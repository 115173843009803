import React from 'react';
import style from './EmployeeStatistic.module.less';
import Translate from 'rootReact/Translate.jsx';
import Item from 'UI/DropdownMenu/UI/Item';

const COURSE = 'course';
const ATTACK = 'attack';
const MESSAGE = 'message';

export function Select({
    type,
    setShow,
    filterTextGlobal,
    setFilterState,
    filter
}) {
    const filterCheck = (param) => {
        if (type === ATTACK && param.reverse === filter.reverse) return true;
        if (type === MESSAGE && param.reverse === filter.reverse) return true;
        if (type === COURSE && param.type === filter.filterKey && param.reverse === filter.reverse) return true;
    };

    const setContent = () => {
        return <>
            <div className={style.filterRow}>
                <div className={style.filterTitle}>{filter.activeTab === COURSE ?
                    filterTextGlobal[filter.activeTab].from :
                    filterTextGlobal[filter.activeTab][filter.filterKey]}
                </div>
                <div className={style.radioWrapper}>
                    <Item handleClick={() => {
                        type === COURSE ?
                            setFilterState({ activeTab: 'course',
                                filterKey: 'from',
                                reverse: false,
                                prevRevers: false, }):
                            type === ATTACK ?
                                setFilterState({ activeTab: 'attack',
                                    filterKey: 'startedAt',
                                    reverse: false, }) :
                                setFilterState({ activeTab: 'message',
                                    filterKey: 'datetime',
                                    reverse: false, });
                        setShow(false);
                    }}
                    checked={filterCheck({ type: 'from', reverse: false })}
                    checkedType={'radiobutton'}
                    size={'md'}
                    id={'dropdown-item'}
                    ><Translate>Сначала новые</Translate></Item>
                </div>
                <div className={style.radioWrapper}>
                    <Item handleClick={() => {
                        {
                            type === COURSE ?
                                setFilterState({ activeTab: 'course',
                                    filterKey: 'from',
                                    reverse: true,
                                    prevRevers: true }) :
                                type === ATTACK ?
                                    setFilterState({ activeTab: 'attack',
                                        filterKey: 'startedAt',
                                        reverse: true, }) :
                                    setFilterState({ activeTab: 'message',
                                        filterKey: 'datetime',
                                        reverse: true, });
                            setShow(false);
                        }}
                    }
                    checked={filterCheck({ type: 'from', reverse: true, })}
                    checkedType={'radiobutton'}
                    size={'md'}
                    id={'dropdown-item'}
                    ><Translate>Сначала старые</Translate></Item>
                </div>
            </div>
            {type === COURSE && <div className={style.filterRow}>
                <div className={style.filterTitle}><Translate>По дате прохождения</Translate></div>
                <div className={style.radioWrapper}>
                    <Item handleClick={() => {
                        setFilterState({ activeTab: 'course',
                            filterKey: 'educationTill',
                            reverse: false });
                        setShow(false);
                    }}
                    checked={filterCheck({ type: 'educationTill', reverse: false })}
                    checkedType={'radiobutton'}
                    size={'md'}
                    id={'dropdown-item'}
                    ><Translate>Сначала новые</Translate></Item>
                </div>
                <div className={style.radioWrapper}>
                    <Item handleClick={() => {
                        setFilterState({ activeTab: 'course',
                            filterKey: 'educationTill',
                            reverse: true });
                        setShow(false);
                    }}
                    checked={filterCheck({ type: 'educationTill', reverse: true })}
                    checkedType={'radiobutton'}
                    size={'md'}
                    id={'dropdown-item'}
                    ><Translate>Сначала старые</Translate></Item>
                </div>
            </div>}
        </>;
    };

    return (
        <div className={style.filterWrapper + ' ' + 'target-card-select'}>
            {setContent()}
        </div>
    );
}
