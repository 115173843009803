import React from 'react';
import style from './TargetCards.module.less';
import { TargetEntitiesCard } from 'rootReact/UI/TargetEntitiesCard';

export const TargetCards = ({ status = 'default', type, item, timeZone, isReported }) => {
    return (
        <div className={`${style.wrapper} ${type === 'message' ? style.message : ''}`} data-testid={'target_card'}>
            {type !== 'message' && <div className={`${style.colorLeftLine} ${style[status]}`}></div>}
            <TargetEntitiesCard item={item}
                type={type}
                timeZone={timeZone}
                isReported={isReported}
                status={status}
            />
        </div>
    );
};
