import React from 'react';
import style from './Hint.module.less';
import PropTypes from 'prop-types';

function Hint({ text, type, testId }) {
    const iconsId = {
        error: 'exclamation-mark',
        tip: 'info-mark'
    };
    return (
        <div className={`${style.wrapper} ${style[type]}`} data-testid={testId}>
            <div className={style.icon}>
                <svg className={style.svg}>
                    <use xlinkHref={`/image/icoSprite.svg#${iconsId[type]}`}></use>
                </svg>
            </div>
            <div className={style.text}>{text}</div>
        </div>
    );
}

Hint.propTypes = {
    text: PropTypes.string,
    type: PropTypes.oneOf(['tip', 'error']),
};

export default Hint;
