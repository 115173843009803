import { EmployeesDeleteModal } from '../employeesDeleteModal';

class EmployeesImportFromFileController {
    constructor ($injector, Upload, gettextCatalog) {
        this.$injector = $injector;
        this.Upload = Upload;
        this.inProcess = false;
        this.result = null;
        this.gettextCatalog = gettextCatalog;
        this.strings = {
            fileFormatError: gettextCatalog.getString('Некорректный формат файла')
        };
    }

    $onInit = () => {
        this.updateParentProcess(this.inProcess);
    };

    dropzoneChanged = () => {
        this.uploadFiles(this.dropzone);
    };

    uploadFiles = (file, errFiles) => {
        let controller = this;
        controller.file = file;
        controller.errFile = errFiles && errFiles[0];
        if (controller.file) {
            controller.inProcess = true;
            controller.updateParentProcess(controller.inProcess);
            controller.result = null;
            file.upload = controller.Upload.upload({
                url: `${window.config.SYSTEM_URL}${window.config.API_URL}/targets/import/xls-import`,
                data: {
                    targets_xlsx: file
                }
            });
            file.upload.then((response) => {
                if (response.data) {
                    controller.result = response.data;
                    controller.result.showDetails = false;
                    controller.result.showNewDetails = false;
                    controller.result.showUpdateDetails = false;
                    controller.result.showLostDetails = false;
                    controller.result.showUpdateMark = false;
                }
                controller.inProcess = false;
                controller.updateParentProcess(controller.inProcess);
                this.loadDashboard();
            }, function (response) {
                controller.inProcess = false;
                controller.updateParentProcess(controller.inProcess);
                if (response.data) {
                    controller.result = {
                        error: response.data.error || controller.gettextCatalog.getString('Ошибка при импорте целей')
                    };
                } else {
                    controller.result = {
                        error: controller.gettextCatalog.getString('Ошибка при импорте целей')
                    };
                }
            }, function (evt) {
                file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
            });
        }
    };

    deleteTargets = () => {
        this.modal = this.$injector.instantiate(EmployeesDeleteModal);
        this.modal.open(this.result.selectedData).then(() => {
            this.loadDashboard();
            this.modalClose();
        }, () => {
        });
    };

    getCsrfToken = () => {
        return window.csrfToken;
    };
}

EmployeesImportFromFileController.$inject = [
    '$injector',
    'Upload',
    'gettextCatalog'
];

export {
    EmployeesImportFromFileController
};
