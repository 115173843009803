import style from './style.modules.css';
import { setSearchData, setSelectedData } from '../../toolkit/actions';
import FetchApi from 'rootReact/REST/fetchApi';

class TargetFileSelectModalController {
    constructor(gettextCatalog, scope, $ngRedux, Upload) {
        this.gettextCatalog = gettextCatalog;
        this.Upload = Upload;
        this.error = false;
        this.strings = {
            fileFormatError: gettextCatalog.getString('Некорректный формат файла')
        };
        this.style = style;
        this.redux = $ngRedux;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, { setSelectedData, setSearchData })(this);
    }

    mapStateToThis = (state) => {
        return {
            selectedData: state.selectedData.selectedData,
        };
    };

    $onInit = () => {
    };

    dropzoneChanged = () => {
        this.uploadFiles(this.dropzone);
    };

    rebuild = async () => {
        const fetchSelected = FetchApi('/targets/rebuild');
        return await fetchSelected.post({ body: { importFromFile: true, ...this.selectedData } });
    };

    uploadFiles = (file, errFiles) => {
        let controller = this;
        this.error = false;
        controller.file = file;
        controller.errFile = errFiles && errFiles[0];
        if (controller.file) {
            controller.inProcess = true;
            controller.result = null;
            file.upload = controller.Upload.upload({
                url: `${window.config.SYSTEM_URL}${window.config.API_URL}/targets/import/temp`,
                data: {
                    targets_xlsx: file
                }
            });
            file.upload.then((response) => {
                if (response.data) {
                    controller.result = response.data;
                    this.rebuild().then(res => this.setSelectedData(res));
                }
                controller.inProcess = false;
            }, function (response) {
                controller.inProcess = false;
                if (response.data) {
                    controller.error = response.data.error || controller.gettextCatalog.getString('Что-то пошло не так :-(');
                    controller.result = { error: response.data.error };
                    return;
                }
                controller.gettextCatalog.getString('Что-то пошло не так :-(');
            }, function (evt) {
                file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
            });
        }
    };

    getCsrfToken = () => {
        return window.csrfToken;
    };
    modalClose = (isSelect = false) => {
        this.$close(isSelect);
    }
}

TargetFileSelectModalController.$inject = [
    'gettextCatalog',
    '$scope',
    '$ngRedux',
    'Upload',
];

export {
    TargetFileSelectModalController
};
