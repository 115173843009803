import {
    ATTACHMENTS_SERVICE,
    AUTH_SERVICE,
    CAMPAIGN_TARGETS_SERVICE,
    CAMPAIGNS_SERVICE,
    DIRECTORIES_SERVICE,
    EMPTY_SELECTED_DATA,
    FINAL_PAGES_SERVICE,
    MAIL_SERVERS_SERVICE,
    PATCHERS_SERVICE,
    PHISHING_PAGES_SERVICE,
    SENDER_DOMAINS_SERVICE,
    TARGETS_SERVICE,
    TEMPLATES_SERVICE,
    CREATE_ATTACK_FIELDS,
    CREATE_ATTACK_BOOL_FIELDS,
    CREATE_ATTACK_DIRECTORIES
} from '../../const';
import angular from 'angular';
import style from './style.modules.css';
import rebuildSelectedData from '../../helpers/rebuildSelectedData';
import buildSelectedDataCacheString from '../../helpers/buildSelectedDataCacheString';
import buildSearchString from '../../helpers/buildSearchString';
import { hasAccess } from '../../reselect';
import { setSelectedData, setLinkings, clearSelectedData } from '../../toolkit/actions';
import AttachmentAddIn from 'rootReact/components/AttachmentAddIn';

const MAX_ISO_NAME_LENGTH = 31;
const IMAGE_REGEXP = /<img id="_image_".+>/ig;

class CampaignModalController {
    fields = [
        'subject',
        'name',
        'fromName',
        'from',
        'domain',
        'text',
        'attachment',
        'attachmentFakeName',
        'phishingPage',
        'finalPage',
        'domainInLinks',
        'sendAttachment',
        'showRedirectPage',
        'checkLdapPassword',
        'vectors',
        'sources',
        'qrCode'
    ];
    boolFields = [
        'domainInLinks',
        'sendAttachment'
    ];
    constructor(
        $ngRedux, $scope, $location, $anchorScroll, $uibModalInstance, $textEditorAttackOptions, $localStorage, $injector, $timeout, CampaignService, TemplatesService, AttachmentsService, PhishingPagesService, FinalPagesService, CampaignTargetsService, SenderDomainsService, AuthService,
        PatchersService, TargetsService, campaignId, copyId, MailServersService, DirectoriesService, gettextCatalog) {
        this.$location = $location;
        this.$scope = $scope;
        this.ngRedux = $ngRedux;
        this.$anchorScroll = $anchorScroll;
        this.$localStorage = $localStorage;
        this.$injector = $injector;
        this.$timeout = $timeout;
        this.processing = false;
        this.CampaignService = CampaignService;
        this.TemplatesService = TemplatesService;
        this.TargetsService = TargetsService;
        this.CampaignTargetsService = CampaignTargetsService;
        this.MailServersService = MailServersService;
        this.DirectoriesService = DirectoriesService;
        this.style = style;
        this.tinymceLoad = false;
        this.fields = CREATE_ATTACK_FIELDS;
        this.boolFields = CREATE_ATTACK_BOOL_FIELDS;
        this.directoriesList = CREATE_ATTACK_DIRECTORIES;
        this.lastActionLength = 0;
        this.AttachmentAddIn = AttachmentAddIn;
        this.$textEditorAttackOptions = $textEditorAttackOptions;
        this.userInfo = AuthService.getUserInfo('licenseFeatures,licenseOutInfectedUrl,ldapProfileCount,variables');
        this.userInfo.$promise.then((userInfo) => {
            this.userInfo = userInfo;
            window.mceTags = userInfo.variables || [];
        });
        this.gettextCatalog = gettextCatalog;
        this.setNewTarget = false;
        this.senderDomains = [];
        this.finalPages = [];
        this.templates = [];
        this.templatesAll = [];
        this.attachments = [];
        this.patchers = [];
        this.phishingPages = [];
        this.isTemplateAllowed = false;
        this.attachmentActions = '';
        this.campaignId = campaignId;
        this.copyId = copyId;
        this.$uibModalInstance = $uibModalInstance;
        this.inProcess = false;
        this.errorText = '';
        this.lastTemplate = null;
        this.campaignLoading = true;
        this.campaignError = false;
        this.showTemplateSaveBtn = false;
        this.templateSaveInProcess = false;
        this.qrList = [];
        this.templateErrors = {};
        this.isTagsLoaded = false;
        this.isFieldChanged = false;
        this.clearArrows = false;
        this.rebuildLoading = false;
        this.imgDetect = '<img id="_image_" class="image" style="display: none;" src="http://{templateCode}.{domain}/payload/openMail/?token={payload}" />';
        this.skipRebuild = false;
        this.textIsNotFilled = false;
        this.attachmentNameError = false;
        this.attachmentError = false;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, { setSelectedData, setLinkings, clearSelectedData })(this);
        window.campaignModalCtrl = this;
        this.templateDefaultText = '<img id="_image_" class="image" style="display: none;" src="{protocol}{templateCode}.{domain}/payload/openMail/?token={payload}" />';
        this.isEditable = true;
        this.showAttachment = false;
        this.handle = false;
        this.strings = {
            refresh: this.gettextCatalog.getString('Обновить')
        };
    }

    mapStateToThis = (state) => {
        return {
            viewTargetAccess: hasAccess(state, { sectionId: 1, rightName: 'view' }),
            createTemplateAccess: hasAccess(state, { sectionId: 2, rightName: 'my_attack_template_save' }),
            deleteTemplateAccess: hasAccess(state, { sectionId: 2, rightName: 'my_attack_template_delete' }),
            selectedData: state.selectedData.selectedData,
            outInfectedUrl: state.auth.auth.license.outInfectedUrl
        };
    };

    $onInit = async () => {
        await this.loadDirectories();
        const { $localStorage } = this;
        if ($localStorage.campaignData) {
            this.campaignId = this.campaignId ? this.campaignId : $localStorage.campaignData.campaignId;
            this.isEditable = true;
        }
        this.$scope.$watch(scope => {
            return scope.$.selectedData.search;
        }, (search) => {
            let searchString = buildSearchString(search);
            if (searchString) {
                this.rebuildSelection();
            }
        });
        if (this.campaignId || this.copyId) {
            this.loadCampaign();
        } else {
            this.campaign = this.CampaignService.YiiModel.one('campaigns');
            this.campaign.state = 0;
            this.campaign.attachmentFakeName = '';
            this.campaign.attachmentActions = '';
            if ($localStorage.campaignData && $localStorage.campaignData.campaign) {
                this.fillCampaign($localStorage.campaignData.campaign);
                this.campaign.id = $localStorage.campaignData.campaign.id;
                this.campaign.template = $localStorage.campaignData.template;
                this.isTemplateAllowed = !!this.campaign.template && this.campaign.template.allowed;
                this.lastTemplate = $localStorage.campaignData.template;
                this.showRedirectPage = $localStorage.campaignData.showRedirectPage;
                this.attachmentActions = $localStorage.campaignData.attachmentActions;
                this.isFieldChanged = $localStorage.campaignData.isFieldChanged;
                this.isEditable = $localStorage.campaignData.isEditable;
                this.campaign.archiveType = $localStorage.campaignData.archiveType;
                this.campaign.domain = $localStorage.campaignData.domain;
                this.showAttachment = $localStorage.campaignData.showAttachment;
                this.activeFileLink = $localStorage.campaignData.activeFileLink;
                this.setNewTarget = true;
            } else {
                this.campaign.text = this.campaign.text ? this.campaign.text + this.templateDefaultText : this.templateDefaultText;
            }
            this.loadFromMailTemplate();
            $localStorage.campaignData = null;
            this.setDefaultDomain();
            if (!this.campaign.sources) {
                this.campaign.sources = [];
            }
            if (!this.campaign.vectors) {
                this.campaign.vectors = [];
            }
            const qrCodeOptions = {
                attackId: 0,
                getQRCOde: this.getQRCOde,
                qrList: this.qrList
            };
            this.tinymceOptions = this.$textEditorAttackOptions(this.gettextCatalog, qrCodeOptions, this.setFileLink, this);
            this.checkText();
            this.campaignLoading = false;
        }
        window.addEventListener('click', this.zipSelect);
    };

    setPhishing = (item) => {
        if (!item) {
            this.campaign.phishingPage = null;
        }
        if (item.title) {
            this.campaign.phishingPage = item;
        }
        this.$scope.$apply();
    }

    loadDirectories = () => {
        const that = this;
        return new Promise((resolve, reject) => {
            that.directories = that.DirectoriesService.getAll();
            that.directoriesLoading = true;
            that.directoriesError = false;
            that.directories.$promise.then((directories) => {
                if (!directories) {
                    that.directoriesLoading = false;
                    return;
                }
                for (let key in directories) {
                    if (~that.directoriesList.indexOf(key)) {
                        that[key] = angular.copy(directories[key]);
                    }
                }
                if (that.finalPages.length && that.outInfectedUrl) {
                    that.finalPages.unshift({
                        id: -1,
                        title: that.outInfectedUrl
                    });
                } else {
                    that.finalPages.push({
                        id: -1,
                        title: that.outInfectedUrl
                    });
                }
                that.templatesAll = angular.copy(that.templates);
                that.directoriesLoading = false;
                resolve();
            }, () => {
                that.directoriesError = true;
                that.directoriesLoading = false;
                reject();
            });
        });
    };

    setShowRedirectPage = () => {
        this.showRedirectPage = !this.showRedirectPage;
        this.$scope.$apply();
    }

    setFinalPage = (item) => {
        if (!item) {
            this.campaign.finalPage = null;
            return;
        }
        this.campaign.finalPage = item;
        this.$scope.$apply();
    }

    setSendAttachment = (val) => {
        this.campaign.sendAttachment = val;
        this.$scope.$apply();
    }

    setLnkAttach = (item) => {
        if (!item) {
            this.campaign.attachment = null;
            return;
        }
        this.campaign.attachment = item;
        this.$scope.$apply();
    }

    checkAttachmentNameLength = () => {
        if (!this.campaign.attachment) {
            this.attachmentNameError = false;
            return;
        }
        if (this.campaign.archiveType !== 3) {
            this.attachmentNameError = false;
            return;
        }
        let attachmentName = this.campaign.attachmentFakeName || this.campaign.attachment.name;
        if (attachmentName.length > MAX_ISO_NAME_LENGTH) {
            this.attachmentNameError = true;
        } else {
            this.attachmentNameError = false;
        }
    }

    loadCampaign = () => {
        const { $localStorage } = this;
        this.campaignError = false;
        this.isFieldChanged = false;
        this.CampaignService.one(this.campaignId || this.copyId).then((campaign) => {
            this.campaign = campaign;
            if (campaign.template) {
                this.isEditable = false;
            }
            this.campaign.sources = [{ id: campaign.sourceId }];
            this.campaign.search = [''];
            this.showRedirectPage = !!campaign.phishingPage;
            if (this.copyId && this.campaign) {
                if (this.campaign.id) {
                    delete (this.campaign.id);
                }
                this.campaign.state = 0;
                delete (this.campaign.fromScheduler);
                this.campaign.name += ` - ${this.gettextCatalog.getString('копия')}`;
            }
            this.campaign.archiveType = campaign.archiveType;
            this.attachmentActions = campaign.attachmentActions;
            this.activeFileLink = campaign.isLinkAttachment;
            this.campaign.attachmentFakeName = this.campaign.attachmentFakeName && this.campaign.attachmentFakeName.length ? this.campaign.attachmentFakeName : '';
            if ((!this.selectedData || !this.selectedData.selectedCount || !this.selectedData.selectedCount.targets) && campaign.selected) {
                let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(campaign.selected));
                this.setSelectedData(selectedData);
            }
            if ($localStorage.campaignData && $localStorage.campaignData.campaign) {
                this.fillCampaign($localStorage.campaignData.campaign);
                this.campaign.id = $localStorage.campaignData.campaign.id;
                this.campaign.template = $localStorage.campaignData.template;
                this.lastTemplate = $localStorage.campaignData.template;
                this.showRedirectPage = $localStorage.campaignData.showRedirectPage;
                this.showAttachment = $localStorage.campaignData.showAttachment;
                this.campaign.domain = $localStorage.campaignData.domain;
                this.activeFileLink = $localStorage.campaignData.activeFileLink;
                this.setNewTarget = true;
            } else {
                this.lastTemplate = campaign.template;
            }
            this.setDefaultDomain();
            $localStorage.campaignData = null;
            if (!this.campaign.sources) {
                this.campaign.sources = [];
            }
            if (!this.campaign.vectors) {
                this.campaign.vectors = [];
            }
            if (!this.campaign.template && this.campaign.id) {
                this.isFieldChanged = true;
            }
            if (!this.campaign.qrCode) {
                this.campaign.qrCode = [];
            }
            this.qrList = this.campaign.qrCode;
            const qrCodeOptions = {
                attackId: this.campaignId || this.copyId || 0,
                getQRCOde: this.getQRCOde,
                qrList: this.qrList,
                isCopy: this.copyId
            };
            this.isTemplateAllowed = !!this.campaign.template && this.campaign.template.allowed;
            this.tinymceOptions = this.$textEditorAttackOptions(this.gettextCatalog, qrCodeOptions, this.setFileLink, this);
            this.campaignLoading = false;
            this.copyId = null;
            this.checkText();
        }, () => {
            this.campaignError = true;
        });
    }

    setFileLink = (active, text) => {
        this.$timeout();
        this.campaign.text = text ? text : this.campaign.text;
        if (!this.attachments.length) {
            return 'noAttachment';
        }
        if (active !== undefined) {
            this.activeFileLink = active;
        }
    }

    getQRCOde = (qrCode) => {
        this.campaign.qrCode = qrCode;
    }

    setDefaultDomain = () => {
        if (!this.campaign.domain) {
            let defaultDomain = this.senderDomains.find((senderDomain) => {
                return senderDomain.isDefault == 1;
            });
            this.campaign.domain = defaultDomain ? defaultDomain : (this.senderDomains[0] ? this.senderDomains[0].name : null);
        }
    }

    returnBackToPage = () => {
        const lastPage = this.$localStorage.lastPage;
        if (this.$localStorage.dataForCampaign) {
            delete this.$localStorage.dataForCampaign;
        }
        this.clearSelectedData();
        this.$localStorage.lastPage = '';
        window.appCtrl.go(lastPage);
    };

    syncTemplates = (dft) => {
        this.templates = [];
        this.TemplatesService.getAll().$promise.then((templates) => {
            templates = templates.sort(function (obj1, obj2) {
                return obj1.allow > obj2.allow;
            });
            this.templates = templates;
            this.templatesAll = templates;

            if (dft != null) {
                this.campaign.template = dft;
                this.showTemplateSaveBtn = this.checkTemplateAllowed();
            }
        });
        this.filterActive = {
            'header': 0,
            'bottom': 0
        };
    };

    get isAvialableForDeletion() {
        return this.campaign.template && this.campaign.template.allowed && this.deleteTemplateAccess;
    }

    get isAvialableForSave() {
        return this.createTemplateAccess;
    }

    loadFromMailTemplate = () => {
        let data = this.$localStorage.dataForCampaign;
        if (data) {
            this.campaign.name = data.subject;
            this.campaign.subject = data.subject;
            this.campaign.text = data.content;
            this.campaign.fromName = data.fullName;
            let email = data.email.split('@');
            if (email.length == 2) {
                this.campaign.from = email[0];
                if (this.userInfo.license && this.userInfo.license.features && this.userInfo.license.features.senderDomain) {
                    this.campaign.domain = email[1];
                }
            }
            this.MailServersService.markAsSeen(data.id);
            delete this.$localStorage.dataForCampaign;
        }
    };

    zipSelect = (e) => {
        const target = e.target;
        if (!target.parentNode) return;
        if (!this.zipSelectOpen && (target.id === 'zip' || target.parentNode.id === 'zip')) {
            this.zipSelectOpen = true;
            this.$scope.$apply();
        }
        if (this.zipSelectOpen && target.id !== 'zip' && target.parentNode.id !== 'zip') {
            this.zipSelectOpen = false;
            this.$scope.$apply();
        }
        this.checkAttachmentNameLength();
    }

    setZipType = (lock = false) => {
        if (lock) {
            this.zipPass = true;
        } else {
            this.zipPass = false;
        }
        this.checkAttachmentNameLength();
    }

    updateTemplateDomain = () => {
        if (this.senderDomains) {
            if (!this.lastTemplate && this.campaign.template) {
                this.lastTemplate = this.campaign.template;
            }
            if (this.senderDomains && this.senderDomains.length > 0) {
                this.senderDomains.map((senderDomain, index) => {
                    if (senderDomain.id == 'template') {
                        this.senderDomains.splice(index);
                    }
                });
            }
            if (this.lastTemplate && this.lastTemplate.domain) {
                this.campaign.domain = this.lastTemplate.domain;
                let templateDomain = this.senderDomains.find((senderDomain) => {
                    return senderDomain.id == 'template';
                });
                if (!templateDomain) {
                    this.senderDomains.push({
                        id: 'template',
                        name: this.campaign.domain,
                        isDefault: 0,
                        fromTemplate: 1
                    });
                }
            }
        }
    };

    save = () => {
        const { $localStorage } = this;
        this.errorText = null;
        if (this.campaign.template) {
            this.campaign.template = { name: this.campaign.template.name, id: this.campaign.template.id };
        }
        this.checkText(true);
        this.form.$setSubmitted();
        if (!this.form.$valid || !this.selectedData ||
            !this.selectedData.selectedCount ||
            !this.selectedData.selectedCount.targets ||
            this.textIsNotFilled || this.attachmentNameError) {
            if (this.form.$error && this.form.$error.required && this.form.$error.required[0]) {
                this.$location.hash(this.form.$error.required[0].$$element.attr('id'));
                this.$anchorScroll();
            }
            return;
        }
        $localStorage.lastPage = '';
        this.inProcess = true;
        this.campaign.selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(this.selectedData));
        if (!this.showRedirectPage) {
            this.campaign.phishingPage = null;
        }
        if (this.copyId && this.campaign.qrCode.length) {
            this.campaign.qrCode.forEach(item => {
                delete item.attack_id;
                delete item.id;
            });
        }
        this.campaign.from = this.campaign.from.trim();
        this.campaign.domain = this.campaign.domain && this.campaign.domain.name ? this.campaign.domain.name : this.campaign.domain;
        this.campaign.checkLdapPassword = +this.campaign.checkLdapPassword;
        this.campaign.isLinkAttachment = +this.activeFileLink || 0;
        this.campaign.sourceId = this.campaign.sources[0] && this.campaign.sources[0].id;
        if (!this.campaign.sourceId) {
            this.campaign.sourceId = 2;
        }
        if (!this.campaign.archiveType) {
            this.campaign.archiveType = 0;
        }
        if (!this.campaign.attachment) {
            this.campaign.attachmentFakeName = '';
            this.campaign.archiveType = 0;
            this.campaign.sendAttachment = 0;
            if (this.activeFileLink) {
                let fileInLink = document.querySelector('#link-attachment-button');
                fileInLink?.parentNode?.parentNode?.click();
            }
        }
        this.campaign.$save().then((campaignData) => {
            if (campaignData) {
                if (this.$localStorage.dataForCampaign) {
                    delete this.$localStorage.dataForCampaign;
                }
                this.$location.path('attacks');
                this.clearSelectedData();
                this.$close(campaignData);
            }
            this.inProcess = false;
        }, () => { }
        ).catch(() => {
            this.errorText = this.gettextCatalog.getString('Ошибка при сохранении атаки');
        });
    };

    setForm = (form) => {
        this.form = form;
    };

    templateSelected = (template) => {
        let notEmptyFields = this.fields.filter(key => !['name', 'domain', 'vectors', 'sources']
            .includes(key))
            .filter(key => typeof (this.campaign[key]) != 'undefined')
            .filter(key => {
                if (key === 'text') {
                    return this.campaign[key] === this.templateDefaultText;
                }
                return this.campaign[key] != null && this.campaign[key].length;
            });
        if (notEmptyFields.length === 1 && notEmptyFields[0] === 'text') {
            let text = this.campaign.text;
            let start = text.replace(this.templateDefaultText, '');
            notEmptyFields = start ? notEmptyFields : [];
        }
        if (template) {
            if (template.id > 0) {
                if (!notEmptyFields.length ||
                    confirm(this.gettextCatalog.getString('Заполненные вами поля будут обновлены. Применить шаблон?'))) {
                    this.prevTemplate = angular.isObject(this.lastTemplate) ? this.lastTemplate : null;
                    this.loadTemplate();
                }
            } else {
                this.campaign.template = { id: 0, name: '' };
                this.updateTemplateDomain();
            }
        }

        // Почему-то срабатывает fieldChanged при обновлении шаблона, чтобы перезаписать editable после его срабатывания
        setTimeout(() => {
            this.isEditable = false;
        }, 500);
    };

    checkText = (handle) => {
        const parser = new DOMParser();
        const domContent = parser.parseFromString(this.campaign.text, 'text/html');
        let body = domContent.querySelector('body').innerHTML.toString();
        let x = body?.replace(IMAGE_REGEXP, '').trim();
        this.textIsNotFilled = !x;
        if (handle) {
            this.handle = handle;
        }
        return this.textIsNotFilled;
    }

    loadTemplate = () => {
        this.templateLoading = true;
        this.templateError = false;
        this.TemplatesService.one(this.campaign.template.id).$promise.then((template) => {
            let templateAttachId = template.attachment && template.attachment.id;
            if (templateAttachId) {
                let hasAttachment = this.attachments.find(item => item.id === templateAttachId);
                if (!hasAttachment) {
                    template.attachment = null;
                    template.attachmentActions = 'image';
                    template.attachmentFakeName = '';
                    template.sendAttachment = 0;
                    template.isLinkAttachment = 0;
                }
            }
            this.lastTemplate = template;
            this.campaign.template = template;
            this.isTemplateAllowed = template.allowed;
            this.fillCampaign(template, angular.isObject(this.prevTemplate) ? this.prevTemplate : null);
            this.campaign.archiveType = template.archiveType;
            this.activeFileLink = template.isLinkAttachment;
            this.checkTemplateAllowed();
            this.isFieldChanged = false;
            this.templateLoading = false;
        }, () => {
            this.templateLoading = false;
            this.templateError = true;
        });
    }

    getTBN = () => {
        const arr = this.templates.length ? this.templates : this.templatesAll;
        let templateName = typeof this.campaign.template == 'object' && this.campaign.template != null ? this.campaign.template.name : (this.campaign.template || null);
        let tBN = arr.filter(tpl => tpl.name == templateName);
        return tBN.length ? tBN[0] : { 'id': -1, 'allowed': true };
    };

    checkTemplateAllowed = (notFromClear = true) => {
        this.showTemplateSaveBtnText = '';
        if (this.lastTemplate) {
            if (this.lastTemplate.allowed) {
                if (this.lastTemplate.id == this.getTBN().id) {
                    this.showTemplateSaveBtnText = this.gettextCatalog.getString('Обновить');
                }
            } else {
                this.showTemplateSaveBtnText = this.gettextCatalog.getString('Сохранить как шаблон');
            }
        } else if (notFromClear || this.checkIsAllRequired(false)) {
            this.showTemplateSaveBtnText = this.gettextCatalog.getString('Сохранить как шаблон');
        }
        return this.isEditable && ['fromName', 'from', 'subject', 'attachmentActions'].some(key => {
            return key in this.campaign && this.campaign[key] != '' &&
                this.campaign[key] != null && typeof this.campaign[key] != 'undefined' &&
                this.campaign[key].length !== this.templateDefaultText.length;
        }) && this.showTemplateSaveBtnText.length > 0;
    };

    checkIsAllRequired = (setError = true) => {
        let allRequiredFields = true;
        ['template', 'fromName', 'from', 'subject', 'text', 'domain'].forEach(key => {
            let valid = key in this.campaign && this.campaign[key] != '' && this.campaign[key] != null && typeof this.campaign[key] != 'undefined';
            if (setError) {
                this.templateErrors[key] = !valid;
            }
            allRequiredFields = allRequiredFields && valid;
        });
        return allRequiredFields;
    };

    removeTemplate = () => {
        if (confirm(this.gettextCatalog.getString('Вы действительно хотите удалить шаблон?'))) {
            this.CampaignService.deleteTemplate(this.campaign.template.id).$promise
                .then(() => {
                    this.syncTemplates();
                    this.campaign.template = '';
                    this.lastTemplate = null;
                    this.showTemplateSaveBtn = this.checkTemplateAllowed();
                })
                .catch(err => {
                    this.errorText = err.data.error;
                });
        }
    };

    saveTemplate = () => {
        if (this.attachmentNameError) return;
        let template = typeof this.lastTemplate == 'object' ? (this.lastTemplate || {}) : { 'allowed': true };
        if (this.getTBN().allowed) {
            if (this.checkIsAllRequired()) {
                if (!this.campaign.attachment) {
                    this.campaign.attachmentFakeName = '';
                    this.campaign.archiveType = 0;
                    this.campaign.sendAttachment = 0;
                    if (this.activeFileLink) {
                        let fileInLink = document.querySelector('#link-attachment-button');
                        fileInLink?.parentNode?.parentNode?.click();
                    }
                }
                this.templateSaveInProcess = true;
                angular.forEach({
                    'name': this.campaign.template.name || this.campaign.template,
                    'from': this.campaign.from,
                    'fromName': this.campaign.fromName,
                    'templateCode': this.campaign.from,
                    'domain': this.campaign.domain.name || this.campaign.domain,
                    'subject': this.campaign.subject,
                    'text': this.campaign.text,
                    'finalPage': this.campaign.finalPage,
                    'phishingPage': this.campaign.phishingPage,
                    'vectors': this.campaign.vectors,
                    'source': this.campaign.sources,
                    'attachment': this.campaign.attachment ? this.campaign.attachment : null,
                    'sendAttachment': this.campaign.sendAttachment ? 1 : 0,
                    'attachmentActions': this.attachmentActions,
                    'checkLdapPassword': this.campaign.checkLdapPassword,
                    'attachmentFakeName': this.campaign.attachmentFakeName ? this.campaign.attachmentFakeName : null,
                    'archiveType': this.campaign.archiveType,
                    'qrCode': this.campaign.qrCode,
                    'isLinkAttachment': this.activeFileLink || 0,
                }, (value, key) => {
                    template[key] = value;
                });
                this.CampaignService.saveTemplate(template).$promise.then(response => {
                    if (!('error' in response)) {
                        this.syncTemplates({
                            'id': response.id,
                            'name': response.name,
                            'allowed': true
                        });
                        this.lastTemplate = response;
                        this.campaign.template = response;
                    }
                    this.templateSaveInProcess = false;
                    this.isEditable = false;
                }).catch(error => {
                    this.templateErrors['template'] = error.data.error;
                    this.templateSaveInProcess = false;
                });
            }
        } else {
            const isExist = this.templatesAll.some(tm => tm.name === this.lastTemplate);
            if (isExist) {
                this.templateErrors['template'] = this.gettextCatalog.getString('Шаблон с таким названием уже существует');
            }
            window.document.getElementById('template').select();
        }
    };

    templateUnfocused = () => {
        if (!this.campaign.template) {
            this.campaign.template = this.lastTemplate && this.lastTemplate.id ? this.lastTemplate : null;
        }
        this.updateTemplateDomain();
    };

    setAttachment = (attachment) => {
        this.attachmentError = false;
        this.campaign.attachment = attachment;
        this.fieldChanged('attachment');
        this.setFieldChange(true);
        this.checkAttachmentNameLength();
        this.$scope.$apply();
    }

    setAttachmentFakeName = (name) => {
        this.campaign.attachmentFakeName = name;
        this.checkAttachmentNameLength();
        this.$scope.$apply();
    }

    setAttachmentNameError = (value) => {
        this.attachmentNameError = value;
    }

    setCheckLdapPassword = () => {
        this.campaign.checkLdapPassword = !this.campaign.checkLdapPassword;
        this.$scope.$apply();
    }

    setAttachmentActions = (action) => {
        this.campaign.attachmentActions = action;
    }

    getFieldChange = () => {
        return this.isFieldChanged;
    }

    setFieldChange = (isChange) => {
        this.isFieldChanged = isChange;
        this.checkTemplateAllowed();
    }

    clearTemplate = () => {
        let messageText = this.gettextCatalog.getString('Хотите очистить все поля атаки?');
        let isClearTemplate = confirm(messageText);
        if (!isClearTemplate) {
            return;
        }
        this.isFieldChanged = false;
        this.clearArrows = true;
        if (!this.campaign.state) {
            if (!this.campaign.template) {
                angular.forEach(this.fields, (field) => {
                    if (field != 'name') {
                        if (field === 'sources' || field === 'vectors' || field === 'qrCode') {
                            this.campaign[field] = [];
                        } else {
                            this.campaign[field] = null;
                        }
                    }
                });
            }
            if (this.campaign.template && this.campaign.template.id) {
                this.TemplatesService.one(this.campaign.template.id).$promise.then((template) => {
                    angular.forEach(this.fields, (field) => {
                        if (template[field] && (field != 'name' || template[field] == this.campaign[field])) {
                            if (field === 'sources' || field === 'vectors') {
                                this.campaign[field] = [];
                            } else {
                                this.campaign[field] = null;
                            }
                        }
                    });
                    this.showTemplateSaveBtn = this.checkTemplateAllowed(false);
                });
            }
            this.campaign.template = null;
            this.lastTemplate = null;
        }
        this.showAttachment = false;
        this.updateTemplateDomain();
        this.isEditable = true;
    };

    fillCampaign = (lastTemplate, prevTemplate) => {
        angular.forEach(this.fields, (field) => {
            if (this.boolFields.indexOf(field) > -1 && typeof (this.campaign[field]) != 'undefined') {
                this.campaign[field] = +this.campaign[field] ? 1 : 0;
                if (lastTemplate && typeof (lastTemplate[field]) != 'undefined') {
                    lastTemplate[field] = +lastTemplate[field] ? 1 : 0;
                }
                if (prevTemplate && typeof (prevTemplate[field]) != 'undefined') {
                    prevTemplate[field] = +prevTemplate[field] ? 1 : 0;
                }
            }
            if (lastTemplate) {
                if (lastTemplate[field]) {
                    this.campaign[field] = !this.campaign[field] || field != 'name' || (prevTemplate && prevTemplate[field] == this.campaign[field]) ? lastTemplate[field] : this.campaign[field];
                    if (field === 'name') {
                        this.campaign.name = lastTemplate.name;
                    }
                    if (field === 'qrCode' && this.campaign[field].length) {
                        this.qrList = this.campaign.qrCode;
                        const qrCodeOptions = {
                            attackId: this.campaignId || this.copyId || 0,
                            getQRCOde: this.getQRCOde,
                            qrList: this.qrList
                        };
                        this.tinymceOptions = this.$textEditorAttackOptions(this.gettextCatalog, qrCodeOptions, this.setFileLink, this);
                    }
                    if (field === 'domainInLinks') {
                        this.campaign[field] = true;
                    }
                    if (field === 'attachment') {
                        this.chooseDefaultAction();
                    }

                } else {
                    this.campaign[field] = null;
                }
            } else {
                if (this.campaign[field] && prevTemplate && (prevTemplate[field] == this.campaign[field] || (prevTemplate[field] && prevTemplate[field].id == this.campaign[field].id))) {
                    this.campaign[field] = null;
                }
            }
        });
        this.userInfo.$promise.then(() => {
            if (typeof (this.userInfo.license.features) == 'undefined' || !this.userInfo.license.features.senderDomain || !lastTemplate.domain) {
                let domain = this.senderDomains.find((item) => {
                    if (item.name == lastTemplate.domain) {
                        return true;
                    }
                });
                if (!domain) {
                    domain = this.senderDomains.find((item) => {
                        return item.isDefault ? true : false;
                    });
                }
                if (domain && domain.name) {
                    this.campaign.domain = domain.name;
                } else {
                    this.campaign.domain = null;
                }
                this.updateTemplateDomain();
            }
        });
        this.showRedirectPage = this.campaign.phishingPage ? 1 : 0;
        if (this.campaign.template && this.campaign.template.vectors && this.campaign.template.vectors.length) {
            this.campaign.vectors = [];
            this.campaign.template.vectors.forEach(vector => {
                this.campaign.vectors.push(vector);
            });
        }
    };

    editSelected = () => {
        this.skipRebuild = true;
        this.setLinkings('');
        const { $localStorage } = this;
        $localStorage.campaignData = {
            campaign: this.campaign,
            showRedirectPage: this.showRedirectPage,
            selectedData: angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(this.selectedData)),
            campaignId: this.campaignId,
            copyId: this.copyId,
            template: this.campaign.template,
            attachmentActions: this.attachmentActions,
            isFieldChanged: this.isFieldChanged,
            isEditable: this.isEditable,
            domain: this.campaign.domain,
            archiveType: this.campaign.archiveType,
            showAttachment: this.showAttachment,
            activeFileLink: this.activeFileLink,
        };
        window.appCtrl.go('targets');
        let timeStamp = new Date().getTime();
        this.setLinkings('setModalSelectedData' + timeStamp);
        this.$close(this.campaign);
    };

    toggleAttachment = (toggleWithState) => {
        if (!this.campaign.state || toggleWithState) {
            this.lastPatcher = null;
            this.showAttachment = !this.showAttachment;
            if (!this.campaign.attachment && this.attachments.length > 0) {
                this.campaign.attachment = this.attachments[0];
            }
            if (this.campaign.attachment && this.campaign.attachment.patcher) {
                this.lastPatcher = this.campaign.attachment.patcher.id;
                this.chooseDefaultAction();
            }
        }
    };

    chooseDefaultAction = () => {
        if (!this.campaign.attachment.actions && !this.campaign.attachment.actions.length) {
            this.watchActions(false);
        } else {
            this.watchActions(this.lastActionLength !== this.campaign.attachment.actions.length);
        }
        this.lastActionLength = this.campaign.attachment.actions.length;
    };

    setZipAction = (zip) => {
        this.campaign.archiveType = zip;
        this.checkAttachmentNameLength();
        this.$scope.$apply();
    }

    watchActions = (changeAction) => {
        this.attachmentActions = changeAction ? [] : this.campaign.attachmentActions;
        if (changeAction && this.campaign.attachment.actions[0]) {
            this.attachmentActions = this.campaign.attachment.actions[0].action;
        }
    };

    choosePatcher = (patcher) => {
        if (this.campaign.state) return;
        this.lastPatcher = null;
        this.campaign.attachment = null;
        angular.forEach(this.attachments, (attachment) => {
            if (attachment.patcher.id == patcher.id) {
                this.campaign.attachment = attachment;
                this.$timeout(() => {
                    this.lastPatcher = patcher.id;
                }, 1);
            }
        });
        this.chooseDefaultAction();
    };

    attachmentChanged = () => {
        if (!this.campaign.state) {
            this.lastPatcher = this.campaign.attachment.patcher.id;
            this.chooseDefaultAction();
        }
        if (this.campaign.attachmentFakeName && this.campaign.attachmentFakeName.length) {
            this.campaign.attachmentFakeName = '';
        }
    };

    toggleAction = (field, action) => {
        if (this.campaign.state) return;
        this.fieldChanged();
        this.campaign[field] = action;
    };

    rebuildSelection = (reverse = false) => {
        if (!this.skipRebuild) {
            let ctrl = this;
            let scope = this.$scope;
            let $localStorage = this.$localStorage;
            this.rebuildLoading = true;
            let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(this.selectedData));
            rebuildSelectedData(selectedData, {
                scope: scope,
                setLoadingFunction: (loading) => {
                    ctrl.rebuildLoading = loading;
                },
                setSelectedDataFunction: (newSelectedData) => {
                    newSelectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(newSelectedData));
                    $localStorage.selectedDataCacheString = buildSelectedDataCacheString(angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(newSelectedData)));
                    ctrl.setSelectedData(newSelectedData);
                    scope.$apply();
                },
                callbackFunction: () => {
                    scope.$apply();
                },
                reverse: reverse
            });
        }
    };

    reverseSelection = () => {
        this.rebuildSelection(true);
    };

    selectNoAttacked = () => {
        this.rebuildLoading = true;
        this.CampaignTargetsService.getNoAttacked(this.campaignId, this, (ctrl, data) => {
            if (data) {
                let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(data));
                this.setSelectedData(selectedData);
            }
            this.rebuildLoading = false;
        });
    };

    selectAll = () => {
        this.rebuildLoading = true;
        this.CampaignTargetsService.getAll(this.campaignId, this, (ctrl, data) => {
            if (data) {
                let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(data));
                this.setSelectedData(selectedData);
            }
            this.rebuildLoading = false;
        });
    };

    fieldChanged = (field) => {
        if (!this.lastTemplate) {
            this.isFieldChanged = true;
        }
        if (this.campaign && this.lastTemplate && this.lastTemplate.id) {
            if (!this.campaign.template.allowed && field !== 'attachment') {
                this.isFieldChanged = true;
                this.lastTemplate = null;
                this.campaign.template = null;
            }
        }
        this.isEditable = true;
        this.showTemplateSaveBtn = this.checkTemplateAllowed();
    };

    saveButtonDisabled = () => {
        return this.inProcess
            || this.directoriesLoading
            || this.templateLoading
            || this.attachmentNameError
            || this.form.$invalid
            || !this.selectedData.selectedCount.targets
            || this.textIsNotFilled;
    }

    $onDestroy = () => {
        window.campaignModalCtrl = null;
        this.unsubscribe();
    };
}

CampaignModalController.$inject = [
    '$ngRedux',
    '$scope',
    '$location',
    '$anchorScroll',
    '$uibModalInstance',
    '$textEditorAttackOptions',
    '$localStorage',
    '$injector',
    '$timeout',
    CAMPAIGNS_SERVICE,
    TEMPLATES_SERVICE,
    ATTACHMENTS_SERVICE,
    PHISHING_PAGES_SERVICE,
    FINAL_PAGES_SERVICE,
    CAMPAIGN_TARGETS_SERVICE,
    SENDER_DOMAINS_SERVICE,
    AUTH_SERVICE,
    PATCHERS_SERVICE,
    TARGETS_SERVICE,
    'campaignId',
    'copyId',
    MAIL_SERVERS_SERVICE,
    DIRECTORIES_SERVICE,
    'gettextCatalog'
];

export {
    CampaignModalController
};
