import React from 'react';
import style from './NavigationMenuItem.module.less';

function NavigationMenuItem ({ children, isSelected, handleClick, testId }) {
    return (
        <div className={`${style.menuItem} ${isSelected ? style.selected : ''}`}
            onClick={handleClick}
            data-testid={testId}>
            {children}
        </div>
    );
}

export default NavigationMenuItem;
