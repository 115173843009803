import style from './style.modules.css';

class DashboardSceletonItemController {
    constructor(location) {
        this.style = style;
        this.location = location;
    }

    titleClick = (path, event) => {
        if (event.ctrlKey || event.metaKey) {
            return;
        }
        if (!this.accessDashboardView) return;
        this.changePath(`/${path}`);
    }
}

DashboardSceletonItemController.$inject = ['$location'];

export {
    DashboardSceletonItemController,
};
