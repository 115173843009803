export const passwordRule = {
    'new-password' : {
        required: true,
    },
    'old-password': {
        required: true,
    }
};

export const userRules = {
    'time-zone': {
        required: true
    }
};
