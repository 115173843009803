import React from 'react';
import style from './Chip.module.less';
import PropTypes from 'prop-types';

function Chip ({ children, type, size, isClickable, disabled }) {
    return (
        <div className={`${style.chip} ${style[type]} ${style[size]} ${isClickable ? style.hover : ''} ${disabled ? style.disabled : ''}`}>
            {children}
        </div>
    );
}

Chip.propTypes = {
    type: PropTypes.oneOf(['mint', 'green', 'yellow', 'blue', 'pink', 'orange', 'red', 'accent', 'secondary', 'primary']),
    size: PropTypes.oneOf(['md', 'sm', 'xsm']),
    disabled: PropTypes.bool
};

export default Chip;
