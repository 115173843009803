import React from 'react';
import style from './Radiobutton.module.less';
import { classesAdd } from '../../helpers';
import PropTypes from 'prop-types';

const Radiobutton = ({ size = 'lg', isDisabled, children , handleClick, checked }) => {
    return (
        <div onClick={handleClick}
            data-testid={'ui-radiobutton'}
            className={classesAdd(
                style.radiobuttonContainer,
                isDisabled && style.disabled
            )}
        >
            <div style={{ padding: '2px' }}>
                <div className={classesAdd(
                    style.circle,
                    style[size],
                    checked ? style.checkTrue : style.checkFalse
                )}
                data-testid={'ui-radiobutton-circle'}
                id={checked ? 'ui-on-radiobutton' : 'ui-off-radiobutton'}></div>
            </div>
            {children && <div className={classesAdd(style.radioText, style[size])}>
                {children}
            </div>}
        </div>
    );
};

Radiobutton.propTypes = {
    checked: PropTypes.bool,
    isDisabled: PropTypes.bool,
    size: PropTypes.oneOf(['lg', 'md', 'sm']),
    handleClick: PropTypes.func,
};

export default Radiobutton;
