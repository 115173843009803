import React, { useContext, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { GettextContext } from 'rootReact/context';
import style from 'rootReact/widget/Navigaton/Navigation.module.less';
import styleFooter from 'rootReact/widget/Footer/Footer.module.less';
import Footer from '../Footer';
import NavigationMenuItem from 'UI/NavigationMenuItem';
import Tooltip from 'UI/Tooltip';
import { hasAccess } from 'root/assets/webpack/src/js/reselect';
const menuItemsWithNotice = ['trainings', 'vulnerabilities'];

const SECTIONS = {
    employees: 1,
    keyIndicators: 14,
    trainings: 2,
    vulnerabilities: 3,
    settings: 5,
    reports: 4
};

function Navigation ({ languages, handleMenuOpen, changePath, isMenuOpen, path, logout }) {
    const gettext = useContext(GettextContext);
    const employeesAccess = useSelector((state) => hasAccess(state, { sectionId: SECTIONS.employees, rightName: 'view' }));
    const keyIndicatorsAccess = useSelector((state) => hasAccess(state, { sectionId: SECTIONS.keyIndicators, rightName: 'view' }));
    const trainingsAccess = useSelector((state) => hasAccess(state, { sectionId: SECTIONS.trainings, rightName: 'view' }));
    const vulnerabilitiesAccess = useSelector((state) => hasAccess(state, { sectionId: SECTIONS.vulnerabilities, rightName: 'view' }));
    const settingsAccess = useSelector((state) => hasAccess(state, { sectionId: SECTIONS.settings, rightName: 'view' }));
    const reportsAccess = useSelector((state) => hasAccess(state, { sectionId: SECTIONS.reports, rightName: 'view' }));
    const menuItems = [
        { id: 'employees', path: '/targets', name: gettext.getString('Сотрудники'), access: employeesAccess, testId: isMenuOpen ? 'expanded_navbar_employees' : 'collapsed_navbar_employees' },
        { id: 'key-indicators', path: '/dashboard', name: gettext.getString('Ключевые показатели'), access: keyIndicatorsAccess, testId: isMenuOpen ? 'expanded_navbar_indicators' : 'collapsed_navbar_indicators' },
        { id: 'trainings', path: '/attacks', name: gettext.getString('Тренировки'), access: trainingsAccess, testId: isMenuOpen ? 'expanded_navbar_trainings' : 'collapsed_navbar_trainings' },
        { id: 'vulnerabilities', path: '/risks', name: gettext.getString('Уязвимости'), access: vulnerabilitiesAccess, testId: isMenuOpen ? 'expanded_navbar_vulnerabilities' : 'collapsed_navbar_vulnerabilities' },
        { id: 'sitemap', path: '/sitemap', name: gettext.getString('Карта сайта'), access: true, testId: isMenuOpen ? 'expanded_navbar_sitemap' : 'collapsed_navbar_sitemap' },
        { id: 'settings', path: '/settings', name: gettext.getString('Настройки'), access: settingsAccess, testId: isMenuOpen ? 'expanded_navbar_setting' : 'collapsed_navbar_setting' },
        { id: 'reports', path: '/reports', name: gettext.getString('Отчеты'), access: reportsAccess, testId: isMenuOpen ? 'expanded_navbar_reports' : 'collapsed_navbar_reports' }
    ];
    const isNotice = false;
    const companyName = useSelector((state) => state.auth.auth.companyName);
    const navigationRef = useRef();
    const footerRef = useRef();
    const [navigationClass, setNavigationClass] = useState('');
    const [footerClass, setFooterClass] = useState('');

    const handleMotionMenu = () => {
        setFooterClass(`${isMenuOpen ? styleFooter.footerClosed : styleFooter.footerOpened}`);
        setNavigationClass(`${isMenuOpen ? style.navigationClosed : style.navigationOpened}`);
        handleMenuOpen();
    };

    const animationEnd = (e) => {
        if (e.animationName.includes('navigation-open') || e.animationName.includes('navigation-close')) setNavigationClass('');
        if (e.animationName.includes('floors-open') || e.animationName.includes('floors-close')) setFooterClass('');
    };

    const getContent = (item, isNotice) => {
        return (
            <div className={style.content}>
                <div className={style.iconWrapper}>
                    <svg className={style.icon}>
                        <use xlinkHref={`/image/mainSprite.svg#${item.id}`}></use>
                    </svg>
                </div>
                <div className={`${isMenuOpen ? style.openedText : style.closedText} ${style.textOpen} ${style.textClose}`}>
                    {item.name}
                </div>
                {isNotice && menuItemsWithNotice.some((menuItem) => menuItem === item.id) &&
                    <div className={`${style.notice} ${isMenuOpen ? style.openedNotice : style.closedNotice} ${style.noticeOpen} ${style.noticeClose}`}></div>
                }
            </div>
        );
    };

    return (
        <div className={`${style.navigation}
        ${navigationClass}
        ${isMenuOpen ? style.navigationOpen + ' ' + style.navigationShadow : style.navigationClose}`}
            ref={navigationRef}
            onAnimationEnd={(e) => animationEnd(e)}
        >
            <div className={style.header}>
                <div className={`${style.openedHeader} ${style.headerOpen} ${style.headerClose}`}>
                    <div className={style.item}>
                        <div className={style.logoLong} onClick={() => employeesAccess && changePath('/targets')}>
                            <img alt={'logo'} src={'/logo.svg'} className={style.logoImg} />
                        </div>
                        <div className={style.icoArrowWrapper} onClick={handleMotionMenu}>
                            <Tooltip textHint={isMenuOpen ? gettext.getString('Свернуть') : gettext.getString('Развернуть')}
                                inline={true}
                                position={'right'}
                            >
                                <svg className={style.icoArrow} viewBox='0 0 20 20'>
                                    <use xlinkHref={'/image/mainSprite.svg#navigation_arrow'}></use>
                                </svg>
                            </Tooltip>
                        </div>
                    </div>
                    <div className={style.companyName}>
                        <div className={style.companyText}>{companyName}</div>
                    </div>
                </div>

                <div className={`${style.closedHeader} ${style.headerOpen} ${style.headerClose}`}>
                    {!isMenuOpen && <>
                        <div className={style.logoShort} onClick={() => employeesAccess && changePath('/targets')}>
                            <img alt={'logo'} src={'/attack.svg'} className={style.logoImg}/>
                        </div>
                        <div className={style.icoArrowWrapper} onClick={handleMotionMenu}>
                            <Tooltip textHint={isMenuOpen ? gettext.getString('Свернуть') : gettext.getString('Развернуть')}
                                inline={true}
                                position={'right'}
                            >
                                <svg className={style.icoArrow} viewBox='0 0 20 20'>
                                    <use xlinkHref={'/image/mainSprite.svg#navigation_arrow'}></use>
                                </svg>
                            </Tooltip>
                        </div>
                    </>}
                </div>
            </div>

            <div className={`${isMenuOpen ? style.openedMenu : style.closedMenu} ${style.menuOpen} ${style.menuClose}`}>
                {menuItems.map((item, index) => (
                    item.access &&
                        <div key={item.id}>
                            <Tooltip textHint={isMenuOpen ? '' : gettext.getString(item.name)}
                                position={'right'}
                            >
                                <NavigationMenuItem handleClick={() => changePath(item.path)}
                                    isSelected={item.path === path}
                                    testId={item.testId}
                                    key={item.id}
                                    testId={item.testId}
                                >
                                    {getContent(item, isNotice)}
                                </NavigationMenuItem>
                            </Tooltip>
                        </div>
                ))}
            </div>
            <div className={style.footer}>
                <Footer gettext={gettext}
                    languages={languages}
                    isMenuOpen={isMenuOpen}
                    footerRef={footerRef}
                    footerClass={footerClass}
                    isIconExist={!isMenuOpen}
                    logout={logout}
                />
            </div>
        </div>
    );
}

export default Navigation;
