import style from './style.modules.css';
import { EMPTY_SELECTED_DATA, TARGETS_SERVICE } from '../../const';
import angular from 'angular';
import { getLanguage } from 'root/assets/webpack/src/js/reselect/extensibleData';
import { setBreadcrumbLink } from 'root/assets/webpack/src/js/toolkit/actions.js';
import FetchApi from '../../React/REST/fetchApi';

class EditSelectedModalController {
    constructor(TargetsService, $ngRedux, gettextCatalog, scope) {
        this.style = style;
        this.TargetsService = TargetsService;
        this.gettextCatalog = gettextCatalog;
        this.langCode = gettextCatalog.getCurrentLanguage();
        this.employee = {
            names: ['first_name', 'second_name', 'third_name'],
            doTransliterate: 0,
        };
        this.scope = scope;
        this.loading = true;
        this.marksLengtg = 0;
        this.groupChoosen = null;
        this.hasEmptyThirdName = true;
        this.initialLanguage = {};
        this.language = {};
        this.languages = [];
        this.canTransliterate = false;
        this.hasTagsChanges = false;
        this.departments = [];
        this.groups = [];
        this.moveToDepartments = [];
        this.moveToDepartment = null;
        this.moveToGroups = [];
        this.moveToGroup = null;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, { setBreadcrumbLink })(this);
    }

    mapStateToThis = (state) => {
        return {
            selectedData: state.selectedData.selectedData,
            linkNames: state.breadcrumbLinks.link,
            languages: getLanguage(state),
        };
    };

    $onInit = async () => {
        try {
            await Promise.all([
                this.loadTags(),
                this.departmentsLoad(),
                this.fetchDepartmentsList(),
                this.targetsHasDataLoad(),
                this.targetsSimilarLanguage(),
                this.targetsSimilarTags(),
                this.groupsList(),
                this.groupsMoveSelected()
            ]);

            this.loading = false;
            this.scope.$apply();
        } catch (e) {
            this.loading = false;
            this.errorText = this.gettextCatalog.getString('Что-то пошло не так :-(');
        }
    };

    groupsList = async () => {
        let data = {
            layout: 'select',
        };
        if ('groups' in this.selectedData && this.selectedData.groups.length > 0) {
            data.ignoreGroup = this.selectedData.groups.join(',');
        }
        let params = new URLSearchParams(data).toString();
        let response = await this.fetchParams(`/groups?${params}`);
        this.groups = response;
    }

    groupsMoveSelected = async () => {
        let data = {
            layout: 'select',
            moveSelected: JSON.stringify(this.selectedData)
        };
        let params = new URLSearchParams(data).toString();
        let response = await this.fetchParams(`/groups?${params}`);
        this.moveToGroups = response;
    }

    targetsHasDataLoad = async () => {
        let response = await this.fetchParams('/targets/has-data', this.selectedData);
        if (!response) throw response;
        this.hasEmptyThirdName = this.langCode != 'ru' ? true : !!response.emptyThirdName;
    }

    targetsSimilarTags = async () => {
        let response = await this.fetchParams('/targets/similar-tags', this.selectedData);
        this.employee.marks_origin = angular.copy(response.tags);
        this.employee.marks = angular.copy(response.tags);
        this.employee.initMarks = angular.copy(response.tags);
    }

    targetsSimilarLanguage = async () => {
        let response = await this.fetchParams('/targets/similar-language', this.selectedData);
        if (Object.prototype.hasOwnProperty.call(response, 'language') && response.language !== null) {
            this.initialLanguage = angular.copy(response.language);
            this.language = angular.copy(response.language);
        }
    }

    departmentsLoad = async () => {
        let departments = await this.fetchParams('/departments?layout=select');
        this.departments = departments;
    }

    fetchDepartmentsList = async () => {
        let data = {
            layout: 'select',
            moveSelected: this.selectedData,
            ignoreDepartment: this.selectedData && this.selectedData.departments ? this.selectedData.departments : []
        };
        let departmentsResponse = await this.fetchParams('/departments/list', data);
        this.moveToDepartments = departmentsResponse;
    }

    fetchParams = async (url, data = null) => {
        const restApi = FetchApi(url);
        let response = null;
        if (data) {
            response = await restApi.post({ body: data });
        } else {
            response = await restApi.get();
        }
        if (response.isError) throw response.error;
        return response;
    }

    loadTags = async (query) => {
        let response = await this.fetchParams('/marks');
        this.marksLengtg = response.length;
        return response.filter(item => item.indexOf(query) !== -1);
    };

    isMarksChanged = () => {
        let result = false;
        let employeeMarks = [];
        if (Array.isArray(this.employee.marks)) {
            angular.forEach(this.employee.marks, (mark) => {
                employeeMarks.push(mark.text ? mark.text : mark);
            });
        }
        if (Array.isArray(this.employee.initMarks)) {
            angular.forEach(employeeMarks, (mark) => {
                if (this.employee.initMarks.indexOf(mark) == -1) {
                    result = true;
                }
                if (employeeMarks.length != this.employee.initMarks.length) {
                    result = true;
                }
            });
        } else if (employeeMarks.length > 0) {
            result = true;
        }
        return result;
    };

    get group() {
        return this.groupChoosen?.name ? this.groupChoosen.name : this.groupChoosen;
    }

    set group(data) {
        this.groupChoosen = data;
    }

    isLanguageChanged = () => {
        const initialLanguageId = Object.prototype.hasOwnProperty.call(this.initialLanguage, 'id') ?
            this.initialLanguage.id : null;
        const currentLanguageId = Object.prototype.hasOwnProperty.call(this.language, 'id') ?
            this.language.id : null;

        return initialLanguageId !== currentLanguageId;
    };

    isMarksSame = () => {
        if (typeof (this.employee.marks) === 'undefined') return false;
        if (this.employee?.marks?.length !== this.employee?.marks_origin?.length) return true;
        let same = false;
        angular.forEach(this.employee.marks, (mark) => {
            if (!this.employee.marks_origin.includes(mark.text)) {
                same = true;
            }
        });
        return same;
    };

    isFormChanged = () => {
        return this.moveToDepartment || this.moveToGroup || this.canTransliterate || this.isMarksSame()
            || this.employee.department
            || this.groupChoosen
            || this.employee.position
            || this.employee.names[0] != 'first_name'
            || this.employee.names[1] != 'second_name'
            || this.employee.names[2] != 'third_name'
            || this.isLanguageChanged();
    };

    save = () => {
        this.errorText = null;
        this.inProcess = false;
        this.employee.selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(this.selectedData));
        let marks = this.employee.marks;
        this.employee.marks = [];
        if (Array.isArray(marks)) {
            angular.forEach(marks, (mark) => {
                this.employee.marks.push(mark.text ? mark.text : mark);
            });
        }
        let initMarks = this.employee.initMarks;
        this.employee.initMarks = [];
        if (Array.isArray(initMarks)) {
            angular.forEach(initMarks, (initMark) => {
                this.employee.initMarks.push(initMark);
            });
        }
        if (this.employee.department && !this.employee.department.name) {
            this.employee.department = {
                id: 0,
                name: this.employee.department,
            };
        }
        this.employee.group = this.groupChoosen;
        if (this.employee.group && !this.employee.group.name) {
            this.employee.group = {
                id: 0,
                name: this.employee.group,
            };
        }
        if (this.moveToDepartment) {
            this.employee.moveToDepartment = this.moveToDepartment;
        }
        if (this.moveToGroup) {
            this.employee.moveToGroup = this.moveToGroup;
        }
        this.employee.languageId = this.language && this.language.id || null;
        if (this.isFormChanged()) {
            this.inProcess = true;
            this.TargetsService.bulkUpdate(this.employee, this, (ctrl, response) => {
                if (ctrl.employee.moveToDepartment) {
                    ctrl.setBreadcrumbLink([]);
                }
                ctrl.inProcess = false;
                ctrl.$close(response);
            }, (ctrl, response) => {
                ctrl.inProcess = false;
                if (response && response.data && response.data.error) {
                    ctrl.errorText = response.data.error;
                } else {
                    ctrl.errorText = ctrl.gettextCatalog.getString('Ошибка при обновлении целей');
                }
            });
        } else {
            this.errorText = this.gettextCatalog.getString('Не введено данных для обновления целей');
        }
    };

    changeTransliterate = () => {
        this.canTransliterate = !this.canTransliterate;
        this.employee.doTransliterate = +this.canTransliterate;
    };

    onMarksChange = () => {
        this.errorText = null;
        this.hasTagsChanges = this.isMarksChanged();
    };

    grValid = () => {
        return this.isValidGroup() /*&& ! ( 'group' in this.employee.$errors)*/;
    };

    isValidGroup = () => {
        if (this.loading || !this.employee?.group) return true;
        let groupName = typeof (this.employee.group) == 'string' ? this.employee.group : this.employee.group?.fullName;
        if (!groupName) return true;
        let group = this.groups.find((group) => {
            return group.fullName === groupName;
        });
        if (group?.id) {
            if (!this.employee.group || !this.employee.group.id) {
                this.employee.group = group;
            }
            return true;
        }
    };

    checkDepartmentMatches = () => {
        this.departmentHasMatches = false;
        if (this.departments && this.departments.length) {
            let departmentName = this.employee.department && this.employee.department.fullName ? this.employee.department.fullName : (typeof (this.employee.department) == 'string' ? this.employee.department : '');
            angular.forEach(this.departments, (department) => {
                if (!departmentName || !department.fullName || departmentName.indexOf(department.fullName) > -1 || department.fullName.indexOf(departmentName) > -1) {
                    this.departmentHasMatches = true;
                    return true;
                }
            });
        }
    };

    checkMoveToDepartmentMatches = () => {
        this.moveToDepartmentHasMatches = false;
        if (this.moveToDepartments && this.moveToDepartments.length) {
            let departmentName = this.moveToDepartment && this.moveToDepartment.fullName ? this.moveToDepartment.fullName : (typeof (this.moveToDepartment) == 'string' ? this.moveToDepartment : '');
            angular.forEach(this.moveToDepartments, (department) => {
                if (!departmentName || !department.fullName || departmentName.indexOf(department.fullName) > -1 || department.fullName.indexOf(departmentName) > -1) {
                    this.moveToDepartmentHasMatches = true;
                    return true;
                }
            });
        }
    };

    checkGroupMatches = () => {
        this.groupHasMatches = false;
        if (this.groups && this.groups.length) {
            let groupName = this.employee.group && this.employee.group.fullName ? this.employee.group.fullName : (typeof (this.employee.group) == 'string' ? this.employee.group : '');
            angular.forEach(this.groups, (group) => {
                if (!groupName || !group.fullName || groupName.indexOf(group.fullName) > -1 || group.fullName.indexOf(groupName) > -1) {
                    this.groupHasMatches = true;
                    return true;
                }
            });
        }
    };
    checkMoveToGroupMatches = () => {
        this.moveToGroupHasMatches = false;
        if (this.moveToGroups && this.moveToGroups.length) {
            let groupName = this.moveToGroup && this.moveToGroup.fullName ? this.moveToGroup.fullName : (typeof (this.moveToGroup) == 'string' ? this.moveToGroup : '');
            angular.forEach(this.moveToGroups, (group) => {
                if (!groupName || !group.fullName || groupName.indexOf(group.fullName) > -1 || group.fullName.indexOf(groupName) > -1) {
                    this.moveToGroupHasMatches = true;
                    return true;
                }
            });
        }
    };

    $onDestroy() {
        this.unsubscribe();
    }
}

EditSelectedModalController.$inject = [
    TARGETS_SERVICE,
    '$ngRedux',
    'gettextCatalog',
    '$scope'
];

export {
    EditSelectedModalController,
};
