/* eslint-disable no-useless-escape */
export class Cookies {
    constructor () {
        this.cookie = document.cookie.split('; ')
            .reduce((acc, item) => {
                let cookie = item.split('=');
                return { ...acc, [cookie[0]]: cookie[1] };
            }, {});
    }

    getCookie = (name) => {
        return this.cookie[name];
    }

    setCookie = (name, value) => {
        this.cookie[name] = value;
        document.cookie = name + '=' + value;
    }
}
