import { classesAdd } from 'rootReact/helpers';

export const placeholderPosition = (isFocused, placeholderRef, setPlaceStyle, style, value) => {
    if (placeholderRef.current.classList.contains(style.placeholderStart) && isFocused) {
        setPlaceStyle(classesAdd(style.placeholderEnd, style.placeholderAnimate));
        setTimeout(() => setPlaceStyle(`${style.placeholderEnd}`), 200);
    }
    if (placeholderRef.current.classList.contains(style.placeholderEnd) && isFocused === false && !value) {
        setPlaceStyle(classesAdd(style.placeholderStart, style.placeholderAnimateReverse));
        setTimeout(() => setPlaceStyle(`${style.placeholderStart}`), 200);
    }
};
