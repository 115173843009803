import textDashboardTemplate from './view.html';

import { TextDashboardItemController } from './controller';

const TextDashboardItemComponent = {
    template: textDashboardTemplate,
    controller: TextDashboardItemController,
    controllerAs: '$',
    bindings: {
        chartIndex: '<',
        activeIndex: '<',
        itemConf: '<',
        targets: '<',
        licenseValid: '<',
        active: '<',
        accessDashboardView: '<',
        accessDashboardEducation: '<',
        accessDashboardAttack: '<',
        applyFilter: '<',
        startCampaign: '<',
        sendToLearning: '<',
        changePath: '='
    },
};

export {
    TextDashboardItemComponent,
};
