import React from 'react';
import style from './Checkbox.module.less';
import PropTypes from 'prop-types';

export const Checkbox = ({ checked, handleClick, disabled, size }) => {
    return (
        <div data-testid={'ui-checkbox'} className={`${style.wrapper} ${style[size]}`} onClick={() => !disabled && handleClick()}>
            {checked &&
                        <svg viewBox={'0 0 20 20'} data-testid={'ui-on-checkbox'} className={`${style.onCheckbox} ${ disabled ? style.disabled : ''}`} fill='none' id={'ui-on-checkbox'}>
                            <rect width={20} height={20} rx='4'/>
                            <path d='M8.58575 14.2427L15.6568 7.1716L14.2426 5.75739L8.58575 11.4143L5.75736 8.58576L4.34314 10.0001L8.58575 14.2427Z' fill='white'/>
                        </svg>
            }
            {!checked &&
                        <svg viewBox={'0 0 20 20'} data-testid={'ui-off-checkbox'} className={`${style.offCheckbox} ${ disabled ? style.disabled : ''}`} fill='none' id={'ui-off-checkbox'}>
                            <rect x='1.07031' y='1' width={18} height={18} rx='3'/>
                        </svg>
            }
        </div>
    );
};

Checkbox.propTypes = {
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    size: PropTypes.oneOf(['lg', 'md']),
    handleClick: PropTypes.func,
};
