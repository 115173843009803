import { DASHBOARD_RELOAD_TIME, SEPARATOR } from '../../const';
import { hasAccess } from '../../reselect';
import { getDashboardNew, getKnowledge, getCampaign, getVulnerability, getDashboardLoading, getRating } from '../../reselect/newDasboard';
import style from './style.modules.css';
import angular from 'angular';
import { fetchDashboardParams } from '../../toolkit/think';
import { setDashboardRound, setDashboardVersion, setLinkings } from '../../toolkit/actions';

class DashboardController {
    constructor($scope, $state, $interval, $cookies, $location, $anchorScroll, $localStorage, $timeout, $ngRedux, gettextCatalog) {
        this.$scope = $scope;
        this.$state = $state;
        this.style = style;
        this.$interval = $interval;
        this.$cookies = $cookies;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        this.$localStorage = $localStorage;
        this.$timeout = $timeout;
        this.gettextCatalog = gettextCatalog;
        this.dashboardLoading = false;
        this.hasParamsCatch = window.localStorage.getItem('dashboardParams') !== null;
        this.printValue1 = '';
        this.subVal1 = '';
        this.printValue2 = '';
        this.subVal2 = '';
        this.printValue4 = '';
        this.menuOpen = false;
        this.subVal4 = '';
        this.bodyElem = angular.element(document.querySelector('body'));
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, { fetchDashboardParams, setDashboardRound, setDashboardVersion, setLinkings })(this);
    }

    mapStateToThis(state) {
        return {
            accessDashboard: hasAccess(state, { sectionId: 0, rightName: 'view' }),
            accessDashboardView: hasAccess(state, { sectionId: 1, rightName: 'view' }),
            accessDashboardView2: hasAccess(state, { sectionId: 2, rightName: 'view' }),
            accessDashboardView3: hasAccess(state, { sectionId: 4, rightName: 'view' }),
            accessDashboardView4: hasAccess(state, { sectionId: 3, rightName: 'view' }),
            accessDashboardEducation: hasAccess(state, { sectionId: 1, rightName: 'target_education' }),
            accessDashboardAttack: hasAccess(state, { sectionId: 1, rightName: 'target_attack' }),
            accessStatistics: hasAccess(state, { sectionId: 14, rightName: 'view' }),
            dasboardNew: getDashboardNew(state),
            firstloading: getDashboardLoading(state),
            knowledge: getKnowledge(state),
            campaign: getCampaign(state),
            rating: getRating(state),
            vulnerability: getVulnerability(state),
            licenseValid: state.auth.auth.license.isValid,
        };
    }

    onMenuOpen = () => {
        this.menuOpen = !this.menuOpen;
    };

    getVersion = () => {
        return this.version || 2;
    };

    $onInit() {
        if (!this.accessDashboard) {
            return;
        }
        this.update = this.$interval(() => {
            this.fetchDashboardParams();
        }, DASHBOARD_RELOAD_TIME);
        this.version = this.$cookies.get('dashVer');
        if (!this.version) {
            this.toggleVersion();
        }
        this.setDashboardVersion(this.getVersion());
    }

    toggleVersion = () => {
        this.version = this.version == 2 ? 1 : 2;
        this.setDashboardVersion(this.version);
        this.$cookies.put('dashVer', this.version);
    };

    applyFilter = (search, hashPath = 'selectAll') => {
        if (this.accessDashboardView) {
            this.setLinkings(hashPath + SEPARATOR + search);
            if (this.$location.path() != '/targets') {
                if (!this.$localStorage.departments) {
                    this.$localStorage.departments = {};
                }
                this.$localStorage.departments.viewMode = 0;
                this.$localStorage.departments.dataMode = 0;
                this.changePath('/targets');
            }
            if (!hashPath) {
                this.setSearchData([this.gettextCatalog.getString(search)]);
            }
        }
    };

    sendToLearning = (search) => {
        if (this.accessDashboardView && this.accessDashboardEducation) {
            this.applyFilter(search, 'sendTraining');
        }
    };

    startCampaign = (search) => {
        if (this.accessDashboardView && this.accessDashboardAttack) {
            this.$localStorage.lastPage = this.$location.path();
            this.applyFilter(search, 'attacksStart');
        }
    };

    $onDestroy() {
        this.$interval.cancel(this.update);
        this.unsubscribe();
    }
}

DashboardController.$inject = [
    '$scope',
    '$state',
    '$interval',
    '$cookies',
    '$location',
    '$anchorScroll',
    '$localStorage',
    '$timeout',
    '$ngRedux',
    'gettextCatalog',
];

export {
    DashboardController,
};
