import '../styles/styles.less';

import 'core-js';
import 'regenerator-runtime/runtime';

import angular from 'angular';
import tagsInput from './plugins/tagsInput/tagsInput.js';
import uirouter from '@uirouter/angularjs';
import ngResource from 'angular-resource';
import ngSanitize from 'angular-sanitize';
import ngCookies from 'angular-cookies';
import ngFileUpload from 'ng-file-upload';
import uiSelect from './plugins/ui-select';
import tabs from 'angular-ui-bootstrap/src/tabs';
import dropdown from 'angular-ui-bootstrap/src/dropdown';
import modals from 'angular-ui-bootstrap/src/modal';
import typeahead from 'angular-ui-bootstrap/src/typeahead';
import tooltip from 'angular-ui-bootstrap/src/tooltip';
import popover from 'angular-ui-bootstrap/src/popover';
import alert from 'angular-ui-bootstrap/src/alert';
import progressbar from 'angular-ui-bootstrap/src/progressbar';
import datepicker from 'angular-ui-bootstrap/src/datepicker';
import timepicker from 'angular-ui-bootstrap/src/timepicker';
import gettext from 'angular-gettext';
import ngAnimate from 'angular-animate';
import 'angularjs-fusioncharts';
import ngRedux from 'ng-redux';
import { fetch } from 'whatwg-fetch';
import { store } from './toolkit';
import routing from './route';
import {
    ATTACHMENTS_SERVICE,
    AUTH_SERVICE,
    BOSS_KADROVIK_PROFILE_MODAL_CONTROLLER,
    BOSS_KADROVIK_PROFILES_SERVICE,
    BOSS_KADROVIK_SERVICE,
    CAMPAIGN_DELETE_MODAL_CONTROLLER,
    CAMPAIGN_MODAL_CONTROLLER,
    CAMPAIGN_SCHEDULE_MODAL_CONTROLLER,
    CAMPAIGN_START_MODAL_CONTROLLER,
    CAMPAIGN_TARGETS_SERVICE,
    CAMPAIGNS_SERVICE,
    COURSE_MODAL_CONTROLLER,
    COURSES_MODAL_CONTROLLER,
    COURSES_SERVICE,
    DASHBOARD_SERVICE,
    DEPARTMENT_MODAL_CONTROLLER,
    DEPARTMENTS_SERVICE,
    EDIT_SELECTED_MODAL_CONTROLLER,
    EMPLOYEE_IMPORT_LDAP_CONTROLLER,
    EMPLOYEES_MODAL_CONTROLLER,
    FINAL_PAGES_SERVICE,
    GROUP_MODAL_CONTROLLER,
    GROUPS_SERVICE,
    IMAGE_MODAL_CONTROLLER,
    LDAP_IMPORTS_SERVICE,
    LDAP_PROFILE_MODAL_CONTROLLER,
    LDAP_PROFILES_SERVICE,
    LEARNING_SERVICE,
    LOGS_MODAL_CONTROLLER,
    MAIL_SERVER_DETAILS_MODAL_CONTROLLER,
    MAIL_SERVER_MODAL_CONTROLLER,
    MAIL_SERVERS_SERVICE,
    MARKS_SERVICE,
    NOTIFICATION_MODAL_CONTROLLER,
    NOTIFICATIONS_SERVICE,
    PATCHERS_SERVICE,
    PHISHING_PAGES_SERVICE,
    POLICY_SERVICE,
    REPORT_MODAL_CONTROLLER,
    REPORT_TARGETS_MODAL_CONTROLLER,
    REPORTS_SERVICE,
    RISKS_SERVICE,
    ROLE_MODAL_CONTROLLER,
    ROLES_SERVICE,
    SCHEDULER_TASK_MODAL_CONTROLLER,
    SCHEDULER_TASKS_SERVICE,
    SENDER_DOMAINS_SERVICE,
    SETTINGS_SERVICE,
    STATUS_SERVICE,
    TARGETS_SERVICE,
    TEMPLATES_SERVICE,
    TIME_ZONES_SERVICE,
    USB_ATTACK_DELETE_MODAL_CONTROLLER,
    USB_ATTACK_MODAL_CONTROLLER,
    USB_ATTACK_TOKEN_MODAL_CONTROLLER,
    USB_SERVICE,
    USER_AUTH_SETTINGS_MODAL_CONTROLLER,
    USER_MODAL_CONTROLLER,
    USER_POLICY_MODAL_CONTROLLER,
    USERS_SERVICE,
    VARIABLE_MODAL_CONTROLLER,
    VARIABLES_SERVICE,
    DIRECTORIES_SERVICE, CERT_MODAL, BUlK_DELETE_MODAL
} from './const';

import { AlertMessagesComponent } from './components/alertMessages';

import { ArrowsFilterComponent } from './components/arrowsFilter';

import { AttackGraphComponent } from './components/attackGraph';

import { BottomPanelComponent } from './components/bottomPanel';

import { LdapDepartmentsComponent } from './components/ldapDepartments';

import { LdapDepartmentComponent } from './components/ldapDepartment';

import { LdapTargetsComponent } from './components/ldapTargets';

import { EducationMessageComponent } from './components/educationMessage';

import { LdapMessageComponent } from './components/ldapMessage';

import { LoginHeaderComponent } from './components/loginHeader';

import { DepartmentBreadcrumbComponent } from './components/departmentBreadcrumb';

import { UrlInputComponent } from './components/urlInput';

import { SearchFieldComponent } from './components/searchField';

import { CourseTableComponent } from './components/courseTable';

import { CourseFunctionComponent } from './components/coursesFunction';

import { ManagerTableComponent } from './components/managerTable';

import { ClassicMenuComponent, ClassicMenuController } from './components/classicMenu';

import { HighlightComponent } from './components/highlight';

import { DashboardComponent } from './components/dashboard';

import {
    DashboardCoveringComponent,
    DashboardPeopleComponent,
    DashboardMotivationComponent,
    DashboardEducationComponent,
    DashboardAttackComponent
} from './components/dashboardCovering';

import { PeopleGraphComponent } from './components/peopleGrath';

import { RatingGraphComponent } from './components/ratingGraph';

import { DepartmentsTableComponent } from './components/departmentsTable';

import { breadcrumbLinksComponent } from './components/breadcrumbLinks';

import { ReportCampaignComponent } from './components/reportCampaign';

import { LdapSearchPanelComponent } from './components/ldapSearchPanel';

import { CampaignViewComponent } from './components/campaignView';

import { UsbAttackComponent } from './components/usbAttack';

import { UsbAttackTargetsComponent } from './components/usbAttackTargets';

import { LdapDepartmentLinkComponent } from './components/ldapDepartmentLink';

import { TargetsTableComponent } from './components/targetsTable';

import { ReportDiagramsComponent } from './components/reportDiagrams';

import { ReportCampaignsComponent } from './components/reportCampaigns';

import { ReportSideBarComponent } from './components/reportSideBar';

import { CampaignModalSenderAddressComponent } from './components/campaignModalSenderAddress';

import { ReactModalController } from 'root/assets/webpack/src/js/components/ReactModal';

import { CampaignComponent } from './components/campaign';

import { DateSelectorComponent } from './components/dateSelector';

import { LogsListComponent } from './components/logsList';

import { InnerCoursesComponent } from './components/innerCourses';

import { NotificationErrorListComponent } from './components/notificationErrorList';

import { RiskSoftwareComponent } from './components/riskSoftware';

import { IcoRoundComponent } from './components/icoRound';

import { DepartmentModalController } from './components/departmentModal';

import { UserModalController } from './components/userModal';

import { UserPolicyModalController } from './components/userPolicyModal';

import { MailServerModalController } from './components/mailServerModal';

import { MailServerDetailsModalController } from './components/mailServerDetailsModal';

import { ImageModalController } from './components/imageModal';

import { CoursesModalController } from './components/coursesModal';

import { CourseModalController } from './components/courseModal';

import { EmployeesImportLdapController, EmployeesModalController } from './components/employeesModal';

import { EmployeesSingleComponent } from './components/employeesSingle';

import { EmployeesImportFromFileComponent } from './components/employeesImportFromFile';

import { CampaignModalController } from './components/campaignModal';

import { DashboardNewComponent } from './components/dashboardNew';

import { UsbAttackModalController } from './components/usbAttackModal';

import { UsbAttackDeleteModalController } from './components/usbAttackDeleteModal';

import { UsbAttackTokenModalController } from './components/usbAttackTokenModal';

import { EditSelectedModalController } from './components/editSelectedModal';

import { CampaignDeleteModalController } from './components/campaignDeleteModal';

import { CampaignScheduleModalController } from './components/campaignScheduleModal';

import { CampaignStartModalController } from './components/campaignStartModal';

import { MaturityLevelComponent } from './components/maturityLevel';

import { MotivationComponent } from './components/motivationComponent';

import { ReportModalController } from './components/reportModal';

import { ReportTargetsModalController } from './components/reportTargetsModal';

import { LdapProfileModalController } from './components/ldapProfileModal';

import { BossKadrovikProfileModalController } from './components/bossKadrovikModal';

import { SchedulerTaskModalController } from './components/schedulerTaskModal';

import { NotificationModalController } from './components/notificationModal';

import { VariableModalController } from './components/variableModal';

import { SendToTrainingComponent } from './components/sendToTraining';

import { ToolbarComponent } from './components/toolbarComponent';

import { SearchFilterItemsComponent } from './components/searchFilterItems';

import { RoleModalController } from './components/roleModal';

import { UserAuthSettingsModalController } from './components/userAuthSettingsModal';

import { LogsModalController } from './components/logsModal';

import { SaveFileComponent } from './components/saveFile';

import { ClassicMenuSaveFileComponent } from './components/classicMenuSaveFile';

import { SaveFileSelectComponent } from './components/saveFileSelect';

import { SelfProgressBarComponent } from './components/selfProgressBar';

import { GroupsGraphComponent } from './components/groupsGraph';

import { GroupModalController } from './components/groupModal';

import { PracticeToolbarComponent } from './components/practiceToolbar';

import { CourseGraphComponent } from './components/courseGraph';

import { SectionHeaderComponent } from './components/sectionHeader';

import { AppController, CampaignsController, DepartmentsController, ErrorController, InnerLayoutController, LayoutController, LoginController, ReportsController, RisksController, SettingsController, PracticePlan, DashboardGraph } from './controllers';

import { LoginFormComponent } from './components/loginForm';

import { BulkDeleteModalController } from './components/bulkDeleteModal';

import {
    AttachmentsService,
    AuthService,
    BossKadrovikProfilesService,
    CampaignsService,
    CampaignTargetsService,
    CoursesService,
    DashboardService,
    DepartmentsService,
    FinalPagesService,
    GroupsService,
    LdapImportsService,
    LdapProfilesService,
    LearningService,
    MailServersService,
    MarksService,
    NotificationsService,
    PatchersService,
    PhishingPagesService,
    PolicyService,
    ReportsService,
    RisksService,
    RolesService,
    SchedulerTasksService,
    SenderDomainsService,
    SettingsService,
    StatusService,
    TargetsService,
    TemplatesService,
    TimeZonesService,
    UsbService,
    UsersService,
    VariablesService,
    DirectoriesService
} from './services';

import { textEditorAttackOptions } from './providers/textEditorAttackOptions';

import { textEditorNotificationOptions } from './providers/textEditorNotificationOptions';

import { dateFormat, filterDepartments, highlightSearch, isoDate, localTimeZone, parseDmy, parseUrls, plural } from './filters';

import {
    animateMenu,
    autoWidth,
    bottomPanel,
    detectHover,
    directiveAutoWidth,
    directiveElementCallback,
    directiveEscKey,
    directiveFileSelect,
    directiveFocusOn,
    directiveGetUploadFile,
    directiveMaxValue,
    directiveMinLength,
    directiveMinValue,
    directiveOnFinishRepeat,
    directivePluralizeWatch,
    directiveRedirect,
    directiveSameWidth,
    directiveScrollHeader,
    directiveSetMargin,
    directiveTypeaheadShowOnFocus,
    inputOnChange,
    navScroll,
    selectOnBlur,
    showPassword,
    startPositionMenu,
    setTooltip,
} from './directives';
import { DashboardItemComponent } from './components/dashboardItem';
import { CertsModalController } from './components/certModal';

import 'angular-dynamic-locale';
import 'tinymce';
import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/code';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/fullpage';
import './plugins/phishImage';
import './plugins/qr-code';
import 'angular-yii2-model';
import 'ngstorage';
import './components/datetimePicker';
import 'angular-ui-bootstrap/src/dateparser';
import 'angular-ui-bootstrap/src/position';
import 'angular-ui-keypress';
import 'angular-ui-tinymce';
import 'ng-pattern-restrict';
import 'angular-file-upload';
import 'angular-growl-v2';
import 'ngclipboard';
import 'chart.js';
import 'angular-chart.js';
import 'angular-base64';
import './app.core.js';
import './app.react';
import 'angular-bootstrap-colorpicker';
import { SsoProfileModalController } from './components/ssoProfileModal/controller';
import { TargetFileSelectModalController } from 'root/assets/webpack/src/js/components/targetFileSelectModal/controller';
import { UAParser } from 'ua-parser-js';
import { OldBrowserPage, ErrorPage } from './oldBrowserPage';

let app;

class BrowserError extends Error {
    constructor (message, currentBrowser) {
        super(message);
        this.name = 'AppError';
        this.currentBrowser = currentBrowser;
    }
}

try {
    const detectBrowser = () => {
        const browsersSupport = [
            { name: 'Chrome', version: 66 },
            { name: 'IE', version: 12 },
            { name: 'Edge', version: 15 },
            { name: 'Firefox', version: 59 },
            { name: 'Opera', version: 12.10 },
            { name: 'Safari Mac OS', version: 10.1 },
            { name: 'Safari iOS', version: 10.3 },
            { name: 'Android Browser', version: 4.4 }
        ];
        const uaParser = new UAParser();
        const uaOS = uaParser.getOS();
        const uaParse = uaParser.getBrowser();
        let currentBrowser = null;
        let brVersion = uaParse.version;
        if (typeof brVersion !== 'string') {
            brVersion = brVersion.toString();
        }
        browsersSupport.forEach(item => {
            if ((uaParse.name === 'Safari' || uaParse.name === 'Mobile Safari') && uaOS.name === 'iOS' && item.name === 'Safari iOS') {
                currentBrowser = {
                    oldBrow: item.version > parseFloat(brVersion),
                };
            }
            if (uaParse.name === 'Safari' && uaOS.name === 'Mac OS' && item.name === 'Safari Mac OS') {
                currentBrowser = {
                    oldBrow: item.version > parseFloat(brVersion),
                };
            }
            if (item.name === uaParse.name) {
                currentBrowser = {
                    oldBrow: item.version > parseFloat(brVersion),
                };
            }
        });
        if (currentBrowser && currentBrowser.oldBrow) {
            throw new BrowserError('Browser error', currentBrowser);
        }
    };

    detectBrowser();

    tagsInput();

    if (!window.fetch) {
        window.fetch = fetch();
    }

    angular.element(document).ready(async () => {
        let res = await fetch('/config.json');
        if (!res.ok) throw res;
        window.config = await res.json();

        app = angular.module('app', [
            'colorpicker.module',
            uirouter,
            ngResource,
            ngSanitize,
            ngFileUpload,
            ngCookies,
            'ngTagsInput',
            uiSelect,
            'ngStorage',
            'ui.tinymce',
            tabs,
            dropdown,
            modals,
            typeahead,
            tooltip,
            popover,
            alert,
            progressbar,
            'ngPatternRestrict',
            'angularFileUpload',
            'ui.keypress',
            datepicker,
            timepicker,
            'ui.bootstrap.dateparser',
            'ui.bootstrap.position',
            'ui.bootstrap.datetimepicker',
            'angular-growl',
            gettext,
            'chart.js',
            ngAnimate,
            'base64',
            'tmh.dynamicLocale',
            ngRedux,
            'angular-yii2-model',
            'app.core',
            'app.react',
            'ngclipboard',
            'ng-fusioncharts'
        ]);

        app.factory('httpInterceptor', [
            '$q', 'growl', '$location', '$localStorage', function ($q, growl, $location, $localStorage) {
                return {
                    request: function (config) {
                        config.headers['Enable-Session'] = 1;
                        if (window.csrfToken) {
                            config.headers['X-Csrf-Token'] = window.csrfToken;
                        }
                        return config;
                    },
                    responseError: function (rejection) {
                        if (rejection.status > 0) {
                            if (rejection.status == 401) {
                                $localStorage.userInfo = null;
                                window.appCtrl.go('login');
                            } else if (rejection.status == 403) {
                                window.appCtrl.loadCsrf();
                            } else if ([422, 404].indexOf(rejection.status) == -1 && rejection.data) {
                                window.errorDebug = rejection.data.debug || '';
                                window.errorUrl = rejection.data.url || '';
                                if (rejection.data.error) {
                                    window.errorMessage = rejection.data.error;
                                }
                                if (rejection.data.message) {
                                    window.errorMessage = rejection.data.message;
                                }
                                if (window.errorMessage && $location.path() != '/error') {
                                    window.appCtrl.go('error');
                                }
                            } else {
                                if (rejection.data && rejection.data.message) {
                                    growl.error(rejection.data.message);
                                }
                            }
                        }
                        return $q.reject(rejection);
                    }
                };
            }]);

        app.config([
            '$compileProvider', 'YiiModelProvider', '$httpProvider', 'tmhDynamicLocaleProvider', ($compileProvider, YiiModelProvider, $httpProvider, tmhDynamicLocaleProvider) => {
                $compileProvider.debugInfoEnabled(false);
                YiiModelProvider.baseUrl = window.config.SYSTEM_URL + window.config.API_URL;
                $httpProvider.interceptors.push('httpInterceptor');
                tmhDynamicLocaleProvider.defaultLocale(window.config.SYSTEM_LANGUAGE);
                tmhDynamicLocaleProvider.localeLocationPattern('/l10n/angular_{{locale}}.js');
            }]).config([
            '$urlRouterProvider',
            '$locationProvider',
            '$stateProvider', routing]).config([
            '$ngReduxProvider', ($ngReduxProvider) => {
                $ngReduxProvider.provideStore(store);
            }]).run();

        app.provider('$textEditorAttackOptions', textEditorAttackOptions);
        app.provider('$textEditorNotificationOptions', textEditorNotificationOptions);

        app.filter('filterDepartments', [STATUS_SERVICE, filterDepartments]);
        app.filter('highlightSearch', highlightSearch);
        app.filter('isoDate', isoDate);
        app.filter('dateFormat', ['$filter', dateFormat]);
        app.filter('plural', plural);
        app.filter('parseUrls', parseUrls);
        app.filter('parseDmy', ['$filter', parseDmy]);
        app.filter('localTimeZone', ['$localStorage', localTimeZone]);

        app.service(AUTH_SERVICE, ['YiiModel', '$resource', AuthService]);
        app.service(CAMPAIGNS_SERVICE, ['YiiModel', '$resource', CampaignsService]);
        app.service(DASHBOARD_SERVICE, ['$resource', DashboardService]);
        app.service(DEPARTMENTS_SERVICE, ['YiiModel', '$resource', DepartmentsService]);
        app.service(STATUS_SERVICE, ['gettextCatalog', '$filter', StatusService]);
        app.service(TARGETS_SERVICE, ['YiiModel', '$resource', TargetsService]);
        app.service(MARKS_SERVICE, ['$resource', MarksService]);
        app.service(TEMPLATES_SERVICE, ['$resource', TemplatesService]);
        app.service(SETTINGS_SERVICE, ['$resource', SettingsService]);
        app.service(LEARNING_SERVICE, ['$resource', LearningService]);
        app.service(POLICY_SERVICE, ['$resource', PolicyService]);
        app.service(LDAP_PROFILES_SERVICE, ['gettextCatalog', 'YiiModel', '$resource', LdapProfilesService]);
        app.service(BOSS_KADROVIK_PROFILES_SERVICE, ['YiiModel', BossKadrovikProfilesService]);
        app.service(SCHEDULER_TASKS_SERVICE, ['YiiModel', '$resource', SchedulerTasksService]);
        app.service(SENDER_DOMAINS_SERVICE, ['YiiModel', '$resource', SenderDomainsService]);
        app.service(CAMPAIGN_TARGETS_SERVICE, ['$resource', CampaignTargetsService]);
        app.service(ATTACHMENTS_SERVICE, ['$resource', AttachmentsService]);
        app.service(PHISHING_PAGES_SERVICE, ['$resource', PhishingPagesService]);
        app.service(FINAL_PAGES_SERVICE, ['$resource', FinalPagesService]);
        app.service(REPORTS_SERVICE, ['$resource', ReportsService]);
        app.service(RISKS_SERVICE, ['YiiModel', '$resource', RisksService]);
        app.service(USB_SERVICE, ['YiiModel', '$resource', UsbService]);
        app.service(NOTIFICATIONS_SERVICE, ['YiiModel', NotificationsService]);
        app.service(PATCHERS_SERVICE, ['$resource', PatchersService]);
        app.service(VARIABLES_SERVICE, ['YiiModel', '$resource', VariablesService]);
        app.service(USERS_SERVICE, ['YiiModel', '$resource', UsersService]);
        app.service(TIME_ZONES_SERVICE, ['$resource', TimeZonesService]);
        app.service(COURSES_SERVICE, ['YiiModel', CoursesService]);
        app.service(ROLES_SERVICE, ['YiiModel', '$resource', RolesService]);
        app.service(MAIL_SERVERS_SERVICE, ['YiiModel', '$resource', MailServersService]);
        app.service(BOSS_KADROVIK_SERVICE, ['YiiModel', '$resource', BossKadrovikProfilesService]);
        app.service(LDAP_IMPORTS_SERVICE, ['YiiModel', '$resource', LdapImportsService]);
        app.service(GROUPS_SERVICE, ['YiiModel', '$resource', GroupsService]);
        app.service(DIRECTORIES_SERVICE, ['$resource', DirectoriesService]);

        app.directive('scrollHeader', ['$window', directiveScrollHeader]);
        app.directive('onFinishRepeat', ['$timeout', directiveOnFinishRepeat]);
        app.directive('elementCallback', directiveElementCallback);
        app.directive('pluralizeWatch', directivePluralizeWatch);
        app.directive('minValue', directiveMinValue);
        app.directive('maxValue', directiveMaxValue);
        app.directive('escKey', directiveEscKey);
        app.directive('sameWidth', ['$sessionStorage', directiveSameWidth]);
        app.directive('typeaheadShowOnFocus', directiveTypeaheadShowOnFocus);
        app.directive('redirect', directiveRedirect);
        app.directive('inputOnChange', inputOnChange);
        app.directive('fileSelect', directiveFileSelect);
        app.directive('minLength', directiveMinLength);
        app.directive('focusOn', directiveFocusOn);
        app.directive('detectHover', detectHover);
        app.directive('autoWidth', autoWidth);
        app.directive('getUpload', directiveGetUploadFile);
        app.directive('dAutoWidth', directiveAutoWidth);
        app.directive('setMargin', directiveSetMargin);
        app.directive('navScroll', navScroll);
        app.directive('startMenu', startPositionMenu);
        app.directive('animateMenu', ['$ngRedux', animateMenu]);
        app.directive('selectOnBlur', selectOnBlur);
        app.directive('bottomPanelConf', bottomPanel);
        app.directive('showPassword', showPassword);
        app.directive('setTooltip', setTooltip);
        app.directive('ldapDepartment', LdapDepartmentComponent);

        app.component('arrowsFilter', ArrowsFilterComponent);
        app.component('attackGraph', AttackGraphComponent);
        app.component('educationMessage', EducationMessageComponent);
        app.component('ldapMessage', LdapMessageComponent);
        app.component('courseTable', CourseTableComponent);
        app.component('departmentsTable', DepartmentsTableComponent);
        app.component('managerTable', ManagerTableComponent);
        app.component('departmentBreadcrumb', DepartmentBreadcrumbComponent);
        app.component('campaignModalSenderAddress', CampaignModalSenderAddressComponent);
        app.component('searchField', SearchFieldComponent);
        app.component('icoRound', IcoRoundComponent);
        app.component('breadcrumbLinks', breadcrumbLinksComponent);
        app.component('alertMessages', AlertMessagesComponent);
        app.component('highlight', HighlightComponent);
        app.component('dashboard', DashboardComponent);
        app.component('dashboardCovering', DashboardCoveringComponent);
        app.component('dashboardPeople', DashboardPeopleComponent);
        app.component('dashboardMotivation', DashboardMotivationComponent);
        app.component('dashboardEducation', DashboardEducationComponent);
        app.component('dashboardAttack', DashboardAttackComponent);
        app.component('dashboardNew', DashboardNewComponent);
        app.component('reportCampaigns', ReportCampaignsComponent);
        app.component('reportCampaign', ReportCampaignComponent);
        app.component('reportDiagrams', ReportDiagramsComponent);
        app.component('maturityLevel', MaturityLevelComponent);
        app.component('innerCourses', InnerCoursesComponent);
        app.component('targetsTable', TargetsTableComponent);
        app.component('courseFunction', CourseFunctionComponent);
        app.component('campaign', CampaignComponent);
        app.component('classicMenu', ClassicMenuComponent);
        app.component('ldapDepartments', LdapDepartmentsComponent);
        app.component('ldapTargets', LdapTargetsComponent);
        app.component('usbAttack', UsbAttackComponent);
        app.component('usbAttackTargets', UsbAttackTargetsComponent);
        app.component('ldapSearchPanel', LdapSearchPanelComponent);
        app.component('dateSelector', DateSelectorComponent);
        app.component('logsList', LogsListComponent);
        app.component('notificationErrorList', NotificationErrorListComponent);
        app.component('ldapDepartmentLink', LdapDepartmentLinkComponent);
        app.component('reportSideBar', ReportSideBarComponent);
        app.component('employeesSingle', EmployeesSingleComponent);
        app.component('employeesFromFile', EmployeesImportFromFileComponent);
        app.component('bottomPanel', BottomPanelComponent);
        app.component('peopleGrath', PeopleGraphComponent);
        app.component('urlInput', UrlInputComponent);
        app.component('sendToTraining', SendToTrainingComponent);
        app.component('riskSoftware', RiskSoftwareComponent);
        app.component('ratingGraph', RatingGraphComponent);
        app.component('searchFilterItems', SearchFilterItemsComponent);
        app.component('toolbarComponent', ToolbarComponent);
        app.component('saveFile', SaveFileComponent);
        app.component('classicMenuSaveFile', ClassicMenuSaveFileComponent);
        app.component('saveFileSelect', SaveFileSelectComponent);
        app.component('selfProgressBar', SelfProgressBarComponent);
        app.component('dasboardItem', DashboardItemComponent);
        app.component('groupsGraph', GroupsGraphComponent);
        app.component('courseGraph', CourseGraphComponent);
        app.component('motivationComponent', MotivationComponent);
        app.component('sectionHeader', SectionHeaderComponent);
        app.component('practiceToolbar', PracticeToolbarComponent);

        app.component('loginHeader', LoginHeaderComponent);
        app.component('loginForm', LoginFormComponent);
        app.component('campaignView', CampaignViewComponent);

        app.controller('AppController', AppController);
        app.controller('InnerLayoutController', InnerLayoutController);
        app.controller('LayoutController', LayoutController);
        app.controller('DepartmentsController', DepartmentsController);
        app.controller('CampaignsController', CampaignsController);
        app.controller('SettingsController', SettingsController);
        app.controller('ReportsController', ReportsController);
        app.controller('RisksController', RisksController);
        app.controller('LoginController', LoginController);
        app.controller('ErrorController', ErrorController);
        app.controller('PracticePlan', PracticePlan);
        app.controller('DashboardGraph', DashboardGraph);
        app.controller('ReactModalController', ReactModalController);
        app.controller('SitemapController', ClassicMenuController);

        app.controller(DEPARTMENT_MODAL_CONTROLLER, DepartmentModalController);
        app.controller(CERT_MODAL, CertsModalController);
        app.controller('ReactModalController', ReactModalController);
        app.controller(EMPLOYEES_MODAL_CONTROLLER, EmployeesModalController);
        app.controller(CAMPAIGN_MODAL_CONTROLLER, CampaignModalController);
        app.controller(CAMPAIGN_DELETE_MODAL_CONTROLLER, CampaignDeleteModalController);
        app.controller(CAMPAIGN_SCHEDULE_MODAL_CONTROLLER, CampaignScheduleModalController);
        app.controller(CAMPAIGN_START_MODAL_CONTROLLER, CampaignStartModalController);
        app.controller(USB_ATTACK_MODAL_CONTROLLER, UsbAttackModalController);
        app.controller(USB_ATTACK_DELETE_MODAL_CONTROLLER, UsbAttackDeleteModalController);
        app.controller(USB_ATTACK_TOKEN_MODAL_CONTROLLER, UsbAttackTokenModalController);
        app.controller(REPORT_MODAL_CONTROLLER, ReportModalController);
        app.controller(REPORT_TARGETS_MODAL_CONTROLLER, ReportTargetsModalController);
        app.controller(LDAP_PROFILE_MODAL_CONTROLLER, LdapProfileModalController);
        app.controller(SCHEDULER_TASK_MODAL_CONTROLLER, SchedulerTaskModalController);
        app.controller(EMPLOYEE_IMPORT_LDAP_CONTROLLER, EmployeesImportLdapController);
        app.controller(EDIT_SELECTED_MODAL_CONTROLLER, EditSelectedModalController);
        app.controller(NOTIFICATION_MODAL_CONTROLLER, NotificationModalController);
        app.controller(VARIABLE_MODAL_CONTROLLER, VariableModalController);
        app.controller(USER_MODAL_CONTROLLER, UserModalController);
        app.controller(USER_POLICY_MODAL_CONTROLLER, UserPolicyModalController);
        app.controller(MAIL_SERVER_MODAL_CONTROLLER, MailServerModalController);
        app.controller(MAIL_SERVER_DETAILS_MODAL_CONTROLLER, MailServerDetailsModalController);
        app.controller(IMAGE_MODAL_CONTROLLER, ImageModalController);
        app.controller(COURSES_MODAL_CONTROLLER, CoursesModalController);
        app.controller(COURSE_MODAL_CONTROLLER, CourseModalController);
        app.controller(ROLE_MODAL_CONTROLLER, RoleModalController);
        app.controller(USER_AUTH_SETTINGS_MODAL_CONTROLLER, UserAuthSettingsModalController);
        app.controller(BOSS_KADROVIK_PROFILE_MODAL_CONTROLLER, BossKadrovikProfileModalController);
        app.controller(LOGS_MODAL_CONTROLLER, LogsModalController);
        app.controller(GROUP_MODAL_CONTROLLER, GroupModalController);
        app.controller(BUlK_DELETE_MODAL, BulkDeleteModalController);
        app.controller('ssoProfileModal', SsoProfileModalController);
        app.controller('targetFileSelect', TargetFileSelectModalController);

        let fileReader = ($q) => {
            let onLoad = (reader, deferred, scope) => {
                return () => {
                    scope.$apply(() => {
                        deferred.resolve(reader.result);
                    });
                };
            };
            let onError = (reader, deferred, scope) => {
                return () => {
                    scope.$apply(() => {
                        deferred.reject(reader.result);
                    });
                };
            };
            let onProgress = (reader, scope) => {
                return (event) => {
                    scope.$broadcast('fileProgress',
                        {
                            total: event.total,
                            loaded: event.loaded
                        });
                };
            };
            let getReader = (deferred, scope) => {
                var reader = new FileReader();
                reader.onload = onLoad(reader, deferred, scope);
                reader.onerror = onError(reader, deferred, scope);
                reader.onprogress = onProgress(reader, scope);
                return reader;
            };
            let readAsDataURL = (file, scope) => {
                var deferred = $q.defer();
                var reader = getReader(deferred, scope);
                reader.readAsDataURL(file);
                return deferred.promise;
            };
            return {
                readAsDataUrl: readAsDataURL
            };
        };

        app.factory('FileReader', ['$q', fileReader]);
        angular.bootstrap(document, ['app']);
    });
} catch (e) {
    if (e instanceof BrowserError) {
        OldBrowserPage();
    } else {
        ErrorPage();
    }
}
