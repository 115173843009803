import React, { useState, useContext, useEffect, useRef } from 'react';
import { GettextContext } from 'rootReact/context';
import style from './Footer.module.less';
import Link from 'rootReact/UI/Link';
import { LanguageSelect } from 'rootReact/shared/LanguageSelect';

function Footer({
    projectUrl,
    projectFooterName,
    languages,
    config,
    supportPhone,
    supportPhonePure,
    supportEmail,
}) {
    const gettext = useContext(GettextContext);
    const [popup, setPopup] = useState(false);
    const popupRef = useRef(null);
    const supportEmailAddress = `mailto:${supportEmail}?subject=${supportEmail}`;

    useEffect(() => {
        if (!popup) return;
        const handleClick = (e) => {
            if (!popupRef.current) return;
            if (!popupRef.current.contains(e.target)) {
                setPopup(false);
            }
        };
        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [popup, setPopup]);

    const getSupport = () => {
        if (supportEmail || supportPhone) {
            return (
                <div className={style.support}>
                    <span>{gettext.getString('Поддержка')} </span>
                    {config.SUPPORT_PRIORITY !== 'email' && (
                        <Link children={supportPhone}
                            linkStyle={'secondary'}
                            size={'sm'}
                            href={'tel:' + +supportPhonePure}
                        />
                    )}
                    {config.SUPPORT_PRIORITY === 'email' && (
                        <Link children={supportEmail}
                            id={'footer-support'}
                            linkStyle={'secondary'}
                            size={'sm'}
                            href={supportEmailAddress}
                        />
                    )}
                </div>
            );
        }
        if (config.SERVICE_URL && !supportEmail && !supportPhone) {
            return (
                <div className={style.support}>
                    <Link children={gettext.getString('ответы на частые вопросы')}
                        id={'footer-support'}
                        size={'sm'}
                        linkStyle={'secondary'}
                        href={config.SERVICE_URL}
                        target={'_blank'}
                    />
                </div>
            );
        }
    };

    return (
        <footer className={'footer-login'} id='login-footer'>
            <div className={style.lang}>
                <LanguageSelect languages={languages}
                    isShort={false}
                    buttonStyle={'ghost'}
                />
            </div>
            <div className={style.rightPart}>
                <div>
                    <Link children={gettext.getString(projectFooterName)}
                        size={'sm'}
                        id={'project-footer-name'}
                        data-testid={'project-footer-name'}
                        linkStyle={'secondary'}
                        href={projectUrl}
                        target={'_blank'}
                    />
                </div>
                {getSupport()}
            </div>
        </footer>
    );
}

export default Footer;
