import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { targetFetch } from  '../../../toolkit/think';
import style from './EmployeeProfile.module.less';
import { EmployeeData } from 'widget/EmployeeData';
import { Loader } from 'rootReact/HOC/loader.jsx';
import { ModalHeader } from 'rootReact/shared/ModalHeader';
import { EmployeeStatistic } from 'rootReact/widget/EmployeeStatistic';
import Translate from 'rootReact/Translate.jsx';
import { Icon } from 'UI/Icon';
import Tag from 'UI/Tag';
import { LoadingPlug } from 'rootReact/features/EmployeeProfile/LoadingPlug.jsx';
import { Tooltip } from 'antd';
import { ErrorPage } from 'rootReact/features/EmployeeProfile/ErrorPage.jsx';

function EmployeeProfile ({ onModalClose, targets }) {
    const dispatch = useDispatch();
    const targetLoad = useSelector(state => state.target.load);
    const target = useSelector(state => state.target.target);
    const targetError = useSelector(state => state.target.error);
    const courses = useSelector(state => state.target.target.courses);
    const attacks = useSelector(state => state.target.target.attacksOnTarget);
    const reports = useSelector(state => state.target.target.attacks);
    const daysAsNew = useSelector(state => state.auth.auth.daysAsNew);
    const languages = useSelector(state => state.languages.languages);
    const timeZone = useSelector(state => state.auth.auth.timeZone);
    const [filter, setFilter] = useState({
        activeTab: 'course',
        filterKey: 'from',
        reverse: false,
        prevRevers: false,
    });

    const hint = <span><Translate>Рейтинг растет, если сотрудник сообщает об атаках или не совершает в них опасных действий. Опасные действия приводят к падению рейтинга.</Translate></span>;

    const dataRef = useRef(null);
    const [hasShadow, setHasShadow] = useState(false);
    const [hasSelectShadow, setHasSelectShadow] = useState(false);

    useEffect(() => {
        downloadTarget();
    }, []);

    const handleScroll = (e) => {
        setHasShadow(e.target.scrollTop !== 0 || e.target.scrollTop - dataRef.current.clientHeight >= 0);
        setHasSelectShadow(e.target.scrollTop - dataRef.current.clientHeight >= 0);
    };

    const setFilterState = (state) => {
        setFilter({ ...filter, ...state });
    };

    const downloadTarget = () => {
        dispatch(targetFetch(targets));
    };

    const filterEntities = (type) => {
        const sorted = (currentDate, nextDate) => {
            if (currentDate < nextDate) return filter.reverse ? -1 : 1;
            if (currentDate > nextDate) return filter.reverse ? 1 : -1;
        };
        if (type === 'attack') {
            if (!attacks || !attacks.length) return [];
            return [...attacks].sort((current, next) => {
                return sorted(current.startedAt, next.startedAt);
            });
        }
        if (type === 'message') {
            if (!reports || !reports.length) return [];
            return [...reports].sort((current, next) => {
                return sorted(new Date(current.datetime), new Date(next.datetime));
            });
        }
        return [];
    };

    return (
        <div className={style.container} data-testid={'employee-card'}>
            <ModalHeader onModalClose={onModalClose} />
            <Loader load={!targetLoad} error={targetError} errorPage={<ErrorPage />} loadingPlug={<LoadingPlug />}>
                <div className={style.header}>
                    <div className={style.headerTitle}>
                        <div className={style.headerStatus}>
                            <Tag size={'xsm'} type={'accent'}>{ !target.status && <span style={{ padding: '0 4px' }}><Translate>Активный</Translate></span> }</Tag>
                        </div>
                        <div className={style.headerName} data-testid={'employee-name'}>{target.fullName}</div>
                    </div>
                    <div className={style.headerRatingWrapper}>
                        <Tooltip title={hint} arrow={false} placement={'left'}>
                            <div className={style.headerTextRating}>
                                <Icon src={'/image/informationSprite.svg'}
                                    id={'info_ico'}
                                    size={'sm'}
                                />
                                <span><Translate>Рейтинг по атакам</Translate></span>
                            </div>
                        </Tooltip>
                        <div className={style.headerRating}>
                            <span data-testid={'employee-rating'}>{target.rating}</span>
                            <div className={target?.situation?.lastChange >= 0 ? style.headerRatingIcoUp : style.headerRatingIcoDown}>
                                <Icon src={'/image/informationSprite.svg'}
                                    id={'arrow_ico'}
                                    size={'md'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${style.wrapper} ${hasShadow ? style.shadow : ''}`} onScroll={handleScroll}>
                    <div ref={dataRef}>
                        <EmployeeData target={target}
                            daysAsNew={daysAsNew}
                            languages={languages}
                            timeZone={timeZone}
                        />
                    </div>
                    <EmployeeStatistic courses={courses}
                        attacks={filterEntities('attack')}
                        reports={filterEntities('message')}
                        timeZone={timeZone}
                        hasSelectShadow={hasSelectShadow}
                        filter={filter}
                        setFilterState={setFilterState}
                    />
                </div>
            </Loader>
        </div>
    );
}

export default EmployeeProfile;
