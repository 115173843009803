import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import style from './EmployeeProfileButton.module.css';
import EmployeeProfile from 'rootReact/features/EmployeeProfile';
import { GettextContext, ModalContext } from 'rootReact/context';
import { setOneTimeMessage } from '../../../toolkit/actions';

function EmployeeProfileButton ({ isAnyLoading, targets, targetHistoryAccess, disable }) {
    const { open } = useContext(ModalContext);
    const dispatch = useDispatch();
    const gettext = useContext(GettextContext);

    const handleClick = () => {
        if (isAnyLoading() || !targetHistoryAccess || disable) return;
        try {
            open(EmployeeProfile, { targets: targets[0] }, 'md');
        } catch (e) {
            dispatch(setOneTimeMessage({
                text: gettext.getString('Что-то пошло не так :-('),
                isReady: 'danger',
                progress: 100
            }));
        }
    };

    return (
        <button onClick={handleClick} disabled={disable || !targetHistoryAccess || isAnyLoading()} className={style.profile}>{gettext.getString('Карточка сотрудника')}</button>
    );
}

export default EmployeeProfileButton;
