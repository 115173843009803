import React, { useContext, useState, useEffect, useRef } from 'react';
import { GettextContext } from 'rootReact/context';
import { useInput } from '../../../hook/useValidModal.jsx';
import DragAndDrop from '../../DragAndDrop';
import FaIcon from 'rootReact/components/FaIcon';

function Locale({
    initValue,
    setValue,
    setActiveLanguage,
    updateEmptyError,
    save,
    currentLocales,
    defaultLangId,
    id,
    errorName,
    errorType,
    setErrorType,
    setErrorName,
}) {
    const gettext = useContext(GettextContext);
    const name = useInput(initValue.name, { isEmpty: true });
    const courseFilename = useInput(initValue.courseFilename, { isEmpty: true });
    const passScore = useInput(initValue.passScore ? initValue.passScore : 92.5, { isEmpty: true });
    const [checkbox, setCheckbox] = useState(initValue.isDefault);
    const [emptyLocale, setEmptyLocale] = useState(false);
    const [dragShow, setDragShow] = useState(true);
    const [file, setFile] = useState(initValue.dropzone);
    const [image, setImage] = useState(initValue.image);
    const [imageURL, setImageURL] = useState(initValue.previewBase64);
    const [imgHeight, setImgHeight] = useState(initValue.imgHeight);
    const [imgWidth, setImgWidth] = useState(initValue.imgWidth);
    const [sizeSuccess, setSizeSuccess] = useState(initValue.sizeSuccess);

    useEffect(() => {
        if (imageURL) {
            getImgSize(imageURL);
        }
    }, [imageURL]);

    const fileReader = new FileReader();
    fileReader.onloadend = () => {
        setImageURL(fileReader.result);
    };

    const handleFile = (event) => {
        event.preventDefault();
        if (event.target.files && event.target.files.length) {
            const image = event.target.files[0];
            setImage(image);
            fileReader.readAsDataURL(image);
        }
    };

    function getImgSize(url) {
        const img = new Image();
        img.src = url;
        img.onload = function () {
            setImgHeight(img.height);
            setImgWidth(img.width);
            setSizeSuccess((1.8 > img.width / img.height && img.width / img.height > 1.6));
        };
    }

    let locale = {
        previewBase64: imageURL,
        courseFilename: courseFilename.value,
        passScore: passScore.value,
        name: name.value,
        languageId: initValue.languageId,
        isDefault: defaultLangId === initValue.languageId ? 1 : 0,
        dropzone: file,
        imageFile: image,
        imgHeight: imgHeight,
        imgWidth: imgWidth,
        sizeSuccess: sizeSuccess,
    };

    useEffect(() => {
        if (courseFilename.value.length !== 0 || image) {
            setValue(locale);
        }
    }, [courseFilename.value, image]);

    const setLocale = (checkbox, field, event) => {
        if (field) {
            field.onBlur(event);
        }
        let locale = {
            previewBase64: imageURL,
            courseFilename: courseFilename.value,
            passScore: passScore.value,
            name: name.value,
            languageId: initValue.languageId,
            isDefault: defaultLangId === initValue.languageId ? 1 : 0,
            dropzone: file,
            imageFile: image,
            imgHeight: imgHeight,
            imgWidth: imgWidth,
            sizeSuccess: sizeSuccess,
        };
        setValue(locale);
    };

    const setDragFile = (newValue) => {
        setFile(newValue);
        setDragShow(false);
        name.onChange(newValue.name);
        courseFilename.onChange(newValue.path);
    };

    const handleDeleteCourse = () => {
        setFile(null);
        setErrorName('');
        setErrorType('');
        setValue({
            name: '',
            passScore: '',
            isDefault: checkbox,
            courseFilename: '',
            previewBase64: '',
            languageId: initValue.languageId,
            dropzone: '',
            imageFile: '',
            imgHeight: '',
            imgWidth: '',
            sizeSuccess: false,
        });
    };

    const handleDeleteImg = async () => {
        if (id && initValue.previewBase64) {
            const options = {
                method: 'DELETE',
                headers: {
                    'X-Csrf-Token': window.csrfToken,
                    'Enable-Session': 1
                }
            };
            fetch(`${window.config.SYSTEM_URL}${window.config.API_URL}/courses/${id}/locales/${initValue.languageId}/preview`, options)
                .then(response => {
                    if (response.ok) {
                        setImage('');
                        setImageURL('');
                        setSizeSuccess(false);
                        setImgWidth('');
                        setImgHeight('');
                    }
                }).catch(e => {
                    console.log(e);
                });
        } else {
            setImage('');
            setImageURL('');
            setSizeSuccess(false);
            setImgWidth('');
            setImgHeight('');
        }
    };

    return (
        <>
            <div className='modal-course_block'>
                <input
                    checked={defaultLangId === initValue.languageId}
                    onClick={() => setActiveLanguage(initValue.languageId)}
                    className='react-checkbox-courses-modal'
                    type='checkbox'
                    id='checkbox'
                />
                <label className='react-checkbox-courses_label-modal' htmlFor='checkbox'>
                    {gettext.getString('Язык по умолчанию')}
                </label>
            </div>
            {(file || currentLocales) &&
                <div className='modal-course_blocks'>
                    <div className='modal-course_block'>
                        <label
                            className={
                                (emptyLocale && !courseFilename.value.length) ||
                                    (courseFilename.isDirty && courseFilename.isEmpty)
                                    ? 'modal-course_label text-error'
                                    : 'modal-course_label'
                            }
                            htmlFor='courseFilename'
                        >
                            {gettext.getString('Новый файл')}
                        </label>
                        <input
                            disabled={true}
                            onChange={(e) => courseFilename.onChange(e.target.value)}
                            onBlur={(e) => setLocale(checkbox, courseFilename, e)}
                            value={courseFilename.value}
                            autoComplete={'off'}
                            className={'modal-course_input-file'}
                            type='text'
                            id='courseFilename'
                        />
                        <button onClick={handleDeleteCourse} className={'modal-course_input-file-btn'}>
                            <span className={'fa fa-trash'}></span>
                        </button>
                    </div>
                    {errorType &&
                        <div className='modal-course-errors'>
                            <FaIcon className='react-icon-error' type={'error'} text={errorType} />
                        </div>
                    }
                    <div className='modal-course_block'>
                        <label
                            className={
                                (emptyLocale && !name.value.length) || (name.isDirty && name.isEmpty)
                                    ? 'modal-course_label text-error'
                                    : 'modal-course_label'
                            }
                            htmlFor='name'
                        >
                            {gettext.getString('Название')}
                        </label>
                        <input
                            onChange={(e) => name.onChange(e.target.value)}
                            onBlur={(e) => setLocale(checkbox, name, e)}
                            value={name.value}
                            className={
                                (emptyLocale && !name.value.length) || (name.isDirty && name.isEmpty)
                                    ? 'modal-course_input input-error'
                                    : 'modal-course_input'
                            }
                            type='text'
                            autoComplete={'off'}
                            id='name'
                        />
                    </div>
                    {errorName &&
                        <div className='modal-course-errors'>
                            <FaIcon className='react-icon-error' type={'error'} text={errorName} />
                        </div>
                    }
                    <div className='modal-course_block'>
                        <label
                            className={
                                (emptyLocale && !passScore.value.length) || (passScore.isDirty && passScore.isEmpty)
                                    ? 'modal-course_label text-error'
                                    : 'modal-course_label'
                            }
                            htmlFor='passScore'
                        >
                            {gettext.getString('Проходной балл')}
                        </label>
                        <input
                            onChange={(e) => passScore.onChange(e.target.value)}
                            onBlur={(e) => setLocale(checkbox, passScore, e)}
                            value={passScore.value}
                            className={
                                (emptyLocale && !passScore.value.length) || (passScore.isDirty && passScore.isEmpty)
                                    ? 'modal-course_input-passcore input-error'
                                    : 'modal-course_input-passcore'
                            }
                            type='number'
                            autoComplete={'off'}
                            id='passScore'
                        />
                    </div>
                    <div className='modal-course_block'>
                        <label htmlFor='input-file' className={'modal-course_label-preview'}>{gettext.getString('Превью')}</label>
                        <input
                            className='modal-course_upload'
                            onChange={handleFile}
                            type='file'
                            id='input-file'
                            accept='.png,.gif'
                        />

                        {
                            imageURL ?
                                <div className={'modal-course_preview-block'}>
                                    <img src={imageURL} className={'modal-course_img-preview'} alt='preview' />

                                    <div className={'modal-course_preview-size'}>
                                        <span className={`${sizeSuccess && 'modal-course_size-success'}`}>{`${imgWidth} x ${imgHeight}`}</span>
                                        <span className={'modal-course_preview-delete'} onClick={handleDeleteImg}>{
                                            initValue.previewBase64 === imageURL?
                                                    gettext.getString('Удалить') :
                                                    gettext.getString('Отмена')}</span>
                                    </div>
                                </div>
                                :
                                <span className='modal-course_preview'>
                                    {gettext.getString('выбирайте изображения GIF или PNG с соотношением ширины и высоты 16:9, с шириной от 700 пикселей.')}
                                </span>
                        }

                    </div>
                </div>
            }
            {(!file && !currentLocales) && <div className='modal-course_drag-block'>
                <DragAndDrop setDragFile={setDragFile} />
            </div>}
        </>
    );
}


export default Locale;
