import React, { useContext, useEffect, useState } from 'react';
import LoginForm from './ui/LoginForm/LoginForm.jsx';
import { GettextContext } from 'rootReact/context';
import style from './loginPage.module.less';
import Footer from './ui/Footer/Footer.jsx';

function LoginPage({
    projectUrl,
    logoMode,
    signIn,
    user,
    isSSO,
    setSSO,
    changeAuthMethod,
    showPasswordForm,
    errorText,
    twoFactorEnable,
    inProcess,
    langCode,
    languages,
    inProcessLang,
    config,
    supportPhonePure,
    supportPhone,
    supportEmail,
    setLanguage,
    projectFooterName,
}) {
    const gettext = useContext(GettextContext);
    const [screenSize, setScreenSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setScreenSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={`${style.loginPage} login-page`}>
            <div className={style.wrapperLeftPart}>
                <div className={style.leftPart}>
                    <div className={style.wrapper}>
                        <div className={style.loginForm}>
                            <div className={style.header}>
                                <div className={style.logo}>
                                    <img src='/logo.svg' alt={'logo'} className={style.logoImg}/>
                                </div>
                                <div className={style.description}>{gettext.getString('Система обучения и контроля защищенности сотрудников')}</div>
                            </div>
                            <LoginForm signIn={signIn}
                                screenSize={screenSize}
                                user={user}
                                twoFactorEnable={twoFactorEnable}
                                isSSO={isSSO}
                                setSSO={setSSO}
                                inProcess={inProcess}
                                errorTextAngular={errorText}
                                showPasswordForm={showPasswordForm}
                                changeAuthMethod={changeAuthMethod} />
                        </div>
                    </div>
                    <Footer langCode={langCode}
                        projectFooterName={projectFooterName}
                        projectUrl={projectUrl}
                        languages={languages}
                        inProcessLang={inProcessLang}
                        config={config}
                        supportPhonePure={supportPhonePure}
                        supportPhone={supportPhone}
                        supportEmail={supportEmail}
                        setLanguage={setLanguage}
                    />
                </div>
            </div>
            <div className={style.wrapperRightPart}>
                <div className={style.rightPart}>
                    <div className={`${style.img} ${style[langCode]}`}></div>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
