import React, { useContext, useState } from 'react';
import SettingsHeader from 'rootReact/components/SettingsSectionHeader';
import { GettextContext, InjectorContext } from 'rootReact/context';
import { useDispatch } from 'react-redux';
import { setLearningConfig } from '../../../toolkit/actions';
import EducationSystemAf from './EducationSystemAf';
import EducationSystem from './EducationSystem';
import { CoursesModal } from 'root/assets/webpack/src/js/components/coursesModal';
import FetchApi from '../../REST';

const AF = 'AF';
const MOODLE = 'moodle';
const WTutor = 'WebTutor';

function SettingsEducation({ learningConfig, languages, licenseIsValid }) {
    const gettext = useContext(GettextContext);
    const injector = useContext(InjectorContext);
    const dispatch = useDispatch();
    const [eduSystem, setEduSystem] = useState(learningConfig.location);
    const [loadConfig, setLoadConfig] = useState(null);
    const [errorText, setErrorText] = useState('');

    const setEducation = (val) => {
        setEduSystem(val);
    };

    const updateCourse = (settings) => {
        let config = { ...learningConfig, ...settings };
        let modal = injector.instantiate(CoursesModal);
        modal.open(config).then(config => {
            if (!config) {
                setLoadConfig(null);
            } else {
                saveSettings(config, true);
            }
        });
    };

    const saveSettings = async (settings, afterUpdate = false) => {
        if (!learningConfig.isVisible) {
            setLoadConfig('load');
            const response = await FetchApi('/learning').post({ body:{ ...settings } });
            if (response.isError) {
                setErrorText(response.err.error);
                setLoadConfig(null);
            } else {
                dispatch(setLearningConfig(response));
                setLoadConfig(null);
            }
            return;
        }
        let initEdu = !learningConfig.location ? AF : learningConfig.type ? WTutor : MOODLE;
        setErrorText('');
        settings.location = eduSystem;
        let edu = !eduSystem ? AF : settings.type ? WTutor : MOODLE;
        if (settings.systemUrl) {
            let url = settings.systemUrl.split('://');
            if (!url[0] && url[1]) {
                settings.systemUrl = 'https' + settings.systemUrl;
            }
            if (!url[1]) {
                settings.systemUrl = null;
            }
        }
        if (loadConfig === 'load') return;
        if (!afterUpdate && learningConfig.educationCount && initEdu !== edu) {
            updateCourse(settings);
        } else {
            setLoadConfig('load');
            const response = await FetchApi('/learning').post({ body:{ ...learningConfig, ...settings } });
            if (response.isError) {
                setErrorText(response.err.error);
                setLoadConfig(null);
            } else {
                dispatch(setLearningConfig(response));
                setLoadConfig(null);
            }
        }
    };

    return (
        <div>
            <SettingsHeader title={gettext.getString('Система электронного обучения')}/>
            {!!learningConfig.isVisible && <div className={'settings-education_wrapper settings_item'}>
                <div className={'react-button'}>
                    <button
                        className={`react-button_element react-button_element__sm react-button_element__left ${!eduSystem ? 'react-button_element__active' : ' '}`}
                        onClick={() => {setEducation(0);}}
                    >{gettext.getString('Start AWR')}</button>
                    <button
                        className={`react-button_element react-button_element__sm react-button_element__right ${eduSystem ? 'react-button_element__active' : ' '}`}
                        onClick={() => {setEducation(1);}}
                    >{gettext.getString('Внешняя')}</button>
                </div>
            </div>}
            {!eduSystem || !learningConfig.isVisible ?
                <EducationSystemAf
                    saveSettings={saveSettings}
                    languages={languages}
                    licenseIsValid={licenseIsValid}
                    learningConfig={learningConfig}
                    error={errorText}
                /> :
                <EducationSystem
                    saveSettings={saveSettings}
                    learningConfig={learningConfig}
                    licenseIsValid={licenseIsValid}
                    updateCourse={updateCourse}
                    error={errorText}
                />}
        </div>
    );
}

export default SettingsEducation;
