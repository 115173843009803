import { Cookies } from './React/helpers/Cookies';

const getTranslate = (translations, key, lang) => {
    return translations && translations[lang] && translations[lang][key] || key;
};

export async function OldBrowserPage () {
    const wrapper = document.querySelector('.main-page-sections');
    const lang = new Cookies().getCookie('langCode') || 'en';
    let response = await fetch('/assets/translations.json');
    let translations = await response.json();
    wrapper.innerHTML = (
        '<div class="e-container">' +
            '<div class="e-browser-error-wrapper">' +
                '<img class="e-browser-error-ico" src="/image/ripIe.svg">' +
                '<div class="e-browser-error-text">' +
                    '<span class="e-browser-error-title">' + getTranslate(translations,'Этот браузер не поддерживается', lang) + '</span>' +
                    '<span class="e-browser-error-line">' + getTranslate(translations,'Пожалуйста, откройте страницу через другой браузер', lang) + '</span>' +
                '</div>' +
            '</div>' +
        '</div>');
}

export async function ErrorPage () {
    const wrapper = document.querySelector('.main-page-sections');
    const lang = new Cookies().getCookie('langCode') || 'en';
    let response = await fetch('/assets/translations.json');
    let translations = await response.json();
    wrapper.innerHTML = (
        '<div class="container" style="display: flex; justify-content: center">' +
            '<div class="error-container">' +
                '<div class="logo-container">' +
                    '<div class="logo"></div>' +
                '</div>' +
                '<div class="message-container">' +
                    '<h1>' + getTranslate(translations, 'Что-то пошло не так :-(', lang) + '</h1>' +
                '</div>' +
            '</div>' +
        '</div>'
    );
}
