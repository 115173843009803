import React from 'react';
import style from './Link.module.less';
import { classesAdd } from '../../helpers';
import PropTypes from 'prop-types';

const Link = ({
    children = 'Link',
    linkStyle = 'primary',
    disabled = false,
    handleClick,
    href,
    target,
    download = false,
    id,
    testId,
    size,
}) => {
    return (
        <a
            id={id}
            data-testid={testId}
            onClick={handleClick}
            href={href}
            target={target}
            download={download}
            className={classesAdd(
                style.link,
                style[size],
                style[linkStyle],
                disabled && style.disabled
            )}
        >
            {children}
        </a>
    );
};

Link.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    linkStyle: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
    handleClick: PropTypes.func,
    disabled: PropTypes.bool,
    dataTestId: PropTypes.string,
    id: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'md', 'sm', 'xsm']),
};

export default Link;
