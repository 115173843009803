import React from 'react';

export function Loader({ load, children, loadingPlug, error, errorPage }) {
    return (
        <>
            {load ?
                loadingPlug :
                error ? errorPage :
                    children
            }
        </>
    );
}
