import React, { useContext } from 'react';
import style from './targetEntitiesCard.module.less';
import { MomentContext } from 'rootReact/context';

export function TargetReportCard({ item, timeZone }) {
    const moment = useContext(MomentContext);
    const m =  moment.tz(item.datetime, 'UTC');
    return (
        <div className={style.reportWrapper}>
            <div className={style.messageTitle}>{item.name || item.comment}</div>
            <div className={style.messageText}>{m.tz(timeZone).format('LL H:mm')}</div>
        </div>
    );
}
