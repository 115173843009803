import style from './style.modules.css';
import getConfig from '../../helpers/dashboardGraph';
import { SEPARATOR } from '../../const';
import { setDashboardRound, setLinkings, setSearchData } from '../../toolkit/actions';

class DashboardItemController {
    constructor(gettextCatalog, scope, location, localStorage, $ngRedux, $state) {
        this.style = style;
        this.scope = scope;
        this.location = location;
        this.localStorage = localStorage;
        this.gettextCatalog = gettextCatalog;
        this.activeIndex = 0;
        this.$state = $state;
        this.cursor = false;
        this.onHover = (points, evt, point) => {
            point ? this.cursor = true : this.cursor = false;
            this.scope.$apply();
        };
        this.unsubscribe = $ngRedux.connect(null, { setDashboardRound, setLinkings, setSearchData })(this);
    }

    $onInit () {
        const { override, series, options } = getConfig(this.chartIndex);
        this.override = override;
        this.series = series;
        this.options = options;
        this.setActiveIndex();
        this.scope.$watch((scope) => {
            return scope.$.itemConf;
        }, () => {
            this.setActiveIndex();
        });
    }

    onClick = (points, evt, point) => {
        if (!point) {
            return;
        }
        this.activeIndex = point._index;
        this.setDashboardRound({ [this.chartId]: point._index });
    };

    setActiveIndex = () => {
        if (!this.itemConf.dataset) {
            return;
        }
        for (let i = 0; i < this.itemConf.dataset[0].length; i++) {
            if (this.itemConf.dataset[0][this.activeIndex]) return;
            if (this.itemConf.dataset[0][i]) {
                this.activeIndex = i;
                this.setDashboardRound({ [this.chartId]: i });
                return;
            }
        }
    }

    titleClick = (path, event) => {
        if (event.ctrlKey || event.metaKey) {
            return;
        }
        if (!this.accessDashboardView) return;
        this.changePath(`/${path}`);
    }

    getCenterText = (index) => {
        let text = '';
        for (let key in this.itemConf) {
            if (+this.itemConf[key].index === +index) {
                text = this.itemConf[key].text;
            }
        }
        return text;
    }

    applyFilter = (search, hashPath = 'selectAll') => {
        if (this.accessDashboardView) {
            this.setLinkings(hashPath + SEPARATOR + search);
            if (!this.localStorage.departments) {
                this.localStorage.departments = {};
            }
            this.localStorage.departments.viewMode = 0;
            this.localStorage.departments.dataMode = 0;
            if (this.location.path() != '/targets') {
                this.changePath('/targets');
            }
            if (!hashPath) {
                this.setSearchData([this.gettextCatalog.getString(search)]);
            }
        }
    };

    ratingFilter = () => {
        if (this.accessDashboardView) {
            if (this.itemConf.lastChange == 0) {
                this.applyFilter(this.gettextCatalog.getString('рейтинг не изменился'));
            } else if (this.itemConf.lastChange > 0) {
                this.applyFilter(this.gettextCatalog.getString('рейтинг улучшился'));
            } else if (this.itemConf.lastChange < 0) {
                this.applyFilter(this.gettextCatalog.getString('рейтинг ухудшился'));
            }
        }
    };

    startCampaign = (search) => {
        if (this.accessDashboardView && this.accessDashboardAttack) {
            this.localStorage.lastPage = this.location.path();
            this.applyFilter(search, 'attacksStart');
        }
    };

    sendToLearning = (search) => {
        if (this.accessDashboardView && this.accessDashboardEducation) {
            this.applyFilter(search, 'sendTraining');
        }
    };

    $onDestroy() {
        this.unsubscribe();
    }
}

DashboardItemController.$inject = [
    'gettextCatalog',
    '$scope',
    '$location',
    '$localStorage',
    '$ngRedux',
    '$state'
];

export {
    DashboardItemController,
};
