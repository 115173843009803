import React from 'react';
import style from './Icon.module.less';
import PropTypes from 'prop-types';

export const Icon = ({ src, id, size, handleClick, handleParams }) => {
    return (
        <svg className={style[size]} data-testid={id} onClick={(e) => handleClick && handleClick(e, handleParams)}>
            <use xlinkHref={`${src}#${id}`}></use>
        </svg>
    );
};

Icon.propTypes = {
    src: PropTypes.string,
    id: PropTypes.string,
    size: PropTypes.oneOf(['xl', 'lg' , 'md' , 'sm']),
    handleClick: PropTypes.func,
    handleParams: PropTypes.any,
};
