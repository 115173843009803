import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import style from './TextFieldHint.module.less';
import { classesAdd } from '../../helpers';
import { placeholderPosition } from 'rootReact/UI/helpers';

function TextFieldHint({
    placeholder = 'Text',
    size = 'lg',
    hint,
    disabled,
    error,
    id,
    testId,
    value,
    handleChange,
    handleBlur,
    handleFocus
}) {
    const [focusStatus, setFocusStatus] = useState(false);
    const [placeStyle, setPlaceStyle] = useState(value ? `${style.placeholderEnd}` : `${style.placeholderStart}`);
    const inputRef = useRef(null);
    const placeholderRef = useRef(null);

    const focusOnInput = () => {
        inputRef.current.focus();
    };

    return (
        <div
            data-testid={'textFieldHint-container'}
            className={classesAdd(
                style.container,
                disabled && style.disabled,
                error && style.error
            )}
        >
            <div
                data-testid={'textFieldHint'}
                className={classesAdd(
                    style.textFieldHint,
                    focusStatus && style.focus,
                    style[size],
                )}
                onClick={() => focusOnInput()}
            >
                <span
                    ref={placeholderRef}
                    data-testid={'textFieldHint-placeholder'}
                    className={classesAdd(placeStyle, style.placeholder, style[size])}
                >
                    {placeholder}
                </span>
                <span className={style.inputWrapper}>
                    <input
                        data-testid={testId || 'textFieldHint-input'}
                        id={id}
                        ref={inputRef}
                        disabled={disabled}
                        value={value}
                        className={classesAdd(style.input, style[size])}
                        onChange={(e) => {
                            handleChange(e);
                            if (value) {
                                placeholderPosition(true, placeholderRef, setPlaceStyle, style, value);
                            }
                        }}
                        onFocus={() => {
                            placeholderPosition(true, placeholderRef, setPlaceStyle, style, value);
                            setFocusStatus(() => true);
                            if (handleFocus) handleFocus();
                        }}
                        onBlur={() => {
                            placeholderPosition(false, placeholderRef, setPlaceStyle, style, value);
                            setFocusStatus(() => false);
                            if (handleBlur) handleBlur();
                        }}
                    />
                </span>

            </div>

            {hint && <div
                data-testid={'textFieldHint-text-under-field'}
                className={classesAdd(
                    style.textUnderField
                )}
            >
                {hint}
            </div>}
        </div>
    );
}

TextFieldHint.propTypes = {
    placeholder: PropTypes.string,
    hint: PropTypes.string,
    value: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'md']),
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    id: PropTypes.string,
};

export default TextFieldHint;
