import React, { useEffect, useRef, useState } from 'react';
import style from './languageSelect.module.less';
import ItemIcon from 'UI/DropdownMenu/UI/ItemIcon';
import ButtonUI from 'UI/ButtonUI';
import { getCurrentLanguage } from 'rootReact/hook/getCurrentLanguage.jsx';
import { useSetLanguage } from 'rootReact/hook/setLanguage.jsx';

export const LanguageSelect = ({ languages, isShort, buttonStyle }) => {
    const [popup, setPopup] = useState(false);
    const langCode = getCurrentLanguage();
    const popupRef = useRef(null);

    const getFlag = (item, popup, open) => {
        //вытащил сюда потому что через спрайты не работают параметры clipPath и filter у svg
        return (
            <svg className={`${style.svg} ${popup && open ? style.disabled : ''}`} xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 16 12'>
                {item === 'ru' && <g id='artwork' filter='url(#filter)'>
                    <g clipPath='url(#clip)'>
                        <rect x='0.404297' y='0.666016' width='14.6667' height='10.6667' rx='2' fill='#1A47B8'/>
                        <path id='vector' fillRule='evenodd' clipRule='evenodd' d='M0.404297 7.77734H15.071V11.3329H0.404297V7.77734Z' fill='#F93939'/>
                        <path id='vector_2' fillRule='evenodd' clipRule='evenodd' d='M0.404297 0.666016H15.071V4.22157H0.404297V0.666016Z' fill='white'/>
                    </g>
                </g>}
                {item === 'en' && <g id='artwork' filter='url(#filter)'>
                    <g clipPath='url(#clip)'>
                        <rect x='0.404297' y='0.666016' width='14.6667' height='10.6667' rx='2' fill='#1A47B8'/>
                        <path id='vector' fillRule='evenodd' clipRule='evenodd' d='M1.96453 0.666016H0.404297V2.44379L13.5023 11.3327L15.071 11.3327V9.5549L1.96453 0.666016Z' fill='white'/>
                        <path id='vector_2' d='M0.924683 0.666016L15.071 10.2912V11.3327H14.5628L0.404297 1.69752V0.666016H0.924683Z' fill='#F93939'/>
                        <path id='vector_3' fillRule='evenodd' clipRule='evenodd' d='M13.6741 0.666016H15.071V2.44379C15.071 2.44379 5.99845 8.36597 1.80112 11.3327H0.404297V9.5549L13.6741 0.666016Z' fill='white'/>
                        <path id='vector_4' d='M15.071 0.666016H14.5973L0.404297 10.2995V11.3327H0.924683L15.071 1.70531V0.666016Z' fill='#F93939'/>
                        <path id='vector_5' fillRule='evenodd' clipRule='evenodd' d='M5.73813 0.666016H9.74933V3.9562H15.071V8.04033H9.74933V11.3327H5.73813V8.04033H0.404297V3.9562H5.73813V0.666016Z' fill='white'/>
                        <path id='vector_6' fillRule='evenodd' clipRule='evenodd' d='M6.57974 0.666016H8.89552V4.76858H15.071V7.23012H8.89552V11.3327H6.57974V7.23012H0.404297V4.76858H6.57974V0.666016Z' fill='#F93939'/>
                    </g>
                </g>}
                {item === 'it' && <g id='artwork' filter='url(#filter)'>
                    <g clipPath='url(#clip)'>
                        <rect x='0.404297' y='0.666016' width='14.6667' height='10.6667' rx='2' fill='white'/>
                        <path id='vector' fillRule='evenodd' clipRule='evenodd' d='M0.404297 0.666016H5.07096V11.3327H0.404297V0.666016Z' fill='#249F58'/>
                        <path id='vector_2' fillRule='evenodd' clipRule='evenodd' d='M10.4043 0.666016H15.071V11.3327H10.4043V0.666016Z' fill='#F93939'/>
                    </g>
                </g> }
                <defs>
                    <filter id='filter' x='0.404297' y='0.666016' width='14.666' height='10.666' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
                        <feFlood floodOpacity='0' result='BackgroundImageFix'/>
                        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/>
                        <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/>
                        <feOffset/>
                        <feGaussianBlur stdDeviation='1'/>
                        <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1'/>
                        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0'/>
                        <feBlend mode='normal' in2='shape' result='effect1_innerShadow_13701_582'/>
                    </filter>
                    <clipPath id='clip'>
                        <rect x='0.404297' y='0.666016' width='14.6667' height='10.6667' rx='2' fill='white'/>
                    </clipPath>
                </defs>
            </svg>);
    };

    const getButtonContent = () => {
        return (
            <>
                <div className={`${style.flag} ${isShort ? style.md : ''} language-select-flag`}>
                    {getFlag(langCode, popup, true)}
                </div>
                <span className='language-select-language'>{languages[langCode]}</span>
            </>
        );
    };

    const getSelectContent = (item) => {
        return (
            <div className={!isShort ? style.buttonContent : ''}>
                <div className={`${style.flag} ${isShort ? style.md : ''}`}>
                    {getFlag(item, popup, false)}
                </div>
                {!isShort && <span>{languages[item]}</span>}
            </div>
        );
    };

    useEffect(() => {
        if (!popup) return;
        const handleClick = (e) => {
            if (!popupRef.current) return;
            if (!popupRef.current.contains(e.target)) {
                setPopup(false);
            }
        };
        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [popup, setPopup]);

    const handleChangeLang = async (lang) => {
        let response = await useSetLanguage(lang);
        setPopup(false);
    };

    const handleLang = () => {
        setPopup(prev => !prev);
    };

    return (
        <div className={style.wrapper}>
            <ButtonUI buttonStyle={buttonStyle}
                size={isShort ? 'sm' : 'xsm'}
                disabled={popup}
                handleClick={handleLang}
                dataTestId={'button_languages'}
                id={'button_languages'}
            >
                {getButtonContent()}
            </ButtonUI>
            {popup &&
            <div className={`${style.popup} language-select-popup ${isShort ? style.lg : style.xs}`} ref={popupRef}>
                {
                    Object.keys(languages).map((item) => (
                        <ItemIcon key={item}
                            handleClick={() => handleChangeLang(item)}
                            size={isShort ? 'md' : 'sm'}
                            id={'land-select'}
                        >
                            {getSelectContent(item)}
                        </ItemIcon>
                    ))
                }
            </div>
            }
        </div>
    );
};
