import React, { useContext } from 'react';
import style from './EmployeeData.module.less';
import Translate from 'rootReact/Translate.jsx';
import { Icon } from 'UI/Icon';
import Chip from 'rootReact/UI/Chip';
import { GettextContext, MomentContext } from 'rootReact/context';
import { Tooltip } from 'antd';

export const EmployeeData = ({ target, languages, timeZone }) => {
    const moment = useContext(MomentContext);
    const gettext = useContext(GettextContext);

    const getDataTarget = (icon, label, value, testId) => {
        return (
            <div className={style.bodyElementWrapper}>
                <Tooltip title={label.length > 17 ? label : null} arrow={false} placement={'left'}>
                    <div className={style.bodyLabelWrapper} style={{ padding: icon === 'star_ico' ? '2px 0' : '' }}>
                        <div className={style.bodyIcon}>
                            <Icon src={'/image/informationSprite.svg'}
                                id={icon}
                                size={'md'}
                            />
                        </div>

                        <div className={style.bodyLabel}>{truncateString(label, 17)}</div>
                    </div>
                </Tooltip>
                <div className={style.bodyValue} data-testid={testId}>
                    {!Array.isArray(value) && value ||
                    Array.isArray(value) && value.length > 0 && value ||
                    <span className={style.bodyNoValue}><Translate>Нет</Translate></span>}
                </div>
            </div>
        );
    };

    const getLanguage = (id) => {
        return languages.find(item => item.id === id)?.name;
    };

    const getManagerName = (manager) => {
        if (!manager) return null;
        return `${manager.second_name} ${manager.first_name} ${manager.third_name ? manager.third_name : ''}`;
    };

    function truncateString(str, length) {
        if (str.length > length) {
            return str.slice(0, length) + '...';
        }
        return str;
    }

    return (
        <div className={style.body}>
            {getDataTarget('email_ico', gettext.getString('Эл. почта'), target.email, 'employee-email')}
            {getDataTarget('calendar_ico', gettext.getString('Дата добавления'), moment.tz(target.createdAt, timeZone).format('ll'), 'employee-date')}
            {getDataTarget('translate_ico', gettext.getString('Язык'), getLanguage(target.languageId), 'employee-lang')}
            {getDataTarget('department_ico', gettext.getString('Подразделение'), target.department?.name, 'employee-department')}
            {getDataTarget('position_ico', gettext.getString('Должность'), target.position, 'employee-position')}
            {getDataTarget('manager_ico', gettext.getString('Руководитель'),  getManagerName(target.manager), 'employee-manager')}
            {getDataTarget('group_ico', gettext.getString('Группа риска'), target.group?.name, 'employee-group')}
            {getDataTarget('star_ico', gettext.getString('Метки'), target.marks?.
                map(item => <div key={item}><Chip key={item} type={'secondary'} size={'xsm'} isClickable={false}><span style={{ padding: '0 4px' }}>{truncateString(item)}</span></Chip></div>), 'employee-mark')}
        </div>
    );
};
