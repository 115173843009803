const webTutorRule = {
    login: {
        required: true
    },
    password: {
        required: false,
    },
    baseUrl: {
        required: true,
        url: true,
    },
    employeesUrl: {
        required: true,
        url: true,
    },
    userSyncUrl: {
        required: true,
        url: true,
    },
    ntlmLogin: {
        required: false
    },
    ntlmPassword: {
        required: false
    }
};

const moodleRule = {
    baseUrl: {
        required: true,
        url: true,
    },
    token: {
        required: true
    }
};

const LMSRule = {
    proxyUrl: {
        urlNoPattern: true,
    },
    baseUrl: {
        required: true,
        urlNoPattern: true,
    },
    login: {
        required: true
    },
    password: {
        required: false,
    },
};

export const educationRule = {
    token: {
        required: true
    },
    login: {
        required: true
    },
    password: {
        required: false,
    },
    baseUrl: {
        required: true,
        urlNoPattern: true,
    },
    employeesUrl: {
        required: false,
        url: true,
    },
    userSyncUrl: {
        required: false,
        url: true,
    },
    ntlmLogin: {
        required: true
    },
    ntlmPassword: {
        required: true
    },
    proxyUrl: {
        url: true,
    },
};

export const getEducationRules = (type, isNtlm, gpbMode) => {
    if (!type) {
        return moodleRule;
    }
    if (type === 3) {
        return LMSRule;
    }
    let rule = { ...webTutorRule };
    if (isNtlm) {
        rule.ntlmPassword ={ required: true };
        rule.ntlmLogin ={ required: true };
    }
    if (gpbMode) {
        rule.userSyncUrl = {
            required: false,
            url: true,
        };
    }
    return rule;
};
