import React, { useState, useEffect, useContext, useMemo } from 'react';
import { GettextContext, InjectorContext } from '../../context';
import { useDispatch, useSelector } from 'react-redux';
import FetchApi from '../../REST';
import iconCreate from '../../../../images/course-icon-create.svg';
import { updateCourse,  deleteCoursesFromList } from '../../../toolkit/actions';
import { deleteCourses, fetchDaemonsMessage } from 'root/assets/webpack/src/js/toolkit/think';
import { ReactModal } from 'root/assets/webpack/src/js/components/ReactModal';
import CoursesModal from './CoursesModal';
import { hasAccess } from '../../../reselect';
import TimeSelect from 'rootReact/components/TimeSelect';
import style from './toolbarStule.module.less';

function ToolbarButtons({
    ids,
    checkedCourses,
    checkboxReport,
    setCourseName,
    setStartTimer,
    startTimer,
    setPopupDelete,
    setPopupDeleteDone,
    popupDelete,
    learning,
    setIdsForDelete,
    idsForDelete
}) {
    const gettext = useContext(GettextContext);
    const dispatch = useDispatch();
    const coursesList = useSelector((state) => state.courses.courses);
    const injector = useContext(InjectorContext);
    const [visible, setVisible] = useState(false);
    const [activeBtns, setActiveBtns] = useState(false);
    const [activeBtnEdit, setActiveBtnEdit] = useState(false);
    const [activeBtnDelete, setActiveBtnDelete] = useState(false);
    const [deleteTimeout, setDeleteTimeout] = useState(null);
    const [loadDisable, setLoadDisable] = useState(false);
    const [selectRange, setSelectRange] = useState(false);
    const checkedName = checkedCourses.map(item => item.name);
    const userName = checkedCourses.map(item => item.user.name);
    const roleWithReport = useSelector(state => hasAccess(state, { sectionId: 1, rightName: 'report_education' }));
    const roleWithSave = useSelector(state => hasAccess(state, { sectionId: 8, rightName: 'course_save' }));
    const roleWithDelete = useSelector(state => hasAccess(state, { sectionId: 8, rightName: 'course_delete' }));
    const manageCourse = useSelector(state => state.auth.auth.license.features.manageCourse);
    const auth = useSelector(state => state.auth.auth);

    useEffect(() => {
        if (checkedCourses.some(item => item.displayForStat === false)) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    }, [checkedCourses]);

    useEffect(() => {
        if (checkedCourses.length !== 0) {
            setActiveBtns(true);
        } else {
            setActiveBtns(false);
        }
    }, [checkedCourses]);

    //смотрит на роли и суперадмина, роль может редактировать только свои курсы
    useEffect(() => {
        if (checkedCourses.length === 1 && (auth.adminId === 0 || (auth.fullName === userName.toString() && roleWithSave))) {
            setActiveBtnEdit(true);
        } else {
            setActiveBtnEdit(false);
        }
    }, [checkedCourses]);

    //смотрит на роли и суперадмина, роль может удалять только свои курсы
    useEffect(() => {
        let checkName = userName.every(i => i === auth.fullName);
        if (checkedCourses.length !== 0 && (auth.adminId === 0 || (checkName && roleWithDelete))) {
            setActiveBtnDelete(true);
        } else {
            setActiveBtnDelete(false);
        }
    }, [checkedCourses]);

    const handleChangeVisible = async () => {
        setLoadDisable(true);
        try {
            const response = await FetchApi('/courses/set-for-visible').post({
                body: {
                    flag: visible,
                    coursesId: ids,
                },
            });
            if (response && response.isError) {
                throw response;
            }
            const coursesUpdate = ids.forEach((id) => {
                let findItem = coursesList.find((item) => item.id === id);
                if (findItem) {
                    dispatch(updateCourse({ ...findItem, displayForStat: visible }));
                }
            });
            setLoadDisable(false);
        } catch (e) {
            console.log(e);
            setLoadDisable(false);
        }
    };

    const handleEdit = async () => {
        const id = Number(ids);
        const modal = injector.instantiate(ReactModal);
        await modal.open(
            {
                component: CoursesModal,
                props: {
                    id
                },
            },
            'md'
        );
    };

    const handleCreate = async () => {
        const modal = injector.instantiate(ReactModal);
        await modal.open(
            {
                component: CoursesModal,
            },
            'md'
        );
    };

    const handleReport = async (date) => {
        try {
            const response = await FetchApi('/daemon/start').post({
                body: {
                    type: 2,
                    data: {
                        reportType: 'education',
                        includeDeletedEmployees: !!date.includeDeletedEmployees,
                        selectedData: {
                            all: 1,
                            includeDeletedCourses: checkboxReport,
                            courses: ids
                        },
                        dateFrom: date.from,
                        dateTo: date.to,
                    },
                },
            });
            if (response && response.isError) {
                throw response;
            }
            setSelectRange(false);
            dispatch(fetchDaemonsMessage(response));
        } catch (e) {
            console.log(e);
        }
    };

    const handleDelete = () => {
        setCourseName(checkedName);
        setStartTimer(true);
        setPopupDelete(true);
        setIdsForDelete(ids);
        dispatch(deleteCoursesFromList(ids));
    };

    const deleteFull = async () => {
        await dispatch(deleteCourses(idsForDelete));
        setPopupDeleteDone(true);
    };

    useEffect(() => {
        if (startTimer) {
            setDeleteTimeout(setTimeout(deleteFull, 8000));
        } else {
            setDeleteTimeout(clearTimeout(deleteTimeout));
        }
    }, [startTimer]);

    return (
        <div className={'react-courses_toolbar'}>
            <div className={'react-courses_toolbar-left'}>
                {!visible ? (
                    <button onClick={handleChangeVisible}
                        className={`react-courses_toolbar-btns courses-tooltip-hover ${activeBtns ? 'react-courses_toolbar-btns-active' : ''}`}
                        disabled={!activeBtns || loadDisable}

                    >
                        <svg className={'react-courses_toolbar-btns-not-check'}>
                            <use xlinkHref={'/image/icoSprite.svg#courses-not-check'}></use>
                        </svg>
                        {gettext.getString('Не видно в системе')}
                    </button>
                ) : (
                    <button onClick={handleChangeVisible}
                        className={`react-courses_toolbar-btns courses-tooltip-hover ${activeBtns ? 'react-courses_toolbar-btns-active' : ''}`}
                        disabled={!activeBtns || loadDisable}
                    >
                        <svg className={'react-courses_toolbar-btns-check'}>
                            <use xlinkHref={'/image/icoSprite.svg#courses-check'}></use>
                        </svg>
                        {gettext.getString('Видно в системе')}
                    </button>
                )}
                <div className={'react-courses_tooltip'}>
                    {gettext.getString('На дашборде и вкладке Знания, в ключевых показателях и отчетах')}
                </div>
                {learning.type === 2 &&
                    <button onClick={handleEdit}
                        className={`react-courses_toolbar-btns ${activeBtnEdit ? 'react-courses_toolbar-btns-active' : ''}`}
                        disabled={!activeBtnEdit || !roleWithReport}
                    >
                        <svg className={'react-courses_toolbar-btns-edit'}>
                            <use xlinkHref={'/image/icoSprite.svg#courses-edit'}></use>
                        </svg>
                        {gettext.getString('Редактировать')}
                    </button>
                }
                {learning.type === 2 &&
                    <button onClick={handleDelete}
                        className={`react-courses_toolbar-btns ${activeBtnDelete ? 'react-courses_toolbar-btns-active' : ''}`}
                        disabled={!activeBtnDelete || popupDelete}
                    >
                        <svg className={'react-courses_toolbar-btns-delete'}>
                            <use xlinkHref={'/image/icoSprite.svg#courses-delete'}></use>
                        </svg>
                        {gettext.getString('Удалить')}
                    </button>
                }
            </div>
            <div className={'react-courses_toolbar-right'}>
                <button
                    onClick={() => setSelectRange(true)}
                    disabled={auth.adminId !== 0 && !roleWithReport}
                    className={`react-courses_toolbar-report ${(auth.adminId !== 0 && !roleWithReport) && 'btn-report_disable'}`}>
                    <svg className={'react-courses_toolbar-btns-report'}>
                        <use xlinkHref={'/image/icoSprite.svg#courses-report'}></use>
                    </svg>
                    {gettext.getString('Скачать отчет по обучению')}
                </button>
                {selectRange && <div className={style.timeWrapper}>
                    <TimeSelect setParams={handleReport} closeWindow={() => setSelectRange(false)}/>
                </div>}
                {learning.type === 2 &&
                    <button
                        onClick={handleCreate}
                        className={`react-courses_toolbar-create ${((auth.adminId !== 0 && !roleWithSave) || !manageCourse) && 'btn-report_disable'}`}
                        disabled={(auth.adminId !== 0 && !roleWithSave) || !manageCourse}
                    >
                        <img src={iconCreate} />
                        {gettext.getString('Новый курс')}
                    </button>
                }
            </div>
        </div>
    );
}

export default ToolbarButtons;
