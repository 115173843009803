import React, { useContext } from 'react';
import { ConfigContext } from 'rootReact/context';
import { useSelector } from 'react-redux';
import style from './Footer.module.less';
import Link from 'UI/Link';
import Tooltip from 'UI/Tooltip';
import { LanguageSelect } from 'rootReact/shared/LanguageSelect';

function Footer ({ languages, gettext, isMenuOpen, footerRef, footerClass, isIconExist, logout }) {
    const config = useContext(ConfigContext);
    const fullName = useSelector((state) => state.auth.auth.fullName);
    const userName = useSelector((state) => state.auth.auth.username);
    const projectFooterName = config.PROJECT_FOOTER_NAME;
    const projectUrl = config.PROJECT_URL;
    const supportPhone = config.SUPPORT_PHONE;
    const supportEmail = config.SUPPORT_EMAIL;
    const supportPhonePure = supportPhone ? supportPhone.replace(/\D+/gim, '') : supportPhone;
    const supportEmailAddress = `mailto:${supportEmail}?subject=${supportEmail}`;

    const getShortName = (name) => {
        return name.slice(0, name.indexOf(' ') + 2) + '.';
    };

    const getSupport = () => {
        if (supportEmail || supportPhone) {
            if (config.SUPPORT_PRIORITY !== 'email') {
                return (
                    <>
                        <Link children={supportPhone}
                            linkStyle={'tertiary'}
                            size={'sm'}
                            href={'tel:' + +supportPhonePure}
                        />
                    </>
                );
            } else {
                return (
                    <Link children={supportEmail}
                        id={'footer-support'}
                        linkStyle={'tertiary'}
                        size={'sm'}
                        href={supportEmailAddress}
                    />
                );
            }
        }
        if (config.SERVICE_URL) {
            return (
                <Link children={gettext.getString('ответы на частые вопросы')}
                    id={'footer-support'}
                    size={'sm'}
                    linkStyle={'tertiary'}
                    href={config.SERVICE_URL}
                    target={'_blank'}
                />
            );
        }
    };

    return (
        <div className={`${style.footer} ${footerClass} ${isMenuOpen ? style.footerOpen : style.footerClose}`} ref={footerRef}>
            <div className={style.languageWrapper}>
                <LanguageSelect languages={languages}
                    isShort={!isMenuOpen}
                    buttonStyle={'secondary'}
                />
            </div>
            <div className={`${style.floors} ${style.floorsOpen} ${style.floorsClose}`}>
                <div className={style.floorsItem}>
                    <div className={style.twoFloor}>
                        <div className={style.item}>
                            <div className={style.fullName}>{getShortName(fullName)}</div>
                            <Tooltip textHint={gettext.getString('Выход')}
                                position={'right'}
                                inline={true}
                            >
                                <div className={style.icoExit}
                                    onClick={() => logout()}
                                >
                                    <svg>
                                        <use xlinkHref={'/image/icoSprite.svg#logout'}></use>
                                    </svg>
                                </div>
                            </Tooltip>
                        </div>
                        <div style={{ width: '100%', overflow: 'hidden' }}>
                            <div className={style.userName}>
                                {userName}
                            </div>
                        </div>
                    </div>
                    <div className={style.oneFloor}>
                        <div>
                            <Link children={projectFooterName}
                                size={'sm'}
                                id={'project-footer-name'}
                                data-testid={'project-footer-name'}
                                linkStyle={'tertiary'}
                                href={projectUrl}
                                target={'_blank'} />
                        </div>
                        <div>
                            {getSupport()}
                        </div>
                    </div>
                </div>
                {isIconExist && <div className={`${style.onlyIcon}`}>
                    <Tooltip textHint={gettext.getString('Выход')}
                        position={'right'}
                        inline={true}
                    >
                        <div className={style.icoExit}
                            onClick={() => logout()}
                        >
                            <svg>
                                <use xlinkHref={'/image/icoSprite.svg#logout'}></use>
                            </svg>
                        </div>
                    </Tooltip>
                </div>}
            </div>
        </div>
    );
}

export default Footer;
