import React, { useContext, useState, useEffect } from 'react';
import { GettextContext, InjectorContext } from '../../context';
import { instructionFileName } from './const';
import FetchApi from '../../REST';
import ReactIcon from '../ReactIcons';
import { ReactModal } from 'root/assets/webpack/src/js/components/ReactModal';
import Modal from './modal.jsx';
import PluginR7Setting from 'rootReact/components/PluginR7Setting/PluginR7Setting.jsx';

function OutlookManifest({ manifestLink, licenseIsValid }) {
    const gettext = useContext(GettextContext);
    const injector = useContext(InjectorContext);
    const langCode = gettext.currentLanguage;
    const [feedbackLink, setFeedbackLink] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            let link = await FetchApi('/outlook-url/get-url').get();
            if (link && !link.isError) {
                setFeedbackLink(link);
            }
        };
        fetchData();
    },
    []);

    const downloadInstruction = () => {
        if (!licenseIsValid) return;
        const fetchApi = FetchApi(`/document/outlook_${langCode}.pdf`, true);
        fetchApi.download(`/document/outlook_${langCode}.pdf`, instructionFileName[langCode]);
    };

    const downloadManifest = () => {
        if (!licenseIsValid) return;
        const fetchApi = FetchApi(manifestLink, true);
        fetchApi.download(manifestLink,'manifest.xml');
    };

    const openModal = async () => {
        const modal = injector.instantiate(ReactModal);
        return await modal.open({
            component: Modal,
            props: { text: 'text' }
        }, 'nm');
    };

    const clipboardData = async (e) => {
        e.preventDefault();
        if (!licenseIsValid) return;
        const clipText = `${location.protocol}//${location.host}${manifestLink}`;
        await clipboard(clipText);
    };

    const clipboard = async (text) => {
        if (navigator && navigator.clipboard) {
            await navigator.clipboard.writeText(text);
        } else if (window.clipboardData) {
            window.clipboardData.setData('Text', text);
        }
    };

    const getFeedbackLink = async () => {
        let isLinkGenerate = true;
        if (feedbackLink) {
            isLinkGenerate = await openModal();
        }
        if (!isLinkGenerate) return;
        let isGenerated = await FetchApi('/outlook-url/generate-url').get();
        if (!isGenerated) return;
        let link = await FetchApi('/outlook-url/get-url').get();
        if (!link.isError) {
            setFeedbackLink(link);
        }
    };

    const copyClipboard = async (e) => {
        e.preventDefault();
        if (!licenseIsValid) return;
        await clipboard(feedbackLink);
    };

    return (
        <div className={'outlook_manifest'}>
            <div>
                <img src={'/attack.svg'} alt={'logo'} className={'outlook_logo'}></img>
            </div>
            <div className={'outlook_text'}>
                <span className={'outlook_subtext'}>
                    {gettext.getString('Скачайте')} <a className={!licenseIsValid ? 'nolink' : ''} onClick={downloadManifest}>{gettext.getString('файл манифеста')} </a>
                    {gettext.getString('или')}
                    <a className={!licenseIsValid ? 'nolink' : ''} onClick={(e) => clipboardData(e)}> {gettext.getString('скопируйте ссылку')}&nbsp;<ReactIcon handleClick={() => true} inline={true} disabled={!licenseIsValid} icoClass={'copy'}/> </a>
                    {gettext.getString('для установки плагина на Microsoft Outlook.')}
                </span>
                <div className={'outlook_instructions-wrapper outlook_subtext'}>
                    <a className={!licenseIsValid ? 'nolink' : ''}
                        onClick={downloadInstruction}>{gettext.getString('Инструкция по установке для Outlook')}</a>
                </div>
                <PluginR7Setting licenseIsValid={licenseIsValid} />
                <div className={'outlook_feedback-wrapper'}>
                    <a className={!licenseIsValid ? 'nolink outlook_link-generate' : 'outlook_link-generate'}
                        onClick={getFeedbackLink}>{gettext.getString('Ссылка на отчет по обратной связи')} </a>
                    {feedbackLink && <>
                        <div className={'outlook_copy-wrapper'}>
                            <ReactIcon handleClick={copyClipboard} inline={true} disabled={!licenseIsValid} icoClass={'copy'}/>
                        </div>
                        <span className={'feedback-link'}>{feedbackLink}</span></>}
                </div>
            </div>
        </div>
    );
}

export default OutlookManifest;
