import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import style from './ModalWindow.module.less';
import { ModalContext } from 'rootReact/context';

const ModalWindow = () => {
    const { isOpen, ModalContent, close, propsDrilling, size='lg' } = useContext(ModalContext);
    if (isOpen) {
        return ReactDOM.createPortal(
            <div className={style.wrapper}>
                <div className={`${style.content} ${size === 'lg' ? style.modalLg : style.modalMd}`}>
                    {typeof ModalContent === 'function' ? <div><ModalContent {...propsDrilling} onModalClose={close}/></div> : ModalContent}
                </div>
            </div>,
            document.querySelector('#modal-root')
        );
    } else {
        return null;
    }
};

export { ModalWindow };
