import React from 'react';
import style from './Tooltip.module.less';
import PropTypes from 'prop-types';

function Tooltip({ children, textHint, inline, position }) {

    return (
        <div className={style.tooltipWrapper}
            style={{ display: inline ? 'inline-flex' : '', width: inline ? 'fit-content' : '100%' }}>
            {textHint ? (<div className={`${style.reactTooltip} ${style[position]}`}
            >{textHint}</div>) : null}
            {children}
        </div>
    );
}

Tooltip.propTypes = {
    textHint: PropTypes.oneOfType ([
        PropTypes.object,
        PropTypes.string
    ])
};

export default Tooltip;
