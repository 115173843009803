import React from 'react';
import style from './TabSelect.module.less';
import PropTypes from 'prop-types';

export const TabSelect = ({ tabs }) => {
    return (
        <div className={style.wrapper}>
            {tabs.map((tab) => (
                <button key={tab.id}
                    role='tab'
                    aria-selected={tab.isActive}
                    data-testid={tab.id}
                    onClick={tab.setIsActive}
                    disabled={tab.disabled}
                    className={`${style.tab} ${tab.isActive ? style.active : ''}`}>
                    {tab.name}
                </button>
            ))}
        </div>
    );
};

TabSelect.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            name: PropTypes.node.isRequired,
            disabled: PropTypes.bool,
            isActive: PropTypes.bool,
            setIsActive: PropTypes.func.isRequired,
        })
    ).isRequired
};
