import * as React from 'react';
import { Provider } from 'react-redux';
import moment from 'moment-timezone';
import { Cookies } from '../helpers/Cookies';
import { SupportedLanguage } from '../const';
import {
    GettextContext,
    ConfigContext,
    LocationContext,
    InjectorContext,
    ModalContext,
    MomentContext
} from '../context';
import { ModalWindow } from 'rootReact/UI/ModalWindow/ModalWindow.jsx';
import useModal from 'rootReact/hook/useModal.jsx';

function ReduxDecorator ({ reduxProvider, render, gettextCatalog, location, injector, passThrough }) {
    const modal = useModal();
    moment.locale('ru', {
        monthsShort: 'янв_фев_мар_апр_май_июн_июл_авг_сен_окт_ноя_дек'.split('_'),
        longDateFormat : {
            ll: 'D MMM YYYY г.'
        },
    });
    let lang = new Cookies().getCookie('langCode');

    // проверка на то, что мы поддерживаем язык
    if (SupportedLanguage.indexOf(lang) === -1) {
        lang = 'en';
    }

    // Проверка на наличие и корректность значения lang в библиотеке
    if (lang && moment.locales().includes(lang)) {
        moment.locale(lang);
    }

    return (
        <Provider store={reduxProvider}>
            <LocationContext.Provider value={location}>
                <InjectorContext.Provider value={injector}>
                    <ConfigContext.Provider value={window.config}>
                        <GettextContext.Provider value={gettextCatalog}>
                            <ModalContext.Provider value={modal}>
                                <MomentContext.Provider value={moment}>
                                    <ModalWindow />
                                    <App render={render} passThrough={passThrough}/>
                                </MomentContext.Provider>
                            </ModalContext.Provider>
                        </GettextContext.Provider>
                    </ConfigContext.Provider>
                </InjectorContext.Provider>
            </LocationContext.Provider>
        </Provider>
    );
}

const App = ({ render, passThrough }) => {
    return (<>{render(passThrough)}</>);
};

export default ReduxDecorator;
