const POST = 'POST';
const GET = 'GET';
const PUT = 'PUT';
const DELETE = 'DELETE';

class FetchApi {
    constructor (url, directLink = false) {
        this.csrfToken = window.csrfToken;
        this.headers = '';
        this.defaultHeaders = {
            'X-Csrf-Token': window.csrfToken,
            'Enable-Session': 1
        };
        this.url = directLink ? window.config.SYSTEM_URL + url : window.config.SYSTEM_URL + window.config.API_URL + url;
    }

    setOptions = (method, clientHeaders = {}, body, isFile = false) => {
        const options = {
            method,
            headers: { ...this.defaultHeaders, ...clientHeaders }
        };
        if (isFile) return options;
        if (method === POST || method === PUT) {
            options.headers['content-type'] = 'application/json;charset=UTF-8';
        }
        options.body = JSON.stringify(body);
        return options;
    }

    setError = (err) => {
        window.console.log(err);
        return { err, isError: true };
    }

    getData = async (url, options ,callback, isBlob, isDelete) => {
        let response = await fetch(url, options);
        if (!response.ok) throw await response.json();
        this.headers = response.headers;
        if (isDelete) return true;
        let data;
        if (isBlob) {
            return await response.blob();
        } else {
            data = await response.json();
        }
        if (callback) {
            callback(data);
            return;
        }
        return data;
    }

    get = async (id = null, params = null, callback, isBlob) => {
        try {
            let options = this.setOptions(GET, params && params.headers);
            let url = this.url + (id ? `/${id}` : '');
            return await this.getData(url, options, callback, isBlob);
        } catch (err) {
            return this.setError(err);
        }
    }

    delete = async (id = null, params = null, callback) => {
        try {
            let options = this.setOptions(DELETE, params && params.headers);
            let url = this.url + (id ? `/${id}` : '');
            await this.getData(url, options, callback, false, true);
            return id;
        } catch (err) {
            return this.setError(err);
        }
    }

    /*deleteMulti = async (params = {}, callback) => {
        try {
            let options = this.setOptions(DELETE, params && params.headers, params.body);
            return await this.getData(this.url, options, callback);
        } catch (err) {
            return this.setError(err);
        }
    }*/

    post = async (params = {}, callback, isBlob) => {
        try {
            let options = this.setOptions(POST, params && params.headers, params.body );
            return await this.getData(this.url, options, callback, isBlob);
        } catch (err) {
            return this.setError(err);
        }
    }

    put = async (id = null, params, callback) => {
        try {
            let options = this.setOptions(PUT, params && params.headers, params.body );
            let url = this.url + (id ? `/${id}` : '');
            return await this.getData(url, options, callback);
        } catch (err) {
            return this.setError(err);
        }
    }

    download = (url, fileName, body) => {
        try {
            let req = new XMLHttpRequest();
            let method = GET;
            if (body) {
                method = POST;
            }
            req.open(method, url, true);
            req.responseType = 'blob';
            body && req.setRequestHeader('Content-type', 'application/json');
            req.setRequestHeader('X-Csrf-Token', window.csrfToken);
            req.setRequestHeader('Enable-Session', 1);
            req.onload = function () {
                let blob = req.response;
                let contentType = req.getResponseHeader('content-type');
                if (window.navigator.msSaveOrOpenBlob) {
                    // Internet Explorer
                    window.navigator.msSaveOrOpenBlob(new Blob([blob], { type: contentType }), fileName);
                } else {
                    let link = document.createElement('a');
                    document.body.appendChild(link);
                    link.download = fileName;
                    link.href = window.URL.createObjectURL(blob);
                    link.click();
                    document.body.removeChild(link); //remove the link when done
                }
            };
            req.send(body);
        } catch (err) {
            return this.setError(err);
        }
    }

    upload = async (file, fileName, params) => {
        try {
            let options = this.setOptions(POST, params && params.headers, {}, true);
            let formData = new FormData();
            formData.append('file', file, fileName);
            options.body = formData;
            return await this.getData(this.url, options);
        } catch (e) {
            return this.setError(e);
        }
    }
}

export default (url, directLink) => {return new FetchApi(url, directLink);};
