import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import style from './style.module.less';
import Translate from '../../Translate.jsx';
import { Button } from 'rootReact/UI/Button';
import ReactIcon from 'rootReact/components/ReactIcons';
import DatePicker from 'react-datepicker';
import { getCurrentLanguage } from 'rootReact/hook/getCurrentLanguage.jsx';
import { MomentContext } from 'rootReact/context';

function TimeSelect({ setParams, closeWindow }) {
    const [now] = useState(new Date().valueOf());
    const [period, setPeriod] = useState(null);
    const [year, setYear] = useState(new Date().getFullYear());
    const [currentYear] = useState(new Date().getFullYear());
    const [currentMonth] = useState(new Date().getMonth() + 1);
    const [isRange, setIsRange] = useState(true);
    const [HALF_YEAR] = useState(new Date().setMonth(currentMonth - 7));
    const [MONTH_9] = useState(new Date().setMonth(currentMonth - 10));
    const [YEAR] = useState(new Date().setMonth(currentMonth - 13));
    const [startRange, setStartRange] = useState(now);
    const [allTime, setAllTime] = useState(false);
    const [areFiredEmployeesSelect, setAreFiredEmployeesSelect] = useState(false);
    const moment = useContext(MomentContext);
    const [endRange, setEndRange] = useState(null);
    const lang = getCurrentLanguage();
    const createdAt = useSelector(state => new Date(state.auth.auth.createdAt).valueOf());

    const isPeriodDisabled = (cell) => {
        if (year === currentYear && Number.isInteger(cell)) {
            return cell > currentMonth;
        } else if (new Date(createdAt).getFullYear() === year && Number.isInteger(cell)) {
            return getMinDate(cell);
        }
        if (year === currentYear && cell === '1 quarter' && currentMonth < 3 || getMinDate(1)) {
            return true;
        }
        if (year === currentYear && cell === '2 quarter' && currentMonth < 6 || getMinDate(4)) {
            return true;
        }
        if (year === currentYear && cell === '3 quarter' && currentMonth < 9 || getMinDate(7)) {
            return true;
        }
        if (year === currentYear && cell === '4 quarter' && currentMonth < 12 || getMinDate(10)) {
            return true;
        }
        if (cell === 'HalfYear' && createdAt > HALF_YEAR) {
            return true;
        }
        if (cell === '9 months' && createdAt > MONTH_9) {
            return true;
        }
        if (cell === 'year' && createdAt > YEAR) {
            return true;
        }
    };

    const isDataPickerClass = (target) => {
        let isContain = false;
        let classList = target.classList;
        classList.forEach(item => {
            if (item.indexOf('datepicker') > -1) {
                isContain = true;
            }
        });
        return isContain;
    };

    const closeModal = (e) => {
        let target = e.target;
        let close = true;
        while (target) {
            if (!target.classList || !target.classList.contains) {
                break;
            }
            if (target.classList.contains(style.wrapper) || isDataPickerClass(target) || target.classList.contains('cert-dowloader-link')) {
                close = false;
                break;
            }
            target = target.parentNode;
        }
        if (close && closeWindow) {
            closeWindow();
        }
    };

    const getMinDate = (minMonth) => {
        let value = true;
        let createdYear = new Date(createdAt).getFullYear();
        let month = new Date(createdAt).getMonth() + 1;
        if (year < createdYear) value = false;
        if (year >= createdYear && currentYear >= year) {
            if (year === createdYear && month > minMonth) {
                return true;
            }
            value = false;
        }
        return value;
    };

    const setCellClass = (cell) => {
        let cellClass = style.cell;
        if (period === cell) {
            cellClass += ' ' + style.activeCell;
        }
        if (isPeriodDisabled(cell)) {
            cellClass += ' ' + style.disabled;
        }
        return cellClass;
    };

    const getParams = (periodValues) => {
        return { from: periodValues.from/1000, to: periodValues.to/1000, includeDeletedEmployees: areFiredEmployeesSelect };
    };

    const changeRanges = (date) => {
        const [start, end] = date;
        setAllTime(false);
        setStartRange(start);
        setEndRange(end);
    };

    const getPeriod = (values) => {
        const periodValues = {
            from: '',
            to: ''
        };
        if (isRange) {
            periodValues.from = startRange;
            periodValues.to = endRange;
            return periodValues;
        }
        if (!values) {
            values = period;
        }
        if (values === 'HalfYear' || values === 'year' || values === '9 months') {
            periodValues.to = now;
            if (values === 'HalfYear') {
                periodValues.from = HALF_YEAR;
            } else if (values === 'year') {
                periodValues.from = YEAR;
            } else {
                periodValues.from = MONTH_9;
            }
        }

        if (values && values.indexOf && values.indexOf('quarter') > -1) {
            if (values.indexOf('1') > -1) {
                periodValues.from = new Date(year, 0, 1).valueOf();
                periodValues.to = new Date(year, 3, 0).valueOf();
            }
            if (values.indexOf('2') > -1) {
                periodValues.from = new Date(year, 3, 1).valueOf();
                periodValues.to = new Date(year, 6, 0).valueOf();
            }
            if (values.indexOf('3') > -1) {
                periodValues.from = new Date(year, 6, 1).valueOf();
                periodValues.to = new Date(year, 9, 0).valueOf();
            }
            if (values.indexOf('4') > -1) {
                periodValues.from = new Date(year, 9, 1).valueOf();
                periodValues.to = new Date(year, 12, 0).valueOf();
            }
        }

        if (Number.isInteger(values)) {
            periodValues.from = new Date(year, values - 1, 1).valueOf();
            periodValues.to = new Date(year, values, 0).valueOf();
        }
        return periodValues;
    };

    const setAllDate = () => {
        if (!allTime) {
            setEndRange(now);
            setStartRange(createdAt);
        }
        setAllTime(!allTime);
    };

    const handleFiredEmployees = () => {
        setAreFiredEmployeesSelect(!areFiredEmployeesSelect);
    };

    const start = () => {
        const periodValues = getPeriod();
        setParams(getParams(periodValues));
    };

    const onPeriodClick = (cell) => {
        if (isPeriodDisabled(cell)) return;
        setAllTime(false);
        if (cell) {
            const { from, to } = getPeriod(cell);
            setStartRange(from);
            setEndRange(to);
        }
        setPeriod(cell);
    };

    useEffect(() => {
        document.body.addEventListener('click', closeModal);
        return () => document.body.removeEventListener('click', closeModal);
    }, []);

    return (
        <div className={style.wrapper}>
            <div className={style.header}>
                <span className={style.title}>
                    <Translate>Выберите период</Translate>
                </span>
                <div className={style.buttonWrapper}>
                    <Button btnStyle={'default'}
                        size={'sm'}
                        active={isRange}
                        handleClick={() => setIsRange(true)}
                    >В календаре</Button>
                    <Button btnStyle={'default'}
                        active={!isRange}
                        handleClick={() => setIsRange(false)}
                        size={'sm'}
                    >Стандартный</Button>
                </div>
            </div>
            { isRange &&
                    <div className={style.datepickerWrapper}>
                        <DatePicker selected={now}
                            monthsShown={3}
                            selected={startRange}
                            startDate={startRange}
                            endDate={endRange}
                            onChange={changeRanges}
                            minDate={createdAt}
                            maxDate={now}
                            selectsRange
                            inline
                        ></DatePicker>
                    </div>
            }
            {!isRange && <div className={style.block}>
                <div className={style.grow}>
                    <div className={setCellClass('HalfYear')}
                        onClick={() => onPeriodClick('HalfYear')}
                    ><Translate>Полугодие</Translate></div>
                    <div className={setCellClass('9 months')}
                        onClick={() => onPeriodClick('9 months')}
                    >9 <Translate>месяцев</Translate></div>
                    <div className={setCellClass('year')}
                        onClick={() => onPeriodClick('year')}
                    ><Translate>Год</Translate></div>
                </div>
                <div className={style.grow}
                    style={{ paddingLeft: 0, flexGrow: 4 }}
                >
                    <div className={style.year}>
                        <div className={`${style.arrow} ${new Date(createdAt).getFullYear() === year ? style.arrowDisabled : ''}`}>
                            <ReactIcon disabled={new Date(createdAt).getFullYear() === year}
                                handleClick={() => new Date(createdAt).getFullYear() !== year && setYear(year - 1)}
                                icoClass={'direction'}/>
                        </div>
                        <div>{year}</div>
                        <div className={`${ style.arrow } ${ style.reverse } ${currentYear === year ? style.arrowDisabled : ''}`}>
                            <ReactIcon disabled={currentYear === year}
                                handleClick={() => currentYear !== year && setYear(year + 1)}
                                icoClass={'direction'}/>
                        </div>
                    </div>
                    <div className={style.body}>
                        <div className={setCellClass('1 quarter')}
                            onClick={() => onPeriodClick('1 quarter')}
                        >1 <Translate>квартал</Translate></div>
                        <div className={setCellClass(1)}
                            onClick={() => onPeriodClick(1)}
                        ><Translate>Январь</Translate></div>
                        <div className={setCellClass(2)}
                            onClick={() => onPeriodClick(2)}
                        ><Translate>Февраль</Translate></div>
                        <div className={setCellClass(3)}
                            onClick={() => onPeriodClick(3)}
                        ><Translate>Март</Translate></div>
                        <div className={setCellClass('2 quarter')}
                            onClick={() => onPeriodClick('2 quarter')}
                        >2 <Translate>квартал</Translate></div>
                        <div className={setCellClass(4)}
                            onClick={() => onPeriodClick(4)}
                        ><Translate>Апрель</Translate></div>
                        <div className={setCellClass(5)}
                            onClick={() => onPeriodClick(5)}
                        ><Translate>Май</Translate></div>
                        <div className={setCellClass(6)}
                            onClick={() => onPeriodClick(6)}
                        ><Translate>Июнь</Translate></div>
                        <div className={setCellClass('3 quarter')}
                            onClick={() => onPeriodClick('3 quarter')}
                        >3 <Translate>квартал</Translate></div>
                        <div className={setCellClass(7)}
                            onClick={() => onPeriodClick(7)}
                        ><Translate>Июль</Translate></div>
                        <div className={setCellClass(8)}
                            onClick={() => onPeriodClick(8)}
                        ><Translate>Август</Translate></div>
                        <div className={setCellClass(9)}
                            onClick={() => onPeriodClick(9)}
                        ><Translate>Сентябрь</Translate></div>
                        <div className={setCellClass('4 quarter')}
                            onClick={() => onPeriodClick('4 quarter')}
                        >4 <Translate>квартал</Translate></div>
                        <div className={setCellClass(10)}
                            onClick={() => onPeriodClick(10)}
                        ><Translate>Октябрь</Translate></div>
                        <div className={setCellClass(11)}
                            onClick={() => onPeriodClick(11)}
                        ><Translate>Ноябрь</Translate></div>
                        <div className={setCellClass(12)}
                            onClick={() => onPeriodClick(12)}
                        ><Translate>Декабрь</Translate></div>
                    </div>
                </div>
            </div>}
            <div className={style.footer}>
                <div className={style.timeWrapper}>
                    <div className={style.time}>
                        <div className={style.timeRange}>
                            {moment(startRange).locale(lang).format('L')}
                            {/*{startRange && new Date(startRange).toLocaleDateString()}*/}
                        </div>
                        {startRange && endRange && <div className={style.timeRange}> – </div>}
                        <div className={style.timeRange}>
                            {endRange && new Date(endRange).toLocaleDateString()}
                        </div>
                    </div>
                    <div className={style.downBlock}>
                        <div className={style.selectWrapper}>
                            {isRange && <div className={style.checkboxWrapper} onClick={() => {setAllDate();}}>
                                <div className={`${style.checkbox} ${allTime ? style.checkboxActive : ''}`}></div>
                                <div className={style.text}><Translate>За все время</Translate></div>
                            </div>}
                            <div className={style.checkboxWrapper} onClick={handleFiredEmployees}>
                                <div className={`${style.checkbox} ${areFiredEmployeesSelect ? style.checkboxActive : ''}`}></div>
                                <div className={style.text}><Translate>Включить в отчет удаленных сотрудников</Translate></div>
                            </div>
                        </div>
                        <div>
                            <Button btnStyle={'prime'}
                                handleClick={start}
                                size={'sm'}
                                disabled={!startRange || !endRange}>
                                <Translate>Подготовить</Translate>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TimeSelect;
