import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FetchApi from '../REST';

const initialState = {
    target: {},
    load: false,
    error: false
};

export const targetFetch = createAsyncThunk(
    'target/fetchTarget',
    async (targetId) => {
        const fetchApi = FetchApi('/targets');
        const response = await fetchApi.get(targetId + '?all-courses=1&all-attacks=1&courses-not-taken=1&courses-no-canceled=1');
        if (response.isError) {
            throw response.err;
        }
        return response;
    }
);

const targetSlice = createSlice({
    name: 'target',
    initialState,
    reducers: {},
    extraReducers: {
        [targetFetch.fulfilled]: (state, { payload }) => {
            state.target = payload;
            state.load = true;
            state.error = false;
        },
        [targetFetch.pending]: (state) => {
            state.load = false;
            state.error = false;
        },
        [targetFetch.rejected]: (state) => {
            state.load = true;
            state.error = true;
        }
    }
});

export default targetSlice.reducer;
