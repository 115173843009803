import FetchApi from '../REST/fetchApi';
import { Cookies } from '../helpers/Cookies';

export const useSetLanguage = async (lang) => {
    let fetchApi = FetchApi('/cache/clean');
    let cookies = new Cookies();
    let currentLang = cookies.getCookie('langCode');
    if (lang === currentLang) return null;
    cookies.setCookie('langCode', lang);
    await fetchApi.get();
    location.reload();
};
