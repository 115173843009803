import React from 'react';
import style from './TargetCardHeader.module.less';

export const TargetCardHeader = ({type, children}) => {
    return (
        <div className={`${style.wrapper} ${style[type]}`}>
            {children}
        </div>
    );
};
