import React, { useContext, useState } from 'react';
import style from './targetEntitiesCard.module.less';
import { Icon } from 'rootReact/UI/Icon';
import { GettextContext, MomentContext } from 'rootReact/context';
import Link from 'rootReact/UI/Link';
import Translate from 'rootReact/Translate.jsx';
import { TargetAttackCard } from 'rootReact/UI/TargetEntitiesCard/TargetAttackCard.jsx';
import { TargetReportCard } from 'rootReact/UI/TargetEntitiesCard/TargetReportCard.jsx';
import { Tooltip } from 'antd';
import { usePlural } from 'rootReact/hook';
import FetchApi from '../../REST';
import { saveAs } from 'file-saver';
import { getFilename } from 'rootReact/helpers/responseHeaders';

const COURSE = 'course';
const ATTACK = 'attack';
const MESSAGE = 'message';

const COURSE_SUCCESS = 5;
const COURSE_NOT_ASSIGNED = -4;
const COURSE_MISS = 7;

export const TargetEntitiesCard = ({ item, type, timeZone, isReported, status }) => {
    const gettext = useContext(GettextContext);
    const moment = useContext(MomentContext);
    const [certError, setCertError] = useState(false);
    const [certLoading, setCertLoading] = useState(false);
    const expiredDays = Math.floor((Date.now() - new Date(item.to).valueOf()) / 1000 /60/ 60 / 24);

    const getOverdueTime = (from, to, till) => {
        const dateTo = new Date(to);
        const dateTill = new Date(till);

        if (dateTill > dateTo) {
            return (<>
                <div className={`${style.errorText} ${style.text}`}>{
                    usePlural(expiredDays,
                        [
                            gettext.getString('Просрочен на {{count}} день', { count: expiredDays }),
                            gettext.getString('Просрочен на {{count}} дня', { count: expiredDays }),
                            gettext.getString('Просрочен на {{count}} дней', { count: expiredDays })
                        ]
                    )
                }</div>
                <div className={`${style.successText} ${style.text}`}>{`${gettext.getString('Пройден')} ${moment(dateTill).format('ll')}`}</div>
            </>);
        } else if (item.status === COURSE_MISS) {
            return (<>
                <div className={`${style.errorText} ${style.text}`}>{
                    usePlural(expiredDays,
                        [
                            gettext.getString('Просрочен на {{count}} день', { count: expiredDays }),
                            gettext.getString('Просрочен на {{count}} дня', { count: expiredDays }),
                            gettext.getString('Просрочен на {{count}} дней', { count: expiredDays })
                        ]
                    )
                }</div>
            </>);
        } else if (item.status === COURSE_SUCCESS) {
            return <div className={`${style.successText} ${style.text}`}>{`${gettext.getString('Пройден')} ${moment(dateTill).format('ll')}`}</div>;
        }
    };

    const convertSeconds = (seconds) => {
        if (seconds < 60) {
            return <Translate>меньше 1 мин.</Translate>;
        }
        if (seconds < 3600) {
            let minutes = Math.floor(seconds / 60);
            return <Translate options={{ min: minutes }}>{'{{min}} мин.'}</Translate>;
        } else {
            let hours = Math.floor(seconds / 3600);
            let minutes = Math.floor((seconds % 3600) / 60);
            // добавлена проверка hours > 1, так как на английском и итальянском, в отличие от русского, вывод фразы во множ. и единст. числе будет отличаться:
            // 2 ore 5 min/1 ora 5 min и 2 hrs. 5 min/1 hr. 5 min.
            if (hours > 1) {
                return <Translate options={{ hrs: hours, min: minutes }}>{'{{hrs}} ч. {{min}} мин.'}</Translate>;
            }
            return <Translate options={{ hr: hours, min: minutes }}>{'{{hr}} ч. {{min}} мин.'}</Translate>;
        }
    };

    const getHint = () => {
        if (item.status === COURSE_SUCCESS) {
            return (
                <div className={style.hint}>
                    <div><Translate options={{ count: Math.floor(item.score), total: '100' }}>{'Итоговый балл: {{count}} из {{total}}'}</Translate></div>
                    {!!item.sessionSeconds && <div><Translate>Время прохождения:</Translate> {convertSeconds(item.sessionSeconds)}</div>}
                    {!!item.sessionCount && <div><Translate options={{ count: item.sessionCount }}>{'Кол-во попыток: {{count}}'}</Translate></div>}
                </div>
            );
        } else {
            return (
                <div className={style.hint}>
                    <div><Translate options={{ count: Math.floor(item.score), total: '100' }}>{'Балл в последней попытке: {{count}} из {{total}}'}</Translate></div>
                    {!!item.sessionCount && <div><Translate options={{ count: item.sessionCount }}>{'Кол-во попыток: {{count}}'}</Translate></div>}
                </div>
            );
        }
    };

    const downloadCert = async () => {
        try {
            setCertError(false);
            setCertLoading(true);
            const fetchApi = FetchApi(`/certs/${item.certificateToken}/download-by-token`);
            let response = await fetchApi.get(false, null, null, true);
            if (response.isError) {
                throw response.err;
            }
            let fileName = getFilename(fetchApi.headers);
            saveAs(response, fileName);
            setCertLoading(false);
        } catch (e) {
            setCertError(true);
            setCertLoading(false);
        }

    };

    return (<>
        {type === COURSE ? <div className={style.wrapper}>
            <div className={style.diffTitle}>{item.name}</div>
            {item.status !== COURSE_NOT_ASSIGNED ? <>
                <div className={style.scoreWrapper}>
                    <Tooltip overlayInnerStyle={{ width: 'fit-content' }} title={getHint()} arrow={false}>
                        <div className={style.score}>
                            <Icon src={'/image/informationSprite.svg'}
                                id={'star_fill_ico'}
                                size={'sm'}
                            />
                            <div>{Math.floor(item.score)}</div>
                        </div>
                    </Tooltip>
                </div>
                <div className={`${style.timeWrapper} ${item.status === COURSE_SUCCESS ? style.mb1 : ''}`}>
                    <div className={style.diffText}>
                        {`${moment(item.from).format('ll')} — ${moment(item.to).format('ll')}`}
                    </div>
                    {getOverdueTime(item.from, item.to, item.educationTill)}
                </div>
                {item.status === COURSE_SUCCESS && <div className={style.certWrapper}>
                    <Icon src={'/image/informationSprite.svg'}
                        id={'pdf_ico'}
                        size={'xl'}
                    />
                    <div className={style.certText}>
                        <Link handleClick={downloadCert}
                            size={'sm'}
                            linkStyle={'secondary'}
                        >
                            <Translate>Скачать сертификат</Translate>
                        </Link>
                        <span className={style.certDate}>{item.certificateDuration ?
                            <span><Translate>Действителен до</Translate> {moment(item.educationTill).add(item.certificateDuration, 'days').format('ll')}</span>:
                            <Translate>Действует бессрочно</Translate>}</span>
                    </div>
                </div>}
            </> : null}
        </div> : ''}
        {type === ATTACK ? <TargetAttackCard timeZone={timeZone} item={item} isReported={isReported} status={status}/> : ''}
        {type === MESSAGE ? <TargetReportCard timeZone={timeZone} item={item}/> : ''}
    </>);
};
