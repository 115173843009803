import React, { useState } from 'react';

export default () => {
    const [isOpen, setIsOpen] = useState(false);
    const [ModalContent, setModalContent] = useState(null);
    const [propsDrilling, setProps] = useState(null);
    const [fulfilled, setFulfilled] = useState(null);

    const open = async (content, props) => {
        if (content) {
            return new Promise((success, rejected) => {
                setIsOpen(true);
                setModalContent(() => content);
                setProps(props);
                setFulfilled(() => success);
            });
        }
    };

    const close = (value) => {
        setIsOpen(false);
        setModalContent(null);
        setProps(null);
        fulfilled(value);
    };
    return { isOpen, ModalContent, open, close, propsDrilling };
};
