import React from 'react';
import style from './Item.module.less';
import PropTypes from 'prop-types';
import { classesAdd } from 'rootReact/helpers';
import { Checkbox } from 'UI/Checkbox';
import Radiobutton from 'UI/Radiobutton';

function Item({
    children,
    isDisabled,
    size,
    handleClick,
    dataTestId,
    id,
    checked,
    checkedType,
    placeholder,
    searchValue
}) {

    const highlightText = (text, highlight) => {
        if (!highlight) return text;
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return parts.map((part, i) =>
            part.toLowerCase() === highlight.toLowerCase() ?
                <span className={style.highlight} key={i}>{part}</span> :
                part
        );
    };

    return (
        <div className={`${style[size]} ${style.item} ${isDisabled && !checked ? style.disabled : ''}`}
            onClick={(e) => {handleClick(e);}}
            data-testid={dataTestId}
            id={id}
        >
            {(checkedType === 'radiobutton' || checkedType === 'checkbox') && <div>
                {checkedType === 'radiobutton' ? <Radiobutton isDisabled={isDisabled} size={size} handleClick={() => {}} checked={checked}/> :
                    <Checkbox disabled={isDisabled} checked={checked} size={size} handleClick={() => {}}/>}
            </div>}
            {children && <div className={classesAdd(style.itemText, style[size])}>
                {highlightText(children, searchValue)}
            </div>}
            {placeholder && <div className={classesAdd(style.placeholder, style[size])} data-testid={'ui-item-placeholder'}>
                {placeholder}
            </div>}
        </div>
    );
}

Item.propTypes = {
    isDisabled: PropTypes.bool,
    checked: PropTypes.bool,
    searchValue: PropTypes.string,
    changeType: PropTypes.oneOf(['radiobutton', 'checkbox']),
    size: PropTypes.oneOf(['lg', 'md']),
    handleClick: PropTypes.func,
    dataTestId: PropTypes.string,
    id: PropTypes.string,
};

export default Item;
