import React from 'react';
import style from './ItemIcon.module.less';
import PropTypes from 'prop-types';

function ItemIcon({
    children,
    disabled,
    size,
    handleClick,
    dataTestId,
    id
}) {

    return (
        <div className={`${style[size]} ${style.item} ${disabled ? style.disabled : ''} dropdown-item-icon`}
            onClick={(e) => {
                e.stopPropagation();
                handleClick();
            }}
            data-testid={dataTestId}
            id={id}
        >
            {children}
        </div>
    );
}

ItemIcon.propTypes = {
    disabled: PropTypes.bool,
    size: PropTypes.oneOf(['lg', 'md', 'sm']),
    handleClick: PropTypes.func,
    dataTestId: PropTypes.string,
    id: PropTypes.string,
};

export default ItemIcon;
