import angular from 'angular';
import getNounPluralForm from './helpers/getNonPluralForm';

const directiveScrollHeader = ($window) => {
    return {
        scope: {
            offset: '@offset',
        },
        link: (scope) => {
            angular.element($window).bind('scroll', function() {
                scope.$parent.appCtrl.headerScrollPosition = this.pageYOffset;
            });
        },
    };
};

const directiveOnFinishRepeat = ($timeout) => {
    return {
        restrict: 'A',
        link: (scope, element, attr) => {
            if (scope.$last === true) {
                $timeout(() => {
                    eval('scope.' + attr.onFinishRepeat + '()');
                });
            }
        },
    };
};

const directiveElementCallback = () => {
    return {
        restrict: 'A',
        link: function(scope, element, attr) {
            eval(attr.elementCallback)(element[0]);
        },
    };
};

const directivePluralizeWatch = () => {
    return (scope, element, attr) => {
        scope.$watch(attr.pluralizeWatch, (count) => {
            if (attr.pluralizeKey) {
                scope[attr.pluralizeKey] = getNounPluralForm(count);
            } else {
                scope.pluralizeWatch = getNounPluralForm(count);
            }
        });
    };
};

const directiveMinValue = () => {
    return {
        require: 'ngModel',
        link: (scope, element, attr, ngModel) => {
            scope.$watch(attr.ngModel, function(newValue) {
                if (+newValue < +attr.minValue) {
                    ngModel.$setViewValue(+attr.minValue);
                    ngModel.$render();
                }
            });
        },
    };
};

const directiveMinLength = () => {
    return {
        require: 'ngModel',
        link: (scope, element, attr, ngModel) => {
            scope.$watch(attr.ngModel, function(newValue, oldValue) {
                if (+newValue.length <= +attr.minLength && oldValue && +oldValue.length > +attr.minLength) {
                    ngModel.$setViewValue(oldValue);
                    ngModel.$render();
                }
            });
        },
    };
};

const directiveMaxValue = () => {
    return {
        require: 'ngModel',
        link: (scope, element, attr, ngModel) => {
            scope.$watch(attr.ngModel, function(newValue) {
                if (+newValue > +attr.maxValue) {
                    ngModel.$setViewValue(+attr.maxValue);
                    ngModel.$render();
                }
            });
        },
    };
};

const directiveEscKey = () => {
    return {
        link: (scope, element, attr) => {
            element.bind('keydown keypress', function(event) {
                if (event.which === 27) { // 27 = esc key
                    scope.$apply(function() {
                        scope.$eval(attr.escKey);
                    });
                    event.preventDefault();
                }
            });
        },
    };
};

const directiveSameWidth = ($sessionStorage) => {
    return (scope, element, attr) => {
        element.ready(() => {
            let width = +element[0].clientWidth;
            if (typeof ($sessionStorage[attr.sameWidth]) == 'undefined' || +$sessionStorage[attr.sameWidth] < width) {
                $sessionStorage[attr.sameWidth] = width;
            }
        });
    };
};

const directiveTypeaheadShowOnFocus = () => {
    return {
        require: 'ngModel',
        link: function($scope, element, attrs, ngModel) {
            element.bind('focus', function() {
                ngModel.$setViewValue();
                element.triggerHandler('change');
                element.triggerHandler('input');
            });
            element.bind('click', function() {
                ngModel.$setViewValue();
                element.triggerHandler('change');
                element.triggerHandler('input');
            });
        },
    };
};

const directiveRedirect = () => {
    return (scope, element, attr) => {
        window.document.location.href = attr.redirect;
        if (window.appCtrl && window.appCtrl.$location.path() != '/error') {
            window.document.location.reload();
        }
    };
};

const inputOnChange = () => {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            element.bind('change', eval(attrs.inputOnChange));
        },
    };
};

const directiveFileSelect = () => {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            element.bind('change', function(e) {
                let file = (e.srcElement || e.target).files[0];
                eval('scope.' + attrs.fileSelect)(file);
            });
        },
    };
};

const directiveFocusOn = () => {
    return (scope, elem, attr) => {
        scope.$on(attr.focusOn, () => {
            elem[0].focus();
        });
    };
};

const detectHover = () => {
    function clearBacks() {
        let all = window.document.querySelectorAll('[detect-hover]');
        Object.keys(all).forEach(key => {
            all[key].style.backgroundColor = '';
        });
    }

    function colorRow(query) {
        clearBacks();
        let rows = window.document.querySelectorAll(query);
        if (rows) {
            Object.keys(rows).forEach(key => {
                rows[key].style.backgroundColor = '#fcf7e3';
            });
        }
    }

    return (scope, element, attr) => {
        let query = ''
            + '[data-target-username="' + attr.targetUsername + '"]'
            + '[data-target-computername="' + attr.targetComputername + '"]'
            + '[data-target-ip="' + attr.targetIp + '"]'
            + '[data-target-os="' + attr.targetOs + '"]'
            + '[data-target-userdomain="' + attr.targetUserdomain + '"]';
        element.bind('mouseover', () => colorRow(query)).bind('mouseout', () => clearBacks());
    };
};


const autoWidth = () => {
    function setWidth(chars, element) {
        element.style.width = (Math.max(chars, 15) * 7.8) + 'px';
        window.console.log(element);
    }

    return (scope, element) => {
        element.bind('keyup', event => setWidth(event.target.value.length, element[0]));
        if ('target' in scope) {
            setWidth(scope.target.ip_name.length || scope.target.ip.length, element[0]);
        }
    };
};

const directiveGetUploadFile = () => {
    return {
        require: 'ngModel',
        link: function postLink(scope, elem, attrs, ngModel) {
            elem.on('change', function() {
                let files = elem[0].files;
                ngModel.$setViewValue(files);
            });
        },
    };
};

const directiveAutoWidth = () => {
    function setWidth(chars, element, minChars) {
        element.style.width = (Math.max(chars, minChars) * 7.8 + 60 - 15.6) + 'px';
    }

    return {
        require: 'ngModel',
        link: function(scope, elem, attrs, ngModel) {
            const minChars = elem[0].getAttribute('min-chars');
            elem.ready(function() {
                let value = ngModel.$viewValue + '';
                setWidth(value.length, elem[0], minChars);
            });
            elem.bind('keyup', () => setWidth(ngModel.$$lastCommittedViewValue.length, elem[0], minChars));
            elem.bind('change', () => setWidth(ngModel.$$lastCommittedViewValue.length, elem[0], minChars));
        },
    };
};

const directiveSetMargin = () => {
    return (scope, elem) => {
        elem.ready(function() {
            if (parseInt(getComputedStyle(elem[0]).height) > 40) {
                elem[0].style['margin-bottom'] = '5px';
            }
        });
    };
};

const navScroll = () => {
    return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
            ngModel: '=',
        },
        link: function(scope, elem, attrs, ngModel) {
            elem.ready(function() {
                angular.element(document).bind('scroll', function() {
                    let startPosition = elem[0].offsetHeight * 2;
                    let target = document.documentElement ? document.documentElement : document.body;
                    if (target && target.scrollHeight - target.clientHeight < startPosition) {
                        elem[0].style.top = `-${startPosition}px`;
                        ngModel.$modelValue = 0;
                        scope.ngModel = 0;
                        return;
                    }
                    if (target.scrollTop > startPosition) {
                        elem[0].style.top = 0;
                        ngModel.$modelValue = 1;
                        scope.ngModel = 1;
                        return;
                    }
                    elem[0].style.top = target.scrollTop - startPosition + 'px';
                    ngModel.$modelValue = 0;
                    scope.ngModel = 0;
                });
            });
        },
    };
};

const bottomPanel = () => {
    return (scope, elem) => {
        let setPosition = () => {
            if ((+document.documentElement.scrollHeight - 136) > (+document.documentElement.clientHeight + +document.documentElement.scrollTop)) {
                elem[0].classList.add('fixed-controller');
                elem[0].classList.remove('static-div');
            } else {
                elem[0].classList.remove('fixed-controller');
                elem[0].classList.add('static-div');
            }
        };
        elem.ready(function() {
            setPosition();
            angular.element(document).bind('scroll', setPosition);
        });
    };
};

const startPositionMenu = () => {
    let setStart = (elem) => {
        const navLogo = angular.element(document.querySelector('.js-logo'));
        if (navLogo && navLogo[0] && elem && elem[0]) {
            const startPosition = navLogo[0].clientHeight + 61;
            elem[0].style.top = startPosition + 'px';
            if (elem[0].classList.contains('js-menu-ico')) {
                const infoBoard = angular.element(document.querySelector('.header'));
                elem[0].style.left = infoBoard[0].offsetLeft - 52 + 'px';
            }
        }
    };
    return (scope, elem) => {
        elem.ready(function() {
            setStart(elem);
            angular.element(window).bind('resize', function() {
                setStart(elem);
            });
        });
    };
};

const animateMenu = ($ngRedux) => {
    return (scope, elem) => {
        elem.ready(function() {
            let menu = elem[0].querySelector('.js-classic-menu');
            const wrapperHeight = menu ? menu.offsetHeight : 0;
            $ngRedux.dispatch({ type: 'MENU_HEIGHT', payload: wrapperHeight });
        });
    };
};

const showPassword = () => {
    return  (scope, elem, attrs) => {
        scope.$watch(attrs.showPassword, function(newValue) {
            if (newValue) {
                elem.attr('type', 'text');
            } else {
                elem.attr('type', 'password');
            }
        });
    };
};

const selectOnBlur = () => {
    return {
        require: 'uiSelect',
        link: function($scope, $element, attrs, $select) {
            var searchInput = $element.querySelectorAll('input.ui-select-search');
            if (searchInput.length !== 1) throw Error('bla');
            searchInput.on('blur', function() {
                $scope.$apply(function() {
                    var item = $select.items[$select.activeIndex];
                    $select.select(item);
                });
            });
        },
    };
};

const setTooltip = () => {
    return (scope, elem, attrs) => {
        elem.ready(() => {
            let currentItem = elem[0];
            let itemWidth = currentItem.offsetWidth;
            let parentNode = currentItem.parentNode.parentNode.parentNode;
            if (itemWidth > parentNode.offsetWidth) {
                attrs.tooltipEnable = 'true';
            }
        });
    };
};

export {
    directiveScrollHeader,
    directiveElementCallback,
    directiveMaxValue,
    directiveMinValue,
    directiveOnFinishRepeat,
    directivePluralizeWatch,
    directiveEscKey,
    directiveSameWidth,
    directiveTypeaheadShowOnFocus,
    directiveRedirect,
    inputOnChange,
    directiveFileSelect,
    directiveMinLength,
    directiveFocusOn,
    detectHover,
    autoWidth,
    directiveGetUploadFile,
    directiveAutoWidth,
    directiveSetMargin,
    navScroll,
    startPositionMenu,
    animateMenu,
    selectOnBlur,
    bottomPanel,
    showPassword,
    setTooltip,
};
