import React from 'react';
import style from './ModalHeader.module.less';

export function ModalHeader ({ onModalClose }) {
    return (
        <div className={style.header}>
            <svg onClick={onModalClose}
                width='25'
                height='24'
                viewBox='0 0 25 24'
                fill='none'
                className={style.cross}>
                <path d='M12.0705 10.5867L17.0171 5.64001L18.4304 7.05335L13.4838 12.0001L18.4304 16.9467L17.0171 18.36L12.0705 13.4134L7.12378 18.36L5.71045 16.9467L10.6572 12.0001L5.71045 7.05335L7.12378 5.64001L12.0705 10.5867Z'
                    fill='#020019'
                    fillOpacity='0.38'/>
            </svg>
        </div>
    );
}
