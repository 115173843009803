import React from 'react';
import style from  './Tag.module.less';
import PropTypes from 'prop-types';

function Tag ({ children, type, size, disabled }) {
    return <div className={`${style.tag} ${style[type]} ${style[size]} ${disabled ? style.disabled : ''}`}>{children}</div>;
}

Tag.propTypes = {
    type: PropTypes.oneOf(['mint', 'green', 'yellow', 'blue', 'pink', 'orange', 'red', 'accent', 'secondary', 'primary']),
    size: PropTypes.oneOf(['sm', 'xsm']),
    disabled: PropTypes.bool
};

export default Tag;
