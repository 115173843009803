import style from './style.modules.css';

class DashboardSceletonController {
    constructor(gettextCatalog, location) {
        this.style = style;
        this.strings = {
            knowledge: gettextCatalog.getString('Знания'),
            skills: gettextCatalog.getString('Навыки'),
            rating: gettextCatalog.getString('Рейтинг'),
            vulnerabilities: gettextCatalog.getString('Уязвимости')
        };
        this.location = location;
    }

    titleClick = (path, event) => {
        if (event.ctrlKey || event.metaKey) {
            return;
        }
        if (!this.accessDashboardView) return;
        this.changePath(`/${path}`);
    }
}

DashboardSceletonController.$inject = ['gettextCatalog', '$location'];

export {
    DashboardSceletonController,
};
