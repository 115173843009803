import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FetchApi from '../REST';

const initialState = {
    targetCards: {},
    courses: [],
    load: false,
    error: false
};

export const targetCardsFetch = createAsyncThunk(
    'targetCards/fetchTargetHistory',
    async (selectedData) => {
        const fetchApi = FetchApi('/reports/targets-history');
        const response = await fetchApi.post({ ...selectedData });
        if (response.isError) {
            throw response.err;
        }
        return response;
    }
);

const targetCardsSlice = createSlice({
    name: 'targetCards',
    initialState,
    reducers: {},
    extraReducers: {
        [targetCardsFetch.fulfilled]: (state, { payload }) => {
            state.targetHistory = payload;
            state.load = true;
            state.error = false;
        },
        [targetCardsFetch.pending]: (state) => {
            state.load = false;
            state.error = false;
        },
        [targetCardsFetch.rejected]: (state) => {
            state.load = true;
            state.error = true;
        }
    }
});

export default targetCardsSlice.reducer;
