import React, { useContext, useRef, useState } from 'react';
import style from 'rootReact/features/EmployeeProfile/LoadingPlug.module.less';
import { Icon } from 'UI/Icon';
import Translate from 'rootReact/Translate.jsx';
import { TabSelect } from 'rootReact/shared/TabSelect';
import { GettextContext } from 'rootReact/context';
import { TargetCardHeader } from 'UI/TargetCardHeader';

const COURSE = 'course';
const ATTACK = 'attack';
const MESSAGE = 'message';

export const LoadingPlug = () => {
    const gettext = useContext(GettextContext);
    const dataRef = useRef(null);
    const [hasShadow, setHasShadow] = useState(false);
    const [hasSelectShadow, setHasSelectShadow] = useState(false);

    const getDataTarget = (icon, label) => {
        return (
            <div className={style.bodyPlugElementWrapper}>
                <div className={style.bodyPlugLabelWrapper}>
                    <div className={style.bodyPlugIcon}>
                        <Icon src={'/image/informationSprite.svg'}
                            id={icon}
                            size={'md'}
                        />
                    </div>
                    <div className={style.bodyPlugLabel}>{label}</div>
                </div>
                <div className={`${style.bodyPlugValue} ${style.animate}`}></div>
            </div>
        );
    };

    const handleScroll = (e) => {
        setHasShadow(e.target.scrollTop !== 0 || e.target.scrollTop - dataRef.current.clientHeight >= 0);
        setHasSelectShadow(e.target.scrollTop - dataRef.current.clientHeight >= 0);
    };

    const tabs = [
        {
            name: <div className={style.selectName}><span>{gettext.getString('Обучение')}</span><div className={style.count}></div></div>,
            id: COURSE,
            disabled: true,
            isActive: false,
            setIsActive: () => {}
        },
        {
            name: <div className={style.selectName}><span>{gettext.getString('Тренировки')}</span><div className={style.count}></div></div>,
            id: ATTACK,
            disabled: true,
            isActive: false,
            setIsActive: () => {}
        },
        {
            name: <div className={style.selectName}><span>{gettext.getString('Сообщения об атаках')}</span><div className={style.count}></div></div>,
            id: MESSAGE,
            disabled: true,
            isActive: false,
            setIsActive: () => {}
        },
    ];

    const cardHeaders = [{ status: 'default', name: gettext.getString('Не назначено') }, { status: 'warning', name: gettext.getString('Назначено') }, { status: 'error', name: gettext.getString('Просрочено') }, { status: 'success', name: gettext.getString('Пройдено') }];

    return (
        <>
            <div className={style.headerPlug}>
                <div className={style.headerPlugTitle}>
                    <div className={`${style.headerPlugStatus} ${style.animate}`}></div>
                    <div className={`${style.headerPlugName} ${style.animate}`}></div>
                </div>
                <div className={`${style.headerPlugRating} ${style.animate}`}>
                </div>
            </div>
            <div className={`${style.wrapperPlug} ${hasShadow ? style.shadow : ''}`} onScroll={handleScroll}>
                <div ref={dataRef}>
                    <div className={style.bodyPlug}>
                        {getDataTarget('email_ico', <Translate>Эл. почта</Translate>)}
                        {getDataTarget('calendar_ico', <Translate>Дата добавления</Translate>)}
                        {getDataTarget('translate_ico', <Translate>Язык</Translate>)}
                        {getDataTarget('department_ico', <Translate>Подразделение</Translate>)}
                        {getDataTarget('position_ico', <Translate>Должность</Translate>)}
                        {getDataTarget('manager_ico', <Translate>Руководитель</Translate>)}
                        {getDataTarget('group_ico', <Translate>Группа риска</Translate>)}
                        {getDataTarget('star_ico', <Translate>Метки</Translate>)}
                    </div>
                </div>
                <div className={style.selectPlug}>
                    <div className={`${style.selectWrapper} ${hasSelectShadow ? style.selectShadow : ''}`}>
                        <TabSelect tabs={tabs}/>
                    </div>
                    <div className={style.mainWrapper}>
                        <div className={`${style.titleWrapper} ${style.animate}`}></div>
                        <div className={style.cardsWrapper}>
                            {cardHeaders.map((header) => (
                                <div key={header.status} className={style.edu}>
                                    <TargetCardHeader type={header.status}>
                                        <span>{header.name}</span>
                                    </TargetCardHeader>
                                    <div className={`${style.card} ${style.animate}`}></div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
