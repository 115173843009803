import dashboardTemplate from './view.html';

import { DashboardController } from './controller';

const DashboardComponent = {
    template: dashboardTemplate,
    controller: DashboardController,
    controllerAs: '$',
    bindings: {
        ctrl: '=',
        pathPage: '<',
        changePath: '='
    },
};

export {
    DashboardComponent,
};
