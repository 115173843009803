import dashboardTemplate from './view.html';

import { DashboardNewController } from './controller';

const DashboardNewComponent = {
    template: dashboardTemplate,
    controller: DashboardNewController,
    controllerAs: '$',
    bindings: {
        dasboardParams: '<',
        knowledgeConfig: '<',
        campaignConfig: '<',
        ratingConfig: '<',
        vulnerabilityConfig: '<',
        accessDashboard: '<',
        accessDashboardView: '<',
        accessDashboardView2: '<',
        accessDashboardView3: '<',
        accessDashboardView4: '<',
        accessDashboardEducation: '<',
        accessDashboardAttack: '<',
        accessStatistics: '<',
        licenseValid: '<',
        changePath: '='
    },
};

export {
    DashboardNewComponent,
};
