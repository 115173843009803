import { Controller } from './controller';
import { AUTH_SERVICE } from '../const';
import setOptions from '../helpers/setOptions';
import { setAuthInfo } from '../toolkit/actions';
import LoginPage from 'rootReact/components/LoginPage';

const CODE_NEED_CHANGE_PASSWORD = 11;

const userFields = [
    'password_new',
    'password_new_repeat',
    'password',
    'oneTimePassword',
    'username'
];

class LoginController extends Controller {

    constructor($scope, $localStorage, AuthService, $ngRedux, $injector) {
        super();
        this.ngRedux = $ngRedux;
        this.$scope = $scope;
        this.$localStorage = $localStorage;
        this.$injector = $injector;
        this.AuthService = AuthService;
        this.user = AuthService.new();
        this.isSSO = false;
        this.showPasswordForm = false;
        this.passwordShow = false;
        this.twoFactorEnable = false;
        this.signInBackUrl = window.config.SIGN_IN_BACK_URL;
        this.gettextCatalog = window.appCtrl.gettextCatalog;
        this.unsubscribe = $ngRedux.connect(null, { setAuthInfo })(this);
        this.LoginPage = LoginPage;

    }

    $onInit() {
        const { $localStorage } = this;
        if ($localStorage.userInfo && $localStorage.userInfo.email) {
            this.$scope.appCtrl.go($localStorage.userInfo.defaultPage || 'targets');
        } else {
            document.body.classList.add('body-login');
        }
    }

    setSSO = (bool) => {
        this.isSSO = bool;
        this.errorText = '';
    }

    signInSSO = async () => {
        try {
            this.inProcess = true;
            const options = setOptions();
            options.method = 'GET';
            let response = await fetch('/sso/login?username=' + this.user.username, options);
            let parsed = await response.json();
            const {
                ok
            } = response;
            if (ok) {
                if (parsed.ssoRedirectUrl) {
                    window.location.href = parsed.ssoRedirectUrl;
                }
            } else {
                throw Error(parsed.error ? parsed.error : this.gettextCatalog.getString('Ошибка аутентификации'));
            }
            this.inProcess = false;
            this.$scope.$apply();
        } catch (error) {
            this.inProcess = false;
            this.errorText = this.gettextCatalog.getString(error.message);
            this.$scope.$apply();
        }
    }

    signIn = async () => {
        this.errorText = null;
        this.$scope.$apply();
        if (this.isSSO) {
            this.signInSSO();
            return;
        }
        try {
            this.inProcess = true;
            this.$scope.$apply();
            let options = setOptions();
            options.method = 'POST';
            options.headers['Content-Type'] = 'application/json';
            let body = {};
            if (!this.user.oneTimePassword) {
                delete this.user.oneTimePassword;
            }
            userFields.forEach(item => {
                if (this.user[item]) {
                    body[item] = this.user[item];
                }
            });
            options.body = JSON.stringify(body);
            let response = await fetch(window.config.SYSTEM_URL + window.config.API_URL + '/auth', options);
            if (+response.status === 449) {
                this.twoFactorEnable = true;
                this.inProcess = false;
                this.$scope.$apply();
                return;
            }
            if (!response.ok) {
                throw true;
            }
            let user = await response.json();
            this.inProcess = false;
            this.setUser(user);
            this.$scope.$apply();
        } catch (e) {
            this.inProcess = false;
            this.errorText = this.gettextCatalog.getString('Ошибка аутентификации');
            this.$scope.$apply();
        }
    };

    changeAuthMethod = (isSSO) => {
        this.isSSO = !isSSO;
        this.twoFactorEnable = false;
        this.showPasswordForm = false;
        this.errorText = '';
        this.$scope.$apply();
    }

    setUser = (user) => {
        const { $localStorage } = this;
        if (user && user.email) {
            $localStorage.userInfo = user;
            if (user.lastMotivation && user.lastMotivation.updatedAt) {
                user.lastMotivation.updatedAt = user.lastMotivation.updatedAt * 1000;
            }
            this.setAuthInfo(user);
            document.body.classList.remove('body-login');
            this.$scope.appCtrl.go(user.defaultPage || 'targets');
        } else {
            if (user && user.error) {
                this.errorText = this.gettextCatalog.getString(user.error);
                this.$scope.$apply();
            } else {
                this.errorText = this.gettextCatalog.getString('Ошибка аутентификации');
                this.$scope.$apply();
            }
            this.showPasswordForm = user.code && user.code === CODE_NEED_CHANGE_PASSWORD;
            this.changePasswordText = user.code && user.code === CODE_NEED_CHANGE_PASSWORD && user.error;
            this.$scope.$apply();
        }
    }

    get oneTimePass () {
        if (!this.user.oneTimePassword) {
            this.user.oneTimePassword = '';
        }
        let arr = this.user.oneTimePassword.match( /\d{1,3}/g ) || [];
        return arr.join(' ');
    }

    set oneTimePass (key) {
        if (!key) {
            this.user.oneTimePassword = null;
            return;
        }
        this.user.oneTimePassword = key.split(' ').join('');
    }

    $onDestroy() {
        this.unsubscribe();
    }
}

LoginController.$inject = [
    '$scope',
    '$localStorage',
    AUTH_SERVICE,
    '$ngRedux',
    '$injector',
];

export {
    LoginController
};
