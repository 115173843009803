import style from '../../styles/layout.modules.css';
import angular from 'angular';
import { hasAccess, getSystemAlert, getAuthType } from '../reselect';
import navigation from '../React/widget/Navigaton';
import setOptions from '../helpers/setOptions';

class LayoutController {
    constructor($location, $ngRedux, gettextCatalog, $scope, $state, $localStorage) {
        this.$location = $location;
        this.$localStorage = $localStorage;
        this.info = {};
        this.style = style;
        this.navigation = navigation;
        this.scope = $scope;
        this.gettextCatalog = gettextCatalog;
        this.menuOpen = window.innerWidth >= 1430;
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis)(this);
        this.screenWidth = window.innerWidth;
        this.prevScreenWidth = window.innerWidth;
        this.path = this.$location.path();
    }

    $onInit () {
        const that = this;
        angular.element(window).bind('resize', function(event) {
            that.screenWidth = event.target.innerWidth;
            if ((that.prevScreenWidth >= 1410 && that.screenWidth >= 1410) || (that.prevScreenWidth < 1410 && that.screenWidth < 1410)) {
                that.prevScreenWidth = that.screenWidth;
                return;
            }
            that.prevScreenWidth = that.screenWidth;
            that.menuOpen = that.screenWidth >= 1410;
            that.scope.$apply();
        });
    }

    mapStateToThis(state) {
        return {
            accessDashboard: hasAccess(state, { sectionId: 0, rightName: 'view' }),
            systemAlerts: getSystemAlert(state),
            isSSO: getAuthType(state) === 3,
        };
    }

    onMenuOpen = () => {
        this.menuOpen = !this.menuOpen;
        this.scope.$apply();
    };

    changePath = (location) => {
        if (location.includes('login')) {
            this.$state.go('app.login');
        } else {
            this.$state.go(`app.index.${location.replace('/', '')}`);
        }
        this.path = location;
    }

    logout = () => {
        const { $localStorage, $location } = this;
        let url = this.isSSO ? '/sso/logout' : '/logout';
        let opts = setOptions();
        fetch(url, opts).then((response) => {
            return response.json();
        }).then((res) => {
            if (res.ssoRedirectUrl) {
                window.location.href = res.ssoRedirectUrl;
            } else {
                $localStorage.userInfo = null;
                $location.path('login');
                this.scope.$apply();
                location.reload();
            }
        });
    };

    isDataPickerClass = (target) => {
        let isContain = false;
        let classList = target.classList;
        classList.forEach(item => {
            if (item.indexOf('datepicker') > -1) {
                isContain = true;
            }
        });
        return isContain;
    }

    go = (path) => {
        this.$location.path(path);
        this.$location.hash('');
    };

}

LayoutController.$inject = [
    '$location',
    '$ngRedux',
    'gettextCatalog',
    '$scope',
    '$state',
    '$localStorage',
];

export {
    LayoutController,
};
