import TargetFileSelectModalTemplate from './view.html';

import { TargetFileSelectModalController } from './controller';

class TargetFileSelectModal {
    constructor($uibModal) {
        'ngInject';
        this.$uibModal = $uibModal;
        this.modalInstance = null;
    }

    open = () => {
        this.modalInstance = this.$uibModal.open({
            template: TargetFileSelectModalTemplate,
            bindToController: true,
            controller: TargetFileSelectModalController,
            controllerAs: '$',
            size: '885',
            windowClass: 'center-modal',
            resolve: {},
            backdrop: 'static',
            keyboard: false,
        });

        return this.modalInstance.result;
    };
}

export {
    TargetFileSelectModal
};
