import React, { useContext } from 'react';
import { GettextContext, InjectorContext } from 'rootReact/context';
import { useSelector, useDispatch } from 'react-redux';
import { CertsModal } from 'root/assets/webpack/src/js/components/certModal';
import { deleteCerts } from 'root/assets/webpack/src/js/toolkit/think';
import SettingsHeader from 'rootReact/components/SettingsSectionHeader';
import { Table, TableBody, TableCell, TableHead, TableRow } from 'rootReact/UI/Table';
import ReactIcon from 'rootReact/components/ReactIcons';

function SettingsCert({ licenseIsValid }) {
    const gettext = useContext(GettextContext);
    const injector = useContext(InjectorContext);
    const certsError = useSelector(state => state.certificate.error);
    const certs = useSelector(state => state.certificate.certs);
    const dispatch = useDispatch();

    const openModal = (id = 0) => {
        let modal = injector.instantiate(CertsModal);
        modal.open(id);
    };

    const handleRow = (id) => {
        if (!licenseIsValid) return;
        openModal(id);
    };

    const iconDelete = (e, id) => {
        e.stopPropagation();
        if (!licenseIsValid) return;
        dispatch(deleteCerts(id));
    };

    if (certsError) {
        return (
            <div>
                <SettingsHeader title={gettext.getString('Сертификаты')} right />
                <span className={'text-error'}>{gettext.getString('Что-то пошло не так :-(')}</span>
            </div>
        )
    }

    return (
        <div>
            <SettingsHeader title={gettext.getString('Сертификаты')} right />
            <div className={'settings_item'}>
                <Table>
                    <TableHead>
                        <TableCell Style={'react-table_header-cell'}>{gettext.getString('Название')}</TableCell>
                        <TableCell></TableCell>
                    </TableHead>
                    <TableBody empty={!certs.length}
                        emptyText={gettext.getString('Нет сертификатов')}
                        emptyColSpan={2}
                    >
                        {certs.map(item => (
                            <TableRow key={item.id} handleClick={() => handleRow(item.id)}>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>{item.isDefault ? gettext.getString('По умолчанию'):
                                    <ReactIcon disabled={!licenseIsValid} handleClick={(e) => iconDelete(e, item.id)} icoClass={'delete'}/>}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
}

export default SettingsCert;
