import React, { useContext, useEffect, useState } from 'react';
import style from './loginForm.module.less';
import { REGEX_PATTERNS } from 'rootReact/const';
import Hint from 'rootReact/UI/Hint';
import { GettextContext } from 'rootReact/context';
import TextFieldHint from 'rootReact/UI/TextFieldHint';
import ButtonUI from 'rootReact/UI/ButtonUI';
import Link from 'rootReact/UI/Link';
import Password from 'rootReact/UI/Password';

function LoginForm({
    signIn,
    screenSize,
    user,
    twoFactorEnable,
    isSSO,
    inProcess,
    errorTextAngular,
    showPasswordForm,
    changeAuthMethod
}) {
    const gettext = useContext(GettextContext);
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [oneTimePassword, setOneTimePassword] = useState('');
    const [passwordNew, setPasswordNew] = useState('');
    const [passwordNewRepeat, setPasswordNewRepeat] = useState('');
    const [errorText, setErrorText] = useState(errorTextAngular);
    const [errorTextBasic, setErrorTextBasic] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const isRequiredFieldsFilled = () => {
        if (isSSO) {
            return userName.length !== 0;
        }
        if (showPasswordForm) {
            return passwordNew.length !== 0 && passwordNewRepeat.length !== 0;
        }
        if (!showPasswordForm && !isSSO) {
            const isUserNameAndPasswordFilled = userName.length !== 0 && password.length !== 0;
            if (twoFactorEnable) {
                return isUserNameAndPasswordFilled && oneTimePassword.length !== 0;
            }
            return isUserNameAndPasswordFilled;
        }
    };

    useEffect(() => {
        setIsButtonDisabled(!isRequiredFieldsFilled());
        setErrorTextBasic('');
        setErrorText('');
    }, [userName, password, oneTimePassword, isSSO, twoFactorEnable]);

    useEffect(() => {
        setIsButtonDisabled(!isRequiredFieldsFilled());
        if(errorTextAngular) {
            setErrorText(errorTextAngular);
        }
    }, [errorTextAngular, showPasswordForm, passwordNew, passwordNewRepeat]);

    useEffect(() => {
        setUserName(userName.replace(/[а-яёА-ЯЁ]/g, ''));
        if (userName) {
            user.username = userName;
        }
    }, [userName]);

    useEffect(() => {
        if (password) {
            user.password = password;
        }
    }, [password]);

    useEffect(() => {
        if (oneTimePassword) {
            user.oneTimePassword = oneTimePassword.split(' ').join('');
        }
    }, [oneTimePassword]);

    useEffect(() => {
        if (passwordNew) {
            user.password_new = passwordNew;
        }
    }, [passwordNew]);

    useEffect(() => {
        if (passwordNewRepeat) {
            user.password_new_repeat = passwordNewRepeat;
        }
    }, [passwordNewRepeat]);

    const handleSSO = () => {
        changeAuthMethod(isSSO);
        setPasswordNew('');
        setPasswordNewRepeat('');
        setOneTimePassword('');
        setErrorTextBasic('');
        user.oneTimePassword = '';
        user.password_new = '';
        user.password_new_repeat = '';
    };

    const validateUserName = (userName) => {
        return String(userName)
            .toLowerCase()
            .match(REGEX_PATTERNS.userName);
    };

    const handleSignIn = () => {
        if (!showPasswordForm) {
            const isValidUserName = validateUserName(userName);
            if (isSSO) {
                if (!isValidUserName) {
                    setErrorTextBasic(gettext.getString('Неверный логин'));
                    return;
                }
            } else {
                if (!isValidUserName) {
                    setErrorTextBasic(gettext.getString('Логин или пароль введены неверно'));
                    return;
                }
            }
        } else {
            setErrorTextBasic('');
        }
        signIn();
    };

    const handleChangeUserName = (e) => {
        setUserName(e.target.value);
    };

    const handleChangePassword = (e) => {
        setPassword(e.target.value);
    };

    const handleChangePasswordNew = (e) => {
        setPasswordNew(e.target.value);
    };

    const handleChangePasswordNewRepeat = (e) => {
        setPasswordNewRepeat(e.target.value);
    };

    const handleChangeOneTimePassword = (e) => {
        let arr = e.target.value.match(/\d{1,3}/g) || [];
        setOneTimePassword(arr.join(' '));
    };

    return (
        <div className={style.wrapper} data-testid={'login_form'}>
            {(errorText || errorTextBasic) &&
                    <Hint text={errorText || errorTextBasic}
                          testId={'login_error_alert'}
                          type={'error'}/>
            }
            {!showPasswordForm &&
                        <div className={style.fields}>
                            <div className={'custom-form-input-wrapper'}>
                                <TextFieldHint placeholder={gettext.getString('Логин')}
                                    id={'username'}
                                    testId={'login_field'}
                                    size={screenSize.width < 1440 ? 'md' : 'lg'}
                                    disabled={inProcess}
                                    value={userName}
                                    handleChange={handleChangeUserName}
                                />
                            </div>
                            {!isSSO &&
                                    <div className={'custom-password-label'}>
                                        <Password placeholder={gettext.getString('Пароль')}
                                            id={'password'}
                                            size={screenSize.width < 1440 ? 'md' : 'lg'}
                                            disabled={inProcess}
                                            testId={'password_field'}
                                            value={password}
                                            handleChange={handleChangePassword}
                                        />
                                    </div>
                            }
                            {twoFactorEnable && !isSSO &&
                                    <div className={'custom-form-input-wrapper'}>
                                        <TextFieldHint placeholder={gettext.getString('Код из приложения')}
                                            id={'oneTimePassword'}
                                            testId={'oneTimePassword'}
                                            size={screenSize.width < 1440 ? 'md' : 'lg'}
                                            value={oneTimePassword}
                                            disabled={inProcess}
                                            handleChange={handleChangeOneTimePassword}/>
                                    </div>
                            }
                        </div>
            }

            {showPasswordForm && !isSSO &&
                        <div className={style.fields}>
                            <div className={'custom-password-label'}>
                                <Password placeholder={gettext.getString('Новый пароль')}
                                    id={'password'}
                                    testId={'new_password_field'}
                                    size={screenSize.width < 1440 ? 'md' : 'lg'}
                                    disabled={inProcess}
                                    value={passwordNew}
                                    handleChange={handleChangePasswordNew}
                                />
                            </div>
                            <div className={'custom-password-label'}>
                                <Password placeholder={gettext.getString('Еще раз')}
                                    id={'password'}
                                    testId={'new_password_confirm_field'}
                                    size={screenSize.width < 1440 ? 'md' : 'lg'}
                                    disabled={inProcess}
                                    value={passwordNewRepeat}
                                    handleChange={handleChangePasswordNewRepeat}
                                />
                            </div>
                        </div>
            }
            <div className={style.login}>
                <ButtonUI
                    handleClick={() => handleSignIn()}
                    size={screenSize.width < 1440 ? 'md' : 'lg'}
                    disabled={!inProcess && isButtonDisabled}
                    isLoading={inProcess}
                    dataTestId={'login_button'}
                >
                    {gettext.getString(showPasswordForm ? 'Сохранить' : 'Войти')}
                </ButtonUI>
                <Link size={screenSize.width < 1440 ? 'md' : 'lg'}
                    handleClick={handleSSO}
                      testId={'login-select-link'}
                >
                    {isSSO ? gettext.getString('Через логин и пароль') : gettext.getString('Через SSO')}
                </Link>
            </div>
        </div>
    );
}

export default LoginForm;
