import React, { useContext } from 'react';
import style from './targetEntitiesCard.module.less';
import { Icon } from 'rootReact/UI/Icon';
import Translate from 'rootReact/Translate.jsx';
import { Tooltip } from 'antd';
import { GettextContext, MomentContext } from 'rootReact/context';

export function TargetAttackCard({ item, timeZone, isReported, status }) {
    const gettext = useContext(GettextContext);
    const moment = useContext(MomentContext);
    const RATING_STATUS = [
        { type :'openLink', label: <Translate>Перешел по ссылке</Translate> },
        { type :'openMail', label: <Translate>Открыл письмо</Translate> },
        { type :'openQrLink', label: <Translate>Перешел по ссылке в QR</Translate> },
        { type :'submitForm', label: <Translate>Ввел данные в форму</Translate> },
        { type :'reportMail', label: <Translate>Сообщил об атаке</Translate> },
        { type :'openAttachment', label: <Translate>Открыл вложение</Translate> },
        { type :'openAttachmentFromLink', label: <Translate>Открыл файл по ссылке</Translate> },
    ];

    const getRatingNumber = (num) => {
        if (num < 0) {
            return num.toString().replace('-', '–');
        }
        return num;
    };

    const getEmotionHint = () => {
        return <div className={style.attackEmotionTooltip}>
            <div><Translate>Психологические вектора атаки:</Translate></div>
            <div style={{ textAlign: 'left' }}>{item.vectors.reduce((compile, item) => {
                return (`${compile} ${compile ? ', ' : ''} ${item}`).trim();
            }, '')}</div>
        </div>;
    };

    const getTypeHint = () => {
        const view = <div className={style.attackEmotionTooltip} style={{ width: '144px' }}>
            {item.sourceId === 4 ?
                <Translate>Личное сообщение, не связанное с работой</Translate> :
                item.sourceId === 2 ?
                    <Translate>От внешних партнеров, подрядчиков</Translate> :
                    <Translate>От имени коллег или руководителей</Translate>}
        </div>;
        return view;
    };

    const getHint = (title, value) => {
        return <div className={style.attackEmotionTooltip}>
            <div>{title}</div>
            <div>{value}</div>
        </div>;
    };

    const getRatingHint = () => {
        const details = item.ratingDetail;
        return <div>
            {
                RATING_STATUS.map(detail => {
                    if (details[detail.type]) {
                        return <div>{detail.label}: {getRatingNumber(details[detail.type])}</div>;
                    }
                    return null;
                })
            }
            {details.vulnerabilities ? gettext.getString('Есть уязвимости') : gettext.getString('Уязвимостей не найдено')}
        </div>;
    };

    const getIconStyle = () => {
        let capStatus = status[0].toUpperCase() + status.slice(1);
        let cssClass = style['attackIcon' + capStatus];
        return cssClass;
    };

    return (
        <>
            <div className={style.diffTitle}>{item.name}</div>
            {item.startedAt && (
                <div className={style.attack}>
                    <div className={style.attackRating}>
                        <Tooltip title={getRatingHint()} arrow={false}>
                            <div className={style.attackIconWrapper}>
                                <div className={`${style.attackIcon} ${getIconStyle()}`}>
                                    <Icon src={'/image/mainSprite.svg'}
                                        id={'trainings'}
                                        size={'sm'}
                                    />
                                </div>
                                <div>{item.rating ? getRatingNumber(item.rating) : 0}</div>
                            </div>
                        </Tooltip>
                        {isReported && <div className={`${style.successText} ${style.text}`}><Translate>Сообщил об атаке</Translate></div>}
                    </div>
                    {!!item.startedAt && <div className={style.diffText}>{moment.tz(item.startedAt*1000 ,timeZone).format('ll')}, {moment.tz(item.startedAt*1000 ,timeZone).format('HH:mm')}</div>}
                </div>)}
            <div className={style.attackFooter} style={{ marginTop: 16 }}>
                <div className={style.attackTemplate}>
                    <div className={style.attackFillingIcon}>
                        <Icon src={'/image/informationSprite.svg'}
                            id={'email'}
                            size={'sm'} />
                    </div>
                    <div>{item.templateName || <Translate>Своя атака</Translate>}</div>
                </div>
                <div className={style.attackFilling}>
                    {!!item?.vectors?.length && <div className={style.attackEmotions}>
                        <Tooltip title={getEmotionHint()} fresh arrow={false}>
                            <div className={style.attackFillingIcon}>
                                <Icon src={'/image/informationSprite.svg'}
                                    id={'emotions'}
                                    size={'sm'} />
                            </div>
                        </Tooltip>
                        <div className={`${style.text} ${style.attackFillingText}`}>
                            {item.vectors.length}
                        </div>
                    </div>}
                    <Tooltip title={getTypeHint()} arrow={false}>
                        <div className={style.attackFillingIcon}>
                            <Icon src={'/image/informationSprite.svg'}
                                id={item.sourceId === 4 ? 'user' : item.sourceId === 3 ? 'corp' : 'global'}
                                size={'sm'} />
                        </div>
                    </Tooltip>
                    {item.finalPage && <Tooltip arrow={false} title={getHint(<Translate>Финальная страница:</Translate>,
                    item.finalPage.name)} fresh>
                        <div className={style.attackFillingIcon}>
                            {item.finalPage.favicon ?
                                <img src={item.finalPage.favicon}/>
                                : <Icon src={'/image/informationSprite.svg'}
                                    id={'final'}
                                    size={'sm'} />}
                        </div>
                    </Tooltip>}
                    {!!item.attachment && <Tooltip arrow={false} title={getHint(<Translate>Файл во вложении:</Translate>, item.attachment.name)}>
                        <div className={style.attackFillingIcon}>
                            <Icon src={'/image/icoSprite.svg'}
                                id={item.attachment.type}
                                size={'sm'}
                            />
                        </div>
                    </Tooltip>}
                    {item.phishingPage && <Tooltip arrow={false} title={getHint(<Translate>Фишинговая страница:</Translate>,
                        item.phishingPage.name)}>
                        <div className={style.attackFillingIcon}>
                            {item.phishingPage.favicon ?
                                <img src={item.phishingPage.favicon}/> :
                                <Icon src={'/image/informationSprite.svg'}
                                    id={'final'}
                                    size={'sm'} />}
                        </div>
                    </Tooltip>}
                </div>
            </div>
        </>
    );
}
