import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FetchApi from '../React/REST';

const initialState = {
    departments: [],
    status: null,
    errorText: null,
    selectedData: {
        search: '',
        all: 0,
        chosenDepartmentName: '',
        departments: [],
        groups: [],
        departmentsUsed: [],
        groupsUsed: [],
        targets: [],
        unselectedDepartments: [],
        unselectedGroups: [],
        unselectedTargets: [],
        selectedCount: { targets: 0 }
    },
};

export const fetchLdapDepartments = createAsyncThunk(
    'ldapDepartments/status',
    async ({groups, filterOptions, profileId}) => {
        let fields = [
            'emailField',
            'departmentField',
            'riskGroupField',
            'positionField',
            'managerField',
            'fullNameMode',
            'firstNameField',
            'secondNameField',
            'thirdNameField',
            'fullNameField',
            'bossKadrovik',
            'externalID'
        ];
        let group = groups.map(item => {
            return `groupName[]=${encodeURIComponent(item.text)}`;
        });
        let concatFilterOptions = '';
        for (let key in filterOptions) {
            if (fields.indexOf(key) !== -1) {
                concatFilterOptions += `&${key}=${filterOptions[key]}`;
            }
        }
        const fetchApi = new FetchApi(`/ldap/${profileId}/departments?${group.join('&')}${concatFilterOptions}`);
        const response = await fetchApi.get();
        return response;
    }
);

const ldapDepartmentsSlice = createSlice({
    name: 'ldapDepartments',
    initialState,
    reducers: {
        setChoosenLdapDepartment (state, {payload}) {
            state.selectedData = {...state.selectedData, ...payload};
        },
        setDefaultLdap (state) {
            state = initialState;
        }
    },
    extraReducers: {
        [fetchLdapDepartments.fulfilled]: (state, { payload }) => {
            state.departments = payload;
            state.status = 'success';
            state.errorText = null;
        },
        [fetchLdapDepartments.pending]: (state) => {
            state.status = 'loading';
            state.errorText = null;
            state.departments = [];
        },
        [fetchLdapDepartments.rejected]: (state, {payload}) => {
            state.departments = [];
            state.status = 'failed';
            state.errorText = payload;
            state.selectedData = initialState.selectedData;
        },
    }
});

export const {setChoosenLdapDepartment, setDefaultLdap} = ldapDepartmentsSlice.actions;
export default ldapDepartmentsSlice.reducer;

