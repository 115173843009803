import React from 'react';
import { classesAdd } from '../../helpers';
import style from './ButtonUI.module.less';
import PropTypes from 'prop-types';

function ButtonUI({
    children,
    buttonStyle = 'primary',
    size = 'lg',
    disabled,
    isLoading = false,
    handleClick,
    dataTestId,
    id,
}) {
    return (
        <button
            onClick={handleClick}
            data-testid={dataTestId}
            id={id}
            className={classesAdd(
                style.button,
                style[size],
                style[buttonStyle],
                disabled && style.disabled,
                isLoading && !disabled && style.loading,
            )}
        >
            { children }
        </button>
    );
}

ButtonUI.propTypes = {
    buttonStyle: PropTypes.oneOf(['primary', 'secondary', 'ghost']),
    size: PropTypes.oneOf(['lg', 'md', 'sm', 'xsm']),
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    handleClick: PropTypes.func,
    dataTestId: PropTypes.string,
    id: PropTypes.string,
};

export default ButtonUI;
