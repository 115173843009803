import React, { useContext, useState, useEffect } from 'react';
import style from './EmployeeStatistic.module.less';
import { TabSelect } from 'rootReact/shared/TabSelect';
import { TargetCards } from 'rootReact/widget/TargetCards';
import { GettextContext } from 'rootReact/context';
import { TargetCardHeader } from 'rootReact/UI/TargetCardHeader';
import Translate from 'rootReact/Translate.jsx';
import { usePlural } from 'rootReact/hook';
import ButtonUI from 'rootReact/UI/ButtonUI';
import { Select } from 'rootReact/widget/EmployeeStatistic/Select.jsx';

const COURSE = 'course';
const ATTACK = 'attack';
const MESSAGE = 'message';

const COURSE_SUCCESS = 5;
const COURSE_NOT_ASSIGNED = -4;
const COURSE_MISS = 7;
const COURSE_ASSIGNED = 4;

const MAX_VIEW_COURSES_COUNT = 90;

const OPEN_MAIL = 'openMail';
const OPEN_LINK = 'openLink';
const CLOSE_LINK = 'closeLink';
const OPEN_ATTACHMENT = 'openAttachment';
const OPEN_ATTACHMENT_FROM_LINK = 'openAttachmentFromLink';
const SUBMIT_FORM = 'submitForm';
const REPORT_MAIL = 'reportMail';
const OPEN_QR_LINK = 'openQrLink';

export const EmployeeStatistic = ({
    hasSelectShadow,
    courses,
    attacks,
    reports,
    timeZone,
    filter,
    setFilterState,
}) => {

    const filterTextGlobal = {
        course: {
            from: <Translate>По дате назначения</Translate>,
            educationTill: <Translate>По дате прохождения</Translate>
        },
        attack: {
            startedAt: <Translate>По дате отправки</Translate>
        },
        message: {
            datetime: <Translate>По дате добавления</Translate>
        }
    };

    const gettext = useContext(GettextContext);
    const [show, setShow] = useState(false);

    const coursesSchema = [
        {
            type: COURSE_NOT_ASSIGNED,
            status: 'default',
            title: gettext.getString('Не назначено')
        },
        {
            type: COURSE_ASSIGNED,
            status: 'warning',
            title: gettext.getString('Назначено')
        },
        {
            type: COURSE_MISS,
            status: 'error',
            title: gettext.getString('Просрочено')
        },
        {
            type: COURSE_SUCCESS,
            status: 'success',
            title: gettext.getString('Пройдено')
        },
    ];
    const attackSchema = [
        {
            type: 1,
            status: 'default',
            title: gettext.getString('Не отправлено')
        },
        {
            type: 0,
            status: 'error',
            title: gettext.getString('Провалено')
        },
        {
            type: 0,
            status: 'success',
            title: gettext.getString('Выдержано')
        }
    ];
    const reportsSchema = [
        {
            type: 1,
            status: 'default',
            title: gettext.getString('Имитированных')
        },
        {
            type: 0,
            status: 'blue',
            title: gettext.getString('Реальных')
        }
    ];

    const setFilter = (e) => {
        let target = e.target;
        let close = true;
        let i = 0;
        let maxI = 7;
        while (target) {
            if (!target.classList || !target.classList.contains || i >= maxI) {
                break;
            }
            if (target.classList.contains('target-card-select')) {
                close = false;
                break;
            }
            i++;
            target = target.parentNode;
        }
        if (close) {
            setShow(false);
        }
    };

    useEffect(() => {
        if (show) {
            document.body.addEventListener('click', setFilter);
        } else {
            document.body.removeEventListener('click', setFilter);
        }
        return () => document.body.removeEventListener('click', setFilter);
    }, [show]);

    const returnCard = (entities, key, type, viewStatus, reportIds = []) => {
        return entities.map((item, i) => (
            <TargetCards status={ type === ATTACK ? getTypeAttack(viewStatus, item) : viewStatus }
                key={ i }
                type={ type }
                timeZone={ timeZone }
                item={ item }
                isReported={ reportIds.indexOf(item.id) > -1 }
            />
        ));
    };

    const getCourses = () => {
        return coursesSchema.map(item => {
            let val = courses.filter(course => course.status === item.type);
            if (filter.filterKey === 'from') {
                val = val.sort((current, next) => {
                    const dateCurrent = new Date(current.from);
                    const dateNex = new Date(next.from);
                    if (dateCurrent < dateNex) return filter.reverse ? -1 : 1;
                    if (dateCurrent > dateNex) return filter.reverse ? 1 : -1;
                });
            }
            if (filter.filterKey === 'educationTill' && item.type === COURSE_SUCCESS) {
                val = val.sort((current, next) => {
                    const dateCurrent = new Date(current.educationTill);
                    const dateNex = new Date(next.educationTill);
                    if (dateCurrent < dateNex) return filter.reverse ? -1 : 1;
                    if (dateCurrent > dateNex) return filter.reverse ? 1 : -1;
                });
            } else if (filter.filterKey === 'educationTill' && item.type !== COURSE_SUCCESS) {
                val = val.sort((current, next) => {
                    const dateCurrent = new Date(current.from);
                    const dateNex = new Date(next.from);
                    if (dateCurrent < dateNex) return filter.prevRevers ? -1 : 1;
                    if (dateCurrent > dateNex) return filter.prevRevers ? 1 : -1;
                });
            }
            return <div key={item.status} className={style.edu} data-testid={'course_row'}>
                <TargetCardHeader type={item.status}>
                    <span>{item.title}</span>
                    <span>{val.length}</span>
                </TargetCardHeader>
                {returnCard(val, 'course_id', COURSE, item.status)}
            </div>;
        });
    };

    const getTypeAttack = (type, attack) => {
        if (type !== 'error') return type;
        let attackDetect = '';
        attack.detection.forEach(item => {
            if (item.type === SUBMIT_FORM) {
                attackDetect = 'failed';
                return;
            }
            if (item.type === OPEN_LINK ||
                item.type === OPEN_QR_LINK ||
                item.type === OPEN_ATTACHMENT ||
                item.type === OPEN_ATTACHMENT_FROM_LINK) {
                attackDetect = 'error';
                return;
            }
        });
        return attackDetect || 'warning';
    };

    const getAttack = () => {
        const reportIds = reports.map(item => item.id);
        return attackSchema.map((item, i) => {
            let val = attacks.filter(attack => {
                if (!attack.startedAt && item.type) return true;
                if (item.status === 'error' && attack.detection) return true;
                if (item.status === 'success' && !attack.detection && attack.startedAt) return true;
                return false;
            });
            return <div key={i} className={style.attack} data-testid={'attack_row'}>
                <TargetCardHeader type={item.status}>
                    <span>{item.title}</span>
                    <span>{val.length}</span>
                </TargetCardHeader>
                {returnCard(val, 'id', ATTACK, item.status, reportIds)}
            </div>;});
    };

    const getReports = () => {
        return reportsSchema.map(item => {
            let val = reports.filter(report => {
                return !!report.id == !!item.type;
            });
            return <div key={item.type} className={style.report} data-testid={'report_row'}>
                <TargetCardHeader type={item.status}>
                    <span>{item.title}</span>
                    <span>{val.length}</span>
                </TargetCardHeader>
                {returnCard(val, 'id', MESSAGE, 'message')}
            </div>;});
    };

    const getTitle = () => {
        if (filter.activeTab === COURSE) {
            return <Translate options={{ count: courses.length }}>{usePlural(
                courses.length,
                ['Всего {{count}} курс', 'Всего {{count}} курса', 'Всего {{count}} курсов']
            )}</Translate>;
        }
        if (filter.activeTab === ATTACK) {
            return <Translate options={{ count: attacks.length }}>{usePlural(
                attacks.length,
                ['Всего {{count}} атака', 'Всего {{count}} атаки', 'Всего {{count}} атак']
            )}</Translate>;
        }
        if (filter.activeTab === MESSAGE) {
            return <Translate options={{ count: reports.length }}>{usePlural(
                reports.length,
                ['Всего {{count}} сообщение об атаках', 'Всего {{count}} сообщения об атаках', 'Всего {{count}} сообщений об атаках']
            )}</Translate>;
        }
    };

    const tabs = [
        {
            name: <div className={style.selectName}>
                <span>{gettext.getString('Обучение')}</span>
                <div className={`${style.count} ${filter.activeTab === COURSE ? style.activeCount : ''}`}
                >{courses.length > MAX_VIEW_COURSES_COUNT ? MAX_VIEW_COURSES_COUNT.toString() + '+' : courses.length ? courses.length : 0}</div>
            </div>,
            id: COURSE,
            disabled: false,
            isActive: filter.activeTab === COURSE,
            setIsActive: () => {
                setFilterState({
                    activeTab: COURSE,
                    filterKey: 'from',
                    reverse: false,
                });
            }
        },
        {
            name: <div className={style.selectName}>
                <span>{gettext.getString('Тренировки')}</span>
                <div className={`${style.count} ${filter.activeTab === ATTACK ? style.activeCount : ''}`}
                >{attacks.length ? attacks.length : 0}</div>
            </div>,
            id: ATTACK,
            disabled: false,
            isActive: filter.activeTab === ATTACK,
            setIsActive: () => {
                setFilterState({
                    activeTab: ATTACK,
                    filterKey: 'startedAt',
                    reverse: false,
                });
            }
        },
        {
            name: <div className={style.selectName}>
                <span>{gettext.getString('Сообщения об атаках')}</span>
                <div className={`${style.count} ${filter.activeTab === MESSAGE ? style.activeCount : ''}`}
                >{reports.length ? reports.length : 0}</div>
            </div>,
            id: MESSAGE,
            disabled: false,
            isActive: filter.activeTab === MESSAGE,
            setIsActive: () => {
                setFilterState({
                    activeTab: MESSAGE,
                    filterKey: 'datetime',
                    reverse: false,
                });
            }
        },
    ];

    const getSortText = () => {
        return <>
            <div className={style.sortLabel}>
                {filterTextGlobal[filter.activeTab][filter.filterKey]}:
            </div>
            <div>
                {!filter.reverse ?
                    <Translate>сначала новые</Translate> :
                    <Translate>сначала старые</Translate>}
            </div>
        </>;
    };

    return (
        <div style={{ width: 1400 }}>
            <div className={`${style.selectWrapper} ${hasSelectShadow ? style.shadow : ''}`}>
                <TabSelect tabs={tabs}/>
            </div>
            <div className={style.mainWrapper}>
                <div className={style.titleWrapper}>
                    <div className={style.title} data-testid={'employee-title'}>
                        {getTitle()}
                    </div>
                    <div className={style.filter}>
                        <ButtonUI buttonStyle={'ghost'}
                            handleClick={() => setShow(!show)}
                            dataTestId={'employee-sort'}
                            size={'sm'}
                            disabled={show}
                        >
                            <div className={style.filterButton}>
                                <div className={style.sortText}>{getSortText()}</div>
                                <div className={`${style.filterSvg} ${!show ? style.filterSvgRotate : style.filterSvgDisabled}`}>
                                    <svg>
                                        <use xlinkHref={'/image/icoSprite.svg#select-arrow'}></use>
                                    </svg>
                                </div>
                            </div>
                        </ButtonUI>
                        {show && <Select
                            type={filter.activeTab}
                            setShow={setShow}
                            filterTextGlobal={filterTextGlobal}
                            filter={filter}
                            setFilterState={setFilterState}
                        />}
                    </div>
                </div>

                <div className={style.cardsWrapper}>
                    {filter.activeTab === COURSE ? getCourses() : ''}
                    {filter.activeTab === ATTACK ? getAttack() : ''}
                    {filter.activeTab === MESSAGE ? getReports() : ''}
                </div>
            </div>
        </div>
    );
};
