import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import auth from "./auth";
import selectedData from "./selectedData";
import target from '../React/entities/target';
import targetCards from '../React/entities/targetHistory';
import variables from "./variables";
import languages from "./languages";
import dashboard from "./dashboard";
import newDashboard from "./dashboardParams";
import daemonsMessage from "./daemons";
import oneTimeMessage from "./oneTimeMessage";
import departments from "./departments";
import breadcrumbLinks from "./breadcrumbLinks";
import linking from "./linking";
import campaigns from "./campaigns";
import courses from "./courses";
import notification from "./notification";
import certificate from "./certificate";
import types from "./types";
import attackHistory from "./attackHistory";
import educationHistory from "./educationHistory";
import graphMode from "./graphMode";
import learningConfig from "./learningConfig";
import loaderSso from "./loaderSso";
import ldapDepartments from "./ldapDepartments";
import motivationHistory from "./motivationHistory";
import ssoProfile from "./ssoProfie";
import templates from "root/assets/webpack/src/js/toolkit/templates";
import settings from "./settings";
import systemNotification from "./systemNotification";
import accountUsers from "root/assets/webpack/src/js/toolkit/accountUsers";
import roleUsers from "root/assets/webpack/src/js/toolkit/roleUsers";
import outlookSettings from "./outlookSettings";
import targetHistory from "./targetHistory";
import dashboardState from "./dashboardState";
import wifiDevice from "./wifiDevice";
import wifi from './wifi';
import finalPages from './finalPages';
import phishingPages from "./phishingPage";
import wifiTargets from "./wifiTargets";
import notificationsList from "root/assets/webpack/src/js/toolkit/notificationsList";
import ldapProfiles from './ldapProfiles';
import schedulerTasks from './schedulerTasks';

const middleware = getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true,
});

export const store = configureStore({
    reducer: {
        auth,
        dashboard,
        selectedData,
        variables,
        languages,
        newDashboard,
        daemonsMessage,
        oneTimeMessage,
        departments,
        breadcrumbLinks,
        linking,
        campaigns,
        notification,
        certificate,
        types,
        attackHistory,
        educationHistory,
        graphMode,
        learningConfig,
        loaderSso,
        ldapDepartments,
        motivationHistory,
        ssoProfile,
        courses,
        templates,
        targetHistory,
        dashboardState,
        settings,
        systemNotification,
        accountUsers,
        roleUsers,
        notificationsList,
        outlook: outlookSettings,
        wifi,
        wifiDevice,
        finalPages,
        phishingPages,
        target,
        wifiTargets,
        ldapProfiles,
        schedulerTasks,
        targetCards,
    },
    middleware,
    devTools: process.env.DEVELOPMENT,
});
